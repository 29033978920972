@font-face {
    font-family: 'n-icomoon';
    src:url('/public/sass/_novel/fonts/icomoon.eot?i46pmu');
    src:url('/public/sass/_novel/fonts/icomoon.eot?i46pmu#iefix') format('embedded-opentype'),
        // url('/public/sass/_novel/fonts/icomoon.ttf?i46pmu') format('truetype'),
        url('/public/sass/_novel/fonts/icomoon.woff?i46pmu') format('woff'),
        url('/public/sass/_novel/fonts/icomoon.svg?i46pmu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="n-icon-"], [class*=" n-icon-"] {
    font-family: 'n-icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.n-icon-tread:before {
    content: "\e800";
}
.n-icon-menu:before {
    content: "\e801";
}
.n-icon-read:before {
    content: "\e802";
}
.n-icon-right:before {
    content: "\e803";
}
.n-icon-back:before {
    content: "\e804";
}
.n-icon-back2:before {
    content: "\e805";
}
.n-icon-change:before {
    content: "\e806";
}
.n-icon-close:before {
    content: "\e807";
}
.n-icon-return:before {
    content: "\e808";
}
.n-icon-menu2:before {
    content: "\e809";
}
.n-icon-message:before {
    content: "\e80a";
}
.n-icon-load:before {
    content: "\e80b";
}
.n-icon-scifi:before {
    content: "\e80c";
}
.n-icon-add:before {
    content: "\e80d";
}
.n-icon-end:before {
    content: "\e80e";
}
.n-icon-wechat:before {
    content: "\e80f";
}
.n-icon-heart:before {
    content: "\e810";
}
.n-icon-up:before {
    content: "\e811";
}
.n-icon-down:before {
    content: "\e812";
}
.n-icon-write:before {
    content: "\e813";
}
.n-icon-weibo:before {
    content: "\e814";
}
.n-icon-praise:before {
    content: "\e815";
}
.n-icon-ding:before {
    content: "\e816";
}
.n-icon-share:before {
    content: "\e900";
}
.n-icon-menu3:before {
    content: "\e901";
}
.n-icon-flashback:before{
    content: "\e902";
}

