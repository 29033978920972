.wrapper.gray {
	background-color: #F5F5F5; }

.sub-tab {
	// background-color: white
	padding: 20px 0 20px 0;
	margin-bottom: 0px;
	// box-shadow: 0 2px 3px rgba(0,0,0,.05)
	.inner {
		width: 980px;
		margin: 0 auto;
		overflow: auto; }
	ul {
		float: left;
		li {
			display: inline-block;
			&:last-child {
				a {
					border: none; } }
			a {
				color: #333;
				padding: 0 14px;
				border-right: 1px solid #9A9A9A;
				display: inline-block;
				line-height: 10px;
				&.on {
					color: $orange; } } } }
	#show-rules {
		float: right;
		color: #333; } }

#popup-apply-success, #popup-trail-note {
	text-align: center !important;
	h2 {
		text-align: center;
		color: #444;
		font-size: 24px;
		color: #424242;
		margin: 30px auto; }
	p {
		color: #666;
		@include fs(14);
		text-align: center; }
	button {
		margin: 30px auto;
		width: 110px; }
	i {
		color: #B3B3B3;
		@include fs(13);
		font-style: normal;
		display: inline-block; } }

#popup-trail-note {
	p {
		text-align: left;
		width: 60%;
		margin: 20px auto; } }

.test-product-list {
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	li {
		width: 470/960*100%;
		background-color: white;
		display: inline-block;
		margin-bottom: 20px;
		overflow: hidden;
		&.full-width {
			width: 100%;
			.info-block {
				padding: 30/960*100%; } } }
	.img-link {
		display: block;
		width: 100%;
		position: relative;
		color: white;
		img {
			width: 100%; }
		i {
			position: absolute;
			top: 0;
			left: 0;
			background-color: #2BBC49;
			padding: 5px 10px;
			display: block;
			color: white;
			font-style: normal; } }

	.info-block {
		padding: 30/470*100%;
		h2 {
			color: #333C4E;
			margin-top: 0;
			width: 80%;
			@include fs(24);
			display: inline-block;
			a {
				color: #333C4E; }
			span {
				display: block;
				color: #505867;
				@include fs(14);
				margin-top: 10px;
				line-height: 20px;
				height: 40px;
				overflow: hidden; } }
		.logo {
			display: inline-block;
			float: right;
			width: 60px;
			height: 60px;
			img {
				width: 100%;
				height: 100%; } }
		hr {
			border: none;
			border-bottom: 1px solid #EEEEEE;
			width: 100%;
			height: 2px;
			margin-top: 27px; }
		.bottom-block {
			margin-top: 15px;
			overflow: auto;
			p {
				color: #828891;
				@include fs(13);
				display: inline-block;
				float: left;
				margin: 0;
				line-height: 20px;
				span {
					float: none;
					margin: 0 5px;
					color: #828891; } }
			time,span {
				color: #828891;
				@include fs(13);
				float: right;
				line-height: 20px; }
			span {
				color: #828891; }
			a {
				color: #4990e2; } } } }
