.input_decoration {
    border: none;
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    height: 26px;
    line-height: 1em;
    margin: 15px 0;
    outline: none;
    @include fs(14);
    background-color: transparent; }
.input_gray {
    background-color: #F9F9F9;
    color: $gray;
    font-size: 14px;
    padding: 15px 16px;
    border: none;
    @include rounded(5px); }
.input_profile,.input_profile_txt {
    width: 100%;
    border: none;
    @include fs(16);
    color: #808080;
    margin-bottom: 18px; }

.input_center {
    width: 200px;
    border: none;
    @include fs(16);
    line-height: 16px;
    color: #808080;
    margin-bottom: 18px; }
.input_normal {
    @extend .border-box;
    border: 1px #D9D9D9 solid;
    padding: 0 19px;
    line-height: 36px;
    @include rounded(4px); }
