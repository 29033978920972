$new_color : #D93641;
.tmt_video {
    padding-top: 55px;
    .top_video {
        height: 450px;
        background-color: #121212;
        margin-bottom: 40px;
        .content {
            position: relative;
            width: 1120px;
            margin: 0 auto;
            display: table;
            height: 100%;
            .words {
                display: table-cell;
                vertical-align: middle;
                color: #fff;
                position: relative;
                z-index: 2;
                width: 550px;
                .type {
                    margin-bottom: 20px;
                    span {
                        background-color: $new_color;
                        display: inline-block;
                        line-height: 23px;
                        padding: 0 5px;
                        @include fs(16); } }
                .title {
                    @include fs(36);
                    line-height: 45px;
                    margin-bottom: 40px;
                    a {
                        color: #fff; } }
                .info {
                    @include fs(13);
                    margin-bottom: 20px;
                    opacity: .8;
                    a {
                        color: #fff; } } }

            .img {
                position: absolute;
                right: 0;
                top: 0;
                width: 800px;
                height: 450px;
                overflow: hidden;
                img {
                    filter: blur(2px); }
                .video-img {
                    img {
                        filter: blur(0px); } }
                .shadow {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-image: -webkit-linear-gradient(to right, #121212, transparent, #121212);
                    background-image: linear-gradient(to right, #121212, transparent, #121212); } } } }
    .video-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 66px;
        height: 66px;
        margin: -35px 0 0 -35px;
        border: 2px #fff solid;
        @include rounded(50%);
        line-height: 66px;
        text-align: center;
        @include fs(40);
        color: #fff;
        background-color: rgba(0,0,0,.5);
        i {
            position: relative;
            top: 2px;
            left: 4px; }
        &.small {
            width: 36px;
            height: 36px;
            line-height: 36px;
            margin: -18px 0 0 -18px;
            @include fs(25);
            i {
                position: relative;
                top: 1px;
                left: 2px; } } }
    .video-img {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -31px 0 0 -31px;
        &.small {
            margin: -21px 0 0 -21px; } }
    .options {
        @include fs(13);
        opacity: .8;
        i {
            @include fs(16);
            vertical-align: middle; }
        .icon-Shape9 {
            display: none; }
        a {
            color: #fff;
            &:hover,&.current {
                color: $orange;
                text-decoration: none;
                .icon {
                    color: $orange; } }
            &.current {
                .icon-Shape9 {
                    display: inline-block;
                    *display: inline;
                    -webkit-animation: scalefade 300ms;
                    animation: scalefade 300ms; }
                .icon-like-1 {
                    display: none; } }
            .icon-Shape9 {
                display: none; }
            .icon-like-1,.icon-Shape9 {
                position: relative;
                top: -2px; }
            .num {
                color: #fff; } }
        .like {
            margin-right: 30px; }
        .icon {
            margin-right: 10px; } }
    .big_man_video {
        width: 1120px;
        margin: 0 auto 60px;
        position: relative;
        z-index: 1;
        h2.title {
            position: absolute;
            top: -7px;
            left: 0;
            z-index: 2;
            span {
                background-color: $new_color;
                display: inline-block;
                line-height: 23px;
                padding: 0 5px;
                color: #fff;
                @include fs(16); } }
        ul {
            font-size: 0;
            margin-right: -10px; }
        li {
            width: 555px;
            height: 555px;
            overflow: hidden;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 21px;
            position: relative;
            .img {
                display: block;
                vertical-align: top;
                width: 100%;
                height: 100%;
                overflow: hidden;
                margin-bottom: 15px;
                img {
                    width: 100%;
                    height: 100%; }
                .video-img {
                    width: 62px; } }
            .shadow {
                width: 100%;
                height: 50%;
                position: absolute;
                bottom: 0;
                right: 0;
                background-image: -webkit-linear-gradient(to top, rgba(0,0,0,.4), rgba(0,0,0,0));
                background-image: linear-gradient(to top, rgba(0,0,0,.9), rgba(0,0,0,0)); }
            .words {
                position: absolute;
                bottom: 30px;
                z-index: 2;
                padding: 0 78px;
                text-align: center;
                .info {
                    color: #fff;
                    @include fs(13);
                    line-height: 18px;
                    margin-bottom: 20px;
                    a {
                        color: #fff; } }
                .tit {
                    @include fs(24);
                    line-height: 33px;
                    margin-bottom: 15px;
                    height: 66px;
                    overflow: hidden;
                    a {
                        color: #fff; } } } } }

    .list_video {
        width: 1120px;
        margin: 0 auto 30px;
        .circle {
            text-align: center;
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            background-color: #F8E71C;
            color: #000;
            margin-right: 10px;
            @include rounded(50%);
            &.blue {
                background-color: #76B5FF; }
            &.pink {
                background-color: #D48BE3; } }
        .icon {
            margin-right: 10px; }
        .title {
            @include fs(18);
            line-height: 25px;
            padding-bottom: 13px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 21px;
            font-weight: bold; }
        ul {
            font-size: 0;
            margin-right: -30px; }
        li {
            width: 200px;
            overflow: hidden;
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 40px;
            vertical-align: top;
            .img {
                display: block;
                position: relative;
                vertical-align: top;
                width: 200px;
                height: 120px;
                overflow: hidden;
                margin-bottom: 15px;
                .time {
                    position: absolute;
                    right: 9px;
                    bottom: 0px;
                    display: inline-block;
                    background-color: #000;
                    padding: 0 2px;
                    line-height: 17px;
                    @include fs(12);
                    color: rgba(255,255,255,.8); } }
            .info {
                color: #666666;
                @include fs(12);
                line-height: 21px;
                margin-bottom: 8px;
                a {
                    color: #666; } }
            .tit {
                @include fs(14);
                line-height: 20px;
                height: 40px;
                overflow: hidden;
                a {
                    color: #333; } } } } }




@media (max-width: 1200px) {
    .tmt_video {
        .top_video .content,.list_video,.big_man_video {
            width: 980px; }
        .big_man_video {
            li {
                width: 485px;
                height: 485px; } } } }






