.qianzai-set-header {
    background-color: #FFF;
    line-height: 89px;
    box-shadow: 0 0 1px rgba(40,56,81,.4);
    padding-right: 60px;
    margin-bottom: 40px;
    position: relative;
    .avatar {
        img {
            margin-right: 20px;
            @include rounded(50%); }
        .name {
            @include fs(16);
            color: #666;
            word-spacing: 2px;
            margin-right: 11px;
            font-weight:  bold;
            letter-spacing: 2px; }
        i {
            @include fs(16);
            color: #3AC0C3;
            vertical-align: middle;
            display: inline-block;
            *display: inline; }
        i.hover {
            animation: turnl 0.3s linear 0s 1 normal running forwards; }
        i.hover-out {
            animation: turnr 0.5s linear 0s 1 normal running forwards; } }
    .qianzai-dropdown {
        position: absolute;
        right: 230px;
        top: 35px;
        .dropdown-menu {
            padding: 10px 0;
            left: -42px;
            &:before {
                right: 15px; } }
        ul {
            line-height: 20px; }
        li {
            margin-right: 0;
            width: 170px;
            line-height: 60px;
            background-color: #FFF;
            border-bottom: 1px solid #E3E4E7;
            padding: 0 30px 0 20px;
            &:last-child {
                border-bottom: none; }
            a {
                @include fs(14);
                line-height: 20px;
                color: #808080; }
            &:hover {
                background-color: #F1F1F1; } } } }

.qianzai_set {
    background-color: #F5F8FB;
    height: 100vh;
    width: 100vw;
    &.close {
        .siderbar {
            width: 80px;
            @include transition;
            .logo_part {
                line-height: 81px;
                .logo,.column-line,.column-title {
                    display: none; }
                .home {
                    display: block; } }
            .navs {
                li {
                    text-align: center;
                    .txt,i.fr {
                        display: none; }
                    .cont {
                        width: auto;
                        .icon {
                            margin-right: 0; } }
                    i {
                        margin-right: 0;
                        margin-left: -2px; }
                    i.icon-pack {
                        display: inline-block;
                        *display: inline;
                        -webkit-transform: rotateY(180deg);
                        transform: rotateY(180deg);
                        -o-transform: rotateY(180deg);
                        -moz-transition: transform .8s ease-in-out;
                        -webkit-transition: transform .8s ease-in-out;
                        transition: transform .8s ease-in-out;
                        -moz-transition: transform .8s ease-in-out;
                        -webkit-transition: transform .8s ease-in-out;
                        -o-transition: transform .8s ease-in-out; }
                    &.current {
                        border-left: 5px solid #EE6E50;
                        .icon {
                            display: inline-block;
                            *display: inline;
                            *display: inline;
                            // width: 40px
                            // height: 40px
                            color: #fff;
                            @include fs(24);
                            line-height: 24px;
                            text-align: center;
                            @include rounded(50%);
 } } } }                            // border: 2px solid #fff
            .nav_tit {
                width: 80px;
                text-align: center; }
            .close_hide {
                display: none; }
            .gap_line {
                width: 60px; } }
        .main_cont {
            padding-left: 80px; } }

    .siderbar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        height: 100vh;
        // @include transition
        background-color: #334563;
        width: 300px;
        overflow: hidden;
        z-index: 3;
        .siderbar_cont {
            height: 100%;
            height: 100vh;
            overflow: hidden; }
        .logo_part {
            line-height: 80px;
            background-color: #283851;
            text-align: center;
            color: #fff;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(76,87,104,.4);
            a {
                color: #fff; }
            .column-title {
                @include fs(20); }
            .column-line {
                margin: 0 10px;
                position: relative;
                top: 2px; }
            a {
                display: inline-block;
                *display: inline;
                *display: inline;
                vertical-align: middle; }
            .home {
                display: none;
                i {
                    @include fs(44);
                    vertical-align: middle; } } }
        // .side_nav
        //     position: absolute
        //     top: 50%
        //     padding-top: 110px
        //     @include
        .navs {
            li {
                text-align: center;
                line-height: 64px;
                color: #A0A9B0;
                @include fs(16);
                border-left: 5px solid #334563;
                a {
                    color: #A0A9B0; }
                i {
                    color: #3AC0C3;
                    @include fs(20); }
                .txt {
                    display: inline-block;
                    *display: inline;
                    @include fs(16); }
                i.fr {
                    color: #737C8B;
                    @include fs(16);
                    line-height: 64px;
                    display: inline-block;
                    *display: inline; }
                .cont {

                    display: inline-block;
                    *display: inline;
                    width: 220px;
                    text-align: left; }
                .icon {
                    display: inline-block;
                    *display: inline;
                    width: 20px;
                    margin-right: 20px;
                    text-align: center;
                    i {
                        vertical-align: middle;
                        position: relative;
                        top: -3px; } }
                &:hover {
                    color: #fff;
                    a {
                        color: #fff; }
                    i {
                        color: #fff; } }
                i.icon-pack {
                    display: inline-block;
                    *display: inline;
                    transform: rotateY(0deg);
                    -o-transform: rotateY(0deg);
                    -webkit-transform: rotateY(0deg);
                    -moz--transform: rotateY(0deg);
                    transition: transform .8s ease-in-out;
                    -moz-transition: transform .8s ease-in-out;
                    -webkit-transition: transform .8s ease-in-out;
                    -o-transition: transform .8s ease-in-out; }
                &.current {
                    background-color: #283851;
                    border-left: 5px solid #EE6E50;
                    color: #fff;
                    a {
                        color: #fff; }
                    i {
                        color: #fff; } } } }
        .gap_line {
            height: 1px;
            width: 300px;
            margin: 0 auto;
            background-color: #4C5768; } }
    .new_siderbar {
        .navs {
            margin-bottom: 20px;
            li {
                line-height: 24px;
                margin-bottom: 14px;
                height: 24px;
                // overflow: hidden
                &:hover {
                    i {
                        color: #3AC0C3; }
                    i.icon-lock3 {
                        color: #737C8B; } }
                i.fr {
                    line-height: 24px; }

                &.current {
                    background-color: transparent;
                    border-left: 5px solid #EE6E50;
                    color: #EE6E50;
                    a {
                        color: #EE6E50; }
                    i {
                        color: #EE6E50; } }
                i.icon-pannel {
                    @include fs(14); }
                i.icon-bag {
                    @include fs(16); }
                i.icon-earth {
                    @include fs(22); }
                i.icon-view {
                    @include fs(18); }
                i.icon-pack {
                    @include fs(18); } } }

        .nav_tit {
            color: #fff;
            width: 220px;
            margin: 20px auto 20px;
            @include fs(14); }
        .gap_line {
            margin-bottom: 10px; }
        .js_close {
            padding-top: 5px; } }
    .main_cont {
        padding-left: 300px;
        .cont {
            width: 900px;
            margin: 0 auto; }
        .part {
            background-color: #FFF;
            border: 1px solid rgba(198,200,206,.5);
            margin-bottom: 20px;
            .bold {
                font-weight: bold; }
            .top_banner, {
                line-height: 70px;
                color: #596679;
                padding: 0 30px;
                .tit {
                    margin-right: 10px;
                    @include fs(18);
                    display: inline-block;
                    *display: inline;
 }                    // font-weight: bold
                .intro {
                    @include fs(14);
                    .num {
                        color: #3AC0C3;
                        @include fs(18);
                        font-weight: bold; } }
                .num {
                    color: #3AC0C3; }
                a {
                    color: $main; }
                .more {
                    @include fs(13); } }
            .hint {
                border-top: 1px solid #E5E7EC;
                text-align: center;
                padding:  60px 0;
                @include fs(18);
                color: #666666;
                a {
                    color: #F38462;
                    text-decoration: underline; } }
            .no_result {
                padding: 30px 30px 20px;
                color: #596679;
                .tit {
                    @include fs(18);
                    line-height: 30px;
                    font-weight: bold; }
                .intro {
                    @include fs(14);
                    line-height: 30px; }
                a {
                    color: $main; } } }
        .top_hint {
            padding: 17px 30px;
            @include fs(18);
            margin-bottom: 20px;
            .circle {
                margin-right: 20px;
                text-align: center;
                line-height: 46px;
                height: 46px;
                width: 46px;
                background-color: #FF624A;
                color: #fff;
                @include rounded(50%);
                margin-top: 4px; }
            .hint_cont {
                overflow: hidden;
                color: #596679;
                @include fs(18);
                p {
                    line-height: 28px;
                    a {
                        color: #EE6E50; } } } }
        .update {
            // line-height: 90px
            padding: 15px 30px;
            .circle {
                background-color: #3AC0C3;
                color: #fff;
                display: inline-block;
                *display: inline;
                *display: inline;
                width: 46px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                @include rounded(50%);
                margin-right: 20px;
                i {
                    @include fs(18);
                    vertical-align: middle; } }
            .txt {
                color: #596679;
                @include fs(18);
                a {
                    color: $main;
                    text-decoration: underline; } } }
        .new_pro {
            .table {
                max-height: 278px;
                overflow-y: scroll; }
            table {
                color: #666666;
                width: 100%;
                th:first-child {
                    text-align: left;
                    padding-left: 30px; }
                th:last-child {
                    padding-right: 20px; }
                td {
                    text-align: center;
                    @include fs(16);
                    @extend .border-box;
                    &.tl {
                        text-align: left;
                        padding-left: 10px; }
                    &:first-child {
                        padding-left: 20px; }
                    &:last-child {
                        padding-right: 20px; }
                    img {
                        border: 1px solid #EBECED; } }
                thead {
                    tr {
                        height: auto;
                        border: none;
                        line-height: 50px;
                        @include fs(16);
                        color: #808080;
                        background-color: #F7F9FA; } }
                tr {
                    line-height: 92px;
                    &:nth-child(2n) {
                        border-top: 1px #F2F2F2 solid;
                        border-bottom: 1px #F2F2F2 solid;
                        background-color: #F7F9FA; } }
                .tit {
                    color: #5696F9;
                    @include fs(18);
                    line-height: 20px;
                    a {
                        color: #5696F9;
                        background-color: rgba(86,150,249,.08); } } } }
        .new_news {
            .news-cont {
                border-top: 1px solid #E5E7EC;
                @include fs(0);
                background-color: #fff;
                .left,.right {
                    @include fs(14);
                    padding-top: 30px;
                    display: inline-block;
                    *display: inline; }
                .tit {
                    color: #596679;
                    line-height: 16px; }
                .left {
                    width: 540px;
                    &.border {
                        border-right: 1px solid #E5E7EC; }
                    .tit {
                        padding-left: 30px; }
                    li {
                        padding: 30px;
                        color: $main;
                        line-height: 14px;
                        border-bottom: 1px solid #E5E7EC;
                        &:last-child {
                            border-bottom: none; }
                        a {
                            color: $main; } } }
                .right {
                    padding-left: 20px;
                    width: 355px;
                    vertical-align: top;
                    .tit {
                        margin-bottom: 20px; }
                    li {
                        margin-bottom: 24px; }
                    h4 {
                        color: #666666;
                        line-height: 15px;
                        margin-bottom: 10px; }
                    .time {
                        color: #808080;
                        line-height: 17px;
                        @include fs(13); } } } }
        .others {
            .others_cont {
                border-top: 1px solid #DEE1E7;
                padding:  30px 0 0 100px;
                @include fs(0);

                li {
                    @include fs(14);
                    width: 33.33%;
                    display: inline-block;
                    *display: inline;
                    margin-bottom: 40px;
                    .circle {
                        background-color: #8CA4B3;
                        float: left;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        @include rounded(50%);
                        margin-right: 20px;
                        color: #fff; }
                    i {
                        @include fs(18);
                        vertical-align: middle; }
                    h4 {
                        margin-bottom: 8px;
                        line-height: 16px;
                        color: #666; }
                    .text {
                        line-height: 13px;
                        @include fs(13);
                        color: #808080; } } } }
        .credit_list {
            .credit-cont {
                color: #666;
                border-top: 1px solid #DEE1E7;
                li {
                    border-bottom: 1px solid #E5E7EC;
                    padding: 23px 32px 19px;
                    &:last-child {
                        border-bottom: none; }
                    .project {
                        @include fs(16);
                        line-height: 30px; } }

                .news_t {
                    @include fs(16);
                    line-height: 28px;
                    margin-bottom: 5px;
                    a {
                        color: #666666; } }
                .logo {
                    float: left;
                    margin-right: 30px; }
                .right_cont {
                    float: right;
                    color: #FFFFFF;
                    border: 2px solid #F24964;
                    @include rounded(4px);
                    line-height: 36px;
                    i {
                        color: #F24964; }
                    .icon {
                        display: inline-block;
                        *display: inline;
                        padding: 0 8px; }
                    .txt {
                        color: #fff;
                        background-color: #F24964;
                        @include rounded(2px);
                        display: inline-block;
                        *display: inline;
                        padding: 0 8px;
                        @include fs(14);
                        line-height: 38px; } } } }

        .orders_list {
            table {
                width: 100%;
                thead {
                    line-height: 70px;
                    color: #596679;
                    @include fs(18); }
                tr {
                    border-bottom: 1px solid #E5E7EC; }
                td {
                    text-align: center;
                    height: 118px;
                    @include fs(18);
                    &.t_left {
                        text-align: left; } }
                .day,.date {
                    color: #808080; }
                .day {
                    @include fs(36);
                    margin-bottom: 3px;
                    line-height: 50px; }
                .date {
                    line-height: 25px; }
                .intro {
                    color: #666; }
                .money {
                    color: #3AC0C3;
                    @include fs(24); } } }

        .analysis_list {
            .analysis-cont {
                color: #666;
                border-top: 1px solid #DEE1E7;
                li {
                    border-bottom: 1px solid #E5E7EC;
                    padding: 23px 32px 19px;
                    &:last-child {
                        border-bottom: none; } }
                .news_t {
                    @include fs(16);
                    line-height: 28px;
                    margin-bottom: 5px;
                    a {
                        color: #666666; } }
                .time {
                    @include fs(14);
                    margin-bottom: 12px; }
                .project {
                    @include fs(14);
                    a {
                        color: #666; } } }
            .right_cont {
                color: #FF6700;
                @include fs(16);
                line-height: 30px;
                float: right;
                .txt {
                    margin-right: 10px; }
                .gap {
                    margin-right: 10px;
                    display: inline-block;
                    *display: inline;
                    height: 10px;
                    width: 1px;
                    background: #D8D8D8; }
                .price {
                    color: #3AC0C3; } } }
        .pay {
            table {
                border-top: 1px solid #DEE1E7;
                width: 100%;
                .circle {
                    display: inline-block;
                    *display: inline;
                    @include rounded(50%);
                    background-color: #D8D8D8;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    background-color: #D8D8D8;
                    color: #fff;
                    text-align: center;
                    @include fs(20);
                    &.current {
                        background-color: #3AC0C3; } }
                tr {
                    line-height: 46px;
                    background-color: #F7F9FA;
                    &:nth-child(2n) {
                        background-color: #FFF; }
                    text-align: center;
                    @include fs(16);
                    color: #666; }
                td:first-child {
                    padding-left: 40px;
                    text-align: left; }

                th {
                    @include fs(18);
                    color: #666;
                    line-height: 78px;
                    text-align: center; }
                th:first-child {
                    text-align: left;
                    padding-left: 40px; } }
            .options {
                padding: 40px;
                p {
                    color: #596679;
                    @include fs(13);
                    margin-bottom: 10px; }
                .vc-btn {
                    background-color: #F24964; }
                .disable {
                    background-color: #D8D8D8; } } } } }

.pc-qianzai-setting {
    .cont-set {
        width: 916px;
        margin: 0 auto;
        background-color: #FFF;
        border: 1px solid rgba(198,200,206,.5);
        margin-bottom: 20px; }
    .user-set-cont {
        padding-top: 40px;
        background-color: #FFF;
        .part {
            border: none; } } }


//潜在公告
.vc_notice {
    background-color: #4A90E2;
    padding: 11px 0px 11px 0px;
    color: #fff;
    line-height: 28px;
    @include fs(18);
    position: absolute;
    left: 40px;
    top: 20px;
    @include rounded(30px);
    p {
        padding: 0 48px 0 20px; }
    .close {
        color: #fff;
        position: absolute;
        cursor: pointer;
        right: 15px; } }





