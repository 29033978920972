
// $orange: #ee6e50
$orange_hover: #DE525B;
$red: #f56467;
$black: #333333;
$gray: #666666;
$error_red: #D55050;
$error_bg: #FFF2F2;
$error-border: #FFDBDB;
$red-link: #FD6639;

$unclickable: #929292;


// $s_color: #EE6E50
$t_color: #333;
$c_color: #808080;
$i_color: #B3B3B3;

$color-line: #e5e5e5;
$color-title: #1a1a1a;
$color-gray: #808080;
$color-disable: #bbbbbb;

$bc-1: #b95c4d;
$bc-2: #4a97a3;
$bc-3: #cf986d;
$bc-4: #92a89e;

$color-bar: #f2f2f2;
$color-dot: #b3b3b3;
// 修改主色
$main_blue: #C5002B;
$main_blue_v2: #C5002B;
$tmt-color-red: #C5002B;
$main_current: #2CFFFF;
$s_color: #C5002B;
$orange: #C5002B;
$main: #E4C06A;
$main_hover: #2CFFFF;
$orange_hover: #C5002B;
$tmt_color_red: #C5002B;
$tmt_color_red_hover: #A20011;

