.pc-user {
	* {
		box-sizing: unset; } }
.user-set-cont {
	width: 700px;
	margin: 0 auto;
	text-align: center;
	.title {
		@include fs(24);
		color: $t_color;
		margin-bottom: 40px; }
	.avatar {
		width: 100px;
		height: 100px;
		margin: 0 auto 80px;
		cursor: pointer;
		img {
			@include rounded(50%); }
		.change-avatar {
			width: 100%;
			height: 25px;
			line-height: 26px;
			display: inline-block;
			*display: inline {
				background-color: #fff;
				background-color: rgba(255,255,255,.9);
				position: relative;
				top: -35px; } } }
	.user-form {
		text-align: left;
		position: relative;
		label {
			display: block;
			margin-bottom: 18px;
			@include fs(18);
			color: $t_color;
			>.tmt_rz_a {
				color: #C5002B;
				font-size: 14px;
				float: right;
				display: flex;
				align-items: center;
				&:before {
					content: '';
					display: inline-block;
					width: 16px;
					height: 16px;
					background: url(/public/img/user_v2/icon_a.png);
					background-size: 100% 100%;
					margin-right: 5px;
					position: relative;
					top: -1px; } } }
		.btn_v1 {
			text-align: center;
			background-color: #FFF;
			border: 1px solid $main_blue;
			color: $main_blue;
			line-height: 40px;
			padding: 0 17px;
			@include fs(14);
			vertical-align: bottom;
			&.blue {
				background-color: $main_blue;
				color: #fff; }
			&.large {
				line-height: 43px;
				padding: 0 62px;
				@include fs(18); }
			&.banner {
				width: 100%;
				padding-left: 0;
				padding-right: 0; } }
		.form-part {
			padding-bottom: 18px;
			border-bottom: 1px #f2f2f2 solid;
			margin-bottom: 30px;
			position: relative;
			.input_profile,.input_profile_txt {
				width: 500px;
				height: 20px; }
			textarea.input_profile {
				height: 40px;
				max-height: 172px;
				resize: none; }
			.input_profile_txt,.country_code_txt {
				border: 1px solid #fff;
				overflow: hidden;
				height: auto;
				@include fs(16); }
			&:last-child {
				border: none; }
			.show {
				overflow: visible;
				.btn-bd {
					position: relative;
					top: -10px; } }
			#wechat_qr {
				right: -71px;
				left: auto;
				top: -260px;
				z-index: -1; }
			#login_mobile {
				padding-left: 10px;
				padding: 10px 0px 10px 10px;
				height: unset; }
			.sel_area {
				display: inline-block;
				height: auto;
				@include fs(16);
				color: #808080;
				position: relative;
				cursor: pointer;
				text-align: center;
				width: 10%;
				&.hide {
					display: none; }
				&::after {
					content: "";
					display: inline-block;
					width: 1px;
					height: 25px;
					background-color: #D8D8D8;
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -13px; }
				i {
					vertical-align: text-bottom; } }
			.dropdown-menu {
				padding: 10px 0;
				top: 14px;
				left: -94px;
				&:before {
					right: calc(50% - -32px);
					right: -webkit-calc(50% - -32px);
					right: -moz-calc(50% - -32px); }
				.areas {
					max-height: 350px;
					overflow: scroll;
					li {
						display: block;
						width: 198px;
						padding: 0 19px;
						line-height: 40px;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						&:hover {
							background-color: #F5F5F5; } } } } }
		.form_pop {
			position: fixed;
			width: 302px;
			height: 303px;
			top: 50%;
			left: 50%;
			//margin-left: -250px
			//margin-top: -201px
			padding: 50px 99px;
			background-color: #FFF;
			box-shadow: 0 0 50px 0 rgba(0,0,0,.3);
			transform: translate(-50%, -50%);
			input[type="text"],input[type="password"] {
				border: none;
				padding: 0 5px 5px;
				line-height: 23px;
				border-bottom: 1px solid #D8D8D8;
				width: 500px;
				@include fs(16);
				color: $black;
				&.middle {
					width: 363px;
					margin-right: 25px; }
				&.s_middle {
					width: 330px;
					margin-right: 25px; }
				&.small {
					width: 150px;
					margin-right: 20px; } }
			.form_msg {
				top: 50px; }
			.tit {
				padding-top: 87px;
				color: #666;
				@include fs(24);
				line-height: 28px;
				margin-bottom: 53px; }
			.form_part {
				margin-bottom: 50px; }
			.close-btn {
				position: absolute;
				//top: -20%
				//right: -8%
				top: 40px;
				right: 40px;
				color: #A0A0A0;
				@include fs(23);
				cursor: pointer;
				transform: translate(0,0) translateZ(0);
				-webkit-transform: translate(0,0) translateZ(0); } }
		.hide {
			display: none; }
		.captcha-box {
			margin-top: -13px;
			.img-box {
				margin-top: -12px;
				margin-right: 12px;
				display: inline-block;
				left: 0;
				top: 0;
				img {
					width: 105px;
					height: 42px; } } }
		.form_part {
			margin-bottom: 36px;
			overflow: visible;
			white-space: nowrap;
			i {
				cursor: pointer;
				&:hover {
					color: #333; } } }
		.part-reminder {
			@include fs(16);
			color: gray;
			margin-bottom: 18px;
			i {
				margin-right: 10px; }
			span {
				color: $tmt_color_red; }
			a {
				margin-left: 10px;
				color: $tmt_color_red; }
			.icon-sina {
				color: #F56467; }
			.icon-tencent {
				color: #38A6D7; }
			.icon-wechat {
				color: #44B549; } }
		.right-button {
			position: absolute;
			top: 50%;
			margin-top: -15px;
			right: 0;
			button.fr {
				display: block;
				margin-bottom: 10px; }
			.modify-btns {
				.btn-code {
					display: block;
					margin-top: 10px; } } }
		.top {
			top: 52px; }
		.radio {
			display: inline-block;
			*display: inline {
				margin-right: 50px; } }
		.line {
			color: #808080;
			margin: 0 5px; }
		.input_data {
			border: none;
			color: #808080;
			&.year {
				width: 30px; }
			&.month {
				width: 10px; }
			&.day {
				width: 20px; } }
		// 实名认证
		.real_name_box {
			._item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 30px;
				._title {
					color: #808080;
					font-size: 16px; }
				._content {
					font-size: 16px;
					color: #333333; }
				.audit_step {
					color: #999999; }
				.apply_step {
					font-size: 14px;
					color: #C5002B;
					padding: 8px 22px;
					border: 1px solid #C5002B;
					border-radius: 20px; }
				.rejected_step {
					font-size: 14px;
					color: #fff;
					padding: 8px 22px;
					border: 1px solid #C5002B;
					border-radius: 20px;
					background: #C5002B; }
				._get_number {
					display: inline-block;
					width: 20px;
					height: 15px;
					margin-left: 10px;
					background: url('/public/img/live/rz/icon_ck.png');
					background-size: 100% 100%;
					cursor: pointer;
					position: relative;
					top: 2px; } } }
		// 钛媒体官方认证
		.tmt_rz {
			label {
				margin-bottom: 25px; }
			.rz_box {
				width: 100%;
				.item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 30px;
					&:last-child {
						margin: 0; }
					.title {
						color: #808080;
						font-size: 16px;
						margin: 0; }
					.rz_btn {
						padding: 8px 22px;
						border-radius: 15px;
						border: 1px solid #D8D8D8;
						color: #909090;
						font-size: 14px;
						line-height: 14px;
						cursor: pointer;
						&.refer {}
						&.audit {
							color: #C5002B; }
						&.auth {}
						&.special {
							color: #C5002B;
							border: 1px solid #C5002B; }
						&.certified {
							color: #C5002B;
							border: 1px solid #C5002B; } } } }
			.auth_box {
				width: 100%;
				.tips {
					width: 100%;
					background: #FFF9F2;
					color: $tmt_color_red;
					font-size: 12px;
					padding: 5px 10px;
					margin-bottom: 10px; }
				.item {
					width: 100%;
					background: #FAFAFA;
					border-radius: 4px;
					border: 1px solid #EDEDED;
					padding: 40px;
					margin-bottom: 30px;
					.item_info {
						display: flex;
						align-items: center;
						width: 100%;
						margin-bottom: 20px;
						.logo {
							width: 40px;
							height: 40px;
							max-width: 40px;
							margin-right: 20px; }
						.tit {
							color: #333;
							font-size: 18px;
							display: flex;
							flex-direction: column;
							span {
								color: #999999;
								font-size: 12px; } }
						&.jg_rz {
							justify-content: space-between;
							margin-bottom: 0px;
							>div {
								display: flex;
								align-items: center; } } }
					.rz_li {
						border-top: 1px dashed #EDEDED;
						width: 100%;
						padding: 20px 0;
						display: flex;
						justify-content: space-between;
						.rz_name {
							color: #333;
							font-size: 18px;
							display: flex;
							align-items: center;
							span {
								color: #999999;
								font-size: 12px;
								display: inline-block;
								margin-left: 10px; } } }
					.rz_a {
						color: #C5002B;
						padding: 8px 15px;
						border: 1px solid #C5002B;
						border-radius: 20px; }
					.rz_a_has {
						color: #fff;
						padding: 8px 15px;
						border: 1px solid #C5002B;
						border-radius: 20px;
						background: #C5002B; }
					.rz_a_grey {
						color: #999999;
						padding: 8px 15px;
						border: 1px solid #999999;
						border-radius: 20px; } } } } }
	.form_msg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		ul {
			list-style-type: none; }
		li,p {
			max-width: 70%;
			display: inline-block;
			padding: 12px 20px 10px;
			color: #594042;
			@include fs(13);
			line-height: 1em;
			background-color: #FFF2F2;
			border: 1px solid #FFDBDB; } } }
.vc-set {
	.avatar {
		margin-bottom: 30px; }
	.user-form .bio-part {
		border: none;
		margin-bottom: 80-18px; }
	.bio,.set-bio {
		margin-bottom: 20px; }
	.bio {
		text-align: center;
		font-size: 14px;
		line-height: 24px;
		color: #808080; }
	.set-bio-input {
		width: 100%;
		margin-bottom: 18px; }
	.tc {
		.btn {
			margin: 0 10px; } }
	.set-part {
		.input-label {
			display: inline-block;
			font-size: 16px;
			width: 96px;
			margin-right: 10px;
			margin-bottom: 0;
			line-height: 38px;
			border: 1px #D8D8D8 solid; }
		@include rounded(5px);
		padding: 30px;
		.input_normal {
			width: 320px;
			@extend .border-box;
			font-size: 16px; }
		.little-input {
			width: 160px; }
		.part {
			margin-bottom: 30px; }
		.get-code {
			text-align: center;
			background-color: #f2f2f2;
			display: inline-block;
			width: 140px;
			line-height: 38px;
			color: $main;
			font-size: 16px;
			margin-left: 20px;
			cursor: pointer; }
		.tc {
			padding-top: 10px; } }
	.hide-form {
		.input-label {
			width: 80px; }
		.little-input {
			width: 140px;
			margin-right: 20px; }
		.start-time {
			color: #333;
			font-size: 16px;
			margin-bottom: 21px;
			display: inline-block;
			.until_now {
				margin-left: 20px; } } }
	.identity-part {
		.scale-label {
			vertical-align: top;
			margin-right: 20px; }
		.radios {
			display: inline-block;
			input {
				margin-right: 10px; } }
		.infor {
			margin-bottom: 20px;
			label {
				width: 102px;
				font-size: 16px;
				color: #909090;
				display: inline-block;
				margin-bottom: 0;
				margin-right: 63px; } }
		.txt {
			font-size: 16px;
			color: #333; }
		.dropdown-part {
			margin-top: 0px;
			display: inline-block; } }
	.investor-part {
		label {
			width: 165px;
			display: inline-block;
			input {
				margin-right: 5px; } } }
	.case-part {
		.top {
			top: 10px; }
		.bottom {
			top: auto; }
		.case-info {
			margin-bottom: 40px; }
		.infor {
			position: relative;
			margin-bottom: 20px;
			label {
				width: 150px;
				font-size: 16px;
				color: #909090;
				display: inline-block;
				margin-bottom: 0;
				margin-right: 63px; }
			.txt {
				font-size: 16px;
				color: #333; } }
		.set-part {
			label {
				width: 145px;
				margin-right: 10px;
				margin-bottom: 0; }
			.s-label {
				display: inline-block;
				width: auto;
				input {
					margin-right: 7px; } }
			.part {
				margin-bottom: 20px; }
			.middle_input {
				width: 235px; }
			.dropdown-part,.little-input {
				margin-right: 20px;
				width: 140px; }
			.start-time {
				margin-bottom: 0px; }
			.dropdown-part {
				margin-bottom: 0;
				display: inline-block;
				.dropdown-btn {
					width: 140px;
					padding: 8px 10px 5px 10px;
					border-color: #d9d9d9; }
				.dropdown-list {
					width: 138px; } } } } }

// 用户详情页
$fore_color: #FD6639;
$tit_color: #333;
$cont_color: #808080;
$info_color: #B3B3B3;

.mod-user-info {
	margin-bottom: 80px;
	#number_of_followers {
		margin-bottom: 30px;
		text-align: center;
		@include fs(16); }
	.pic {
		width: 100px;
		position: relative;
		margin-bottom: 30px;
		.level {
			@include fs(13);
			color: #fff;
			position: absolute;
			right: 6px;
			bottom: 0px;
			background-color: $fore_color;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			@include rounded(50%);
			border: 2px solid #fff;
			i {
				line-height: 22px;
				font-size: 16px; } }
		.blue-v {
			background-color: #4EAAE9; }
		.txt {
			width: 100px;
			height: 100px;
			display: inline-block;
			*display: inline {
				text-align: center;
				line-height: 100px;
				background-color: $s_color; }
			@include rounded(50%);
			color: #fff;
			font-size: 40px; }
		img {
			@include rounded(50%); } }
	.name {
		margin-bottom: 20px;
		@include fs(36);
		color: $tit_color;
		text-align: center;
		padding-bottom: 0;
		border: none; }
	.bio {
		@include fs(14);
		width: 640px;
		text-align: center;
		margin: 0 auto 30px auto;
		color: $cont_color; }
	.info {
		width: 258px;
		margin-bottom: 40px;
		.child {
			padding-top: 8px;
			text-align: center;
			cursor: pointer;
			display: inline-block;
			width: 120px; }
		.line {
			height: 50px;
			margin-top: -35px;
			width: 2px;
			vertical-align: middle;
			border-left: 1px solid #E4E4E4;
			display: inline-block;
			*display: inline; }
		.t {
			display: block;
			@include fs(14);
			margin-bottom: 5px;
			color: $info_color;
			padding-bottom: 0;
			border: none; }
		.num {
			font-weight: normal;
			color: $tmt_color_red;
			@include fs(24);
			&:hover {
				color: $tmt_color_red-hover; } } }
	.options {
		text-align: center; } }

.list-page.tag {
	.user-article-list {
		.mod-article-list {
			width: 700px;
			margin: 0 auto; }
		.tit {
			padding-bottom: 20px;
			@include fs(16);
			@extend .tc;
			border-bottom: 1px #cccccc solid;
			margin-bottom: 5px;
			color: $black; } } }
//排序下拉
.list-page.tag {
	.mod-user-info {
		.clear {
			overflow: visible; } }
	.mod-user-info,.tag-article-list {
		.tit {
			text-align: left; }
		.dropdown-menu-part {
			.dropdown-menu {
				right: 0px;
				padding: 3px 0px;
				line-height: 32px;
				@include rounded(3px);
				a {
					display: inline-block;
					*display: inline {
						padding-right: 0;
						margin-right: 0;
						color: #666666; }
					&:hover {
						color: $main; } }
				.current {
					color: $main; }
				&::before {
					right: calc(50% - 24px);
					right: -webkit-calc(50% - 24px);
					right: -moz-calc(50% - 24px); } } }
		.sort-by {
			.tit {
				width: 190px;
				color: gray;
				text-align: center;
				@include fs(13);
				border: 1px solid #d9d9d9;
				line-height: 25px;
				@include rounded(3px);
				cursor: pointer;
				padding-bottom: 0; }
			a {
				width: 175px!important;
				text-align: left!important;
				padding: 0 15px; }
			i {
				@include fs(18);
				vertical-align: middle;
				margin-left: 5px; }
			.dropdown-menu {
				&::before {
					right: calc(50% - 78px);
					right: -webkit-calc(50% - 78px);
					right: -moz-calc(50% - 78px); } } }
		.tag-sort-by {
			.tit {
				width: 160px; }
			a {
				width: 146px!important;
				padding: 0 15px; }
			.dropdown-menu {
				&::before {
					right: calc(50% - 61px);
					right: -webkit-calc(50% - 61px);
					right: -moz-calc(50% - 61px); } } } } }
.user-detail {
	width: 840px;
	.user-article-list {
		.mod-article-list {
			width: 700px;
			margin: 0 auto; }
		.tit {
			padding-bottom: 20px;
			@include fs(16);
			@extend .tc;
			border-bottom: 1px #e5e5e5 solid;
			margin-bottom: 5px;
			color: $black; }
		.no-author {
			line-height: 24px; }
		.user-nav {
			margin-bottom: 30px;
			.parts {
				background-color: #F6F6F6;
				display: inline-block; }
			.part {
				display: inline-block;
				line-height: 38px;
				width: 215px;
				color: #808080;
				@include fs(14);
				&.current {
					color: $tmt_color_red;
					&:hover {
						color: $tmt_color_red-hover; } } }
			.line {
				height: 14px;
				display: inline-block;
				vertical-align: middle;
				border-right: 1px solid #d7d7d7; } } }
	.user-state {
		padding-top: 30px;
		.reminder {
			text-align: center;
			@include fs(16);
			color: #b3b3b3;
			line-height: 21px;
			margin-top: 60px; }
		.top-circle {
			margin-left: 149px;
			.circle-b {
				width: 26px;
				height: 26px;
				border: 2px #e5e5e5 solid;
				text-align: center;
				@include rounded(50%); }
			.circle-c {
				display: inline-block;
				width: 18px;
				height: 18px;
				background-color: $tmt_color_red;
				@include rounded(50%);
				margin-top: 4px; } }
		.state-list {
			margin-top: -6px;

			li {
				.l-cont {
					padding-top: 40px;
					float: left;
					margin-right: 33px;
					position: relative;
					width: 130px;
					time {
						@include fs(14);
						color: $black; }
					.point {
						position: absolute;
						right: -39px;
						top: 45px;
						display: inline-block;
						height: 10px;
						width: 10px;
						background-color: $tmt_color_red;
						@include rounded(50%); } }
				.r-cont {
					padding-top: 40px;
					overflow: hidden;
					padding-left: 34px;
					border-left: 2px solid #e5e5e5;
					line-height: 20px;
					a {
						color: $tmt_color_red;
						&:hover {
							color: $tmt_color_red-hover; } }
					.inner {
						@include fs(16);
						border-bottom: 1px solid #f2f2f2;
						padding-bottom: 26px; }

					.head-line {
						@include fs(16);
						color: $black;
						margin-bottom: 12px; }
					.title {
						margin-bottom: 14px;
						@include fs(16); }
					p {
						color: #808080;
						margin-bottom: 14px; }
					.about {
						span {
							float: left; }
						a {
							overflow: hidden;
							display: block; } }
					p.dialogue {
						a {
							@include fs(13);
							text-decoration: underline; } } }
				&:first-child {
					.l-cont,.r-cont {
						padding-top: 20px; }
					.point {
						top: 25px; } }
				&:last-child {
					.r-cont .inner {
						border-bottom: none; } } } }
		.load-more-state {
			margin-top: 60px; } } }

#change-password .btn-part {
	margin-top: 50px; }
#change-avatar {
	.current_avatar {
		margin-bottom: 30px;
		img {
			@include rounded(50%); } }
	.select {
		margin-bottom: 15px; }
	.process,.process_s {
		height: 20px;
		margin-bottom: 20px; }
	.info {
		color: $i_color; }
	.upload {
		position: relative;
		overflow: hidden; }
	.upload_input {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		opacity: 0;
		-ms-filter: 'alpha(opacity=0)';
		font-size: 200px;
		direction: ltr;
		cursor: pointer; }
	.crop_wrap {
		margin-bottom: 30px;
		position: relative; }
	.crop_wrap_zoom {
		padding: 0 20px;
		position: relative;
		margin-bottom: 30px;
		.small,.big {
			position: absolute;
			top: -5px; }
		.icon-pic {
			font-size: 14px;
			color: #666;
			cursor: pointer; }
		.small {
			left: 0;
			margin-left: -20px; }
		.big {
			font-size: 14px;
			right: 0;
			top: -11px;
			margin-right: -25px;
			.icon-pic {
				font-size: 24px; } } } }

#floatingCirclesG {
	display: inline-block;
	*display: inline {
		vertical-align: middle;
		margin-top: -3px;
		position: relative;
		width: 20px;
		height: 20px;
		-moz-transform: scale(0.6);
		-webkit-transform: scale(0.6);
		-ms-transform: scale(0.6);
		-o-transform: scale(0.6);
		transform: scale(0.6); } }

.f_circleG {
	position: absolute;
	background-color: #333;
	height: 4px;
	width: 4px;
	-moz-border-radius: 2px;
	-moz-animation-name: f_fadeG;
	-moz-animation-duration: 0.8s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-direction: linear;
	-webkit-border-radius: 2px;
	-webkit-animation-name: f_fadeG;
	-webkit-animation-duration: 0.8s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: linear;
	-ms-border-radius: 2px;
	-ms-animation-name: f_fadeG;
	-ms-animation-duration: 0.8s;
	-ms-animation-iteration-count: infinite;
	-ms-animation-direction: linear;
	-o-border-radius: 2px;
	-o-animation-name: f_fadeG;
	-o-animation-duration: 0.8s;
	-o-animation-iteration-count: infinite;
	-o-animation-direction: linear;
	border-radius: 2px;
	animation-name: f_fadeG;
	animation-duration: 0.8s;
	animation-iteration-count: infinite;
	animation-direction: linear; }

#frotateG_01 {
	left: 0;
	top: 8px;
	-moz-animation-delay: 0.3s;
	-webkit-animation-delay: 0.3s;
	-ms-animation-delay: 0.3s;
	-o-animation-delay: 0.3s;
	animation-delay: 0.3s; }

#frotateG_02 {
	left: 2px;
	top: 2px;
	-moz-animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
	-ms-animation-delay: 0.4s;
	-o-animation-delay: 0.4s;
	animation-delay: 0.4s; }

#frotateG_03 {
	left: 8px;
	top: 0;
	-moz-animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-ms-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
	animation-delay: 0.5s; }

#frotateG_04 {
	right: 2px;
	top: 2px;
	-moz-animation-delay: 0.6s;
	-webkit-animation-delay: 0.6s;
	-ms-animation-delay: 0.6s;
	-o-animation-delay: 0.6s;
	animation-delay: 0.6s; }

#frotateG_05 {
	right: 0;
	top: 8px;
	-moz-animation-delay: 0.7s;
	-webkit-animation-delay: 0.7s;
	-ms-animation-delay: 0.7s;
	-o-animation-delay: 0.7s;
	animation-delay: 0.7s; }

#frotateG_06 {
	right: 2px;
	bottom: 2px;
	-moz-animation-delay: 0.8s;
	-webkit-animation-delay: 0.8s;
	-ms-animation-delay: 0.8s;
	-o-animation-delay: 0.8s;
	animation-delay: 0.8s; }

#frotateG_07 {
	left: 8px;
	bottom: 0;
	-moz-animation-delay: 0.9s;
	-webkit-animation-delay: 0.9s;
	-ms-animation-delay: 0.9s;
	-o-animation-delay: 0.9s;
	animation-delay: 0.9s; }

#frotateG_08 {
	left: 2px;
	bottom: 2px;
	-moz-animation-delay: 1s;
	-webkit-animation-delay: 1s;
	-ms-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s; }

@-moz-keyframes f_fadeG {
	0% {}
	background-color: #fff;
	100% {}
	background-color: #333; }

@-webkit-keyframes f_fadeG {
	0% {}
	background-color: #fff;
	100% {}
	background-color: #333; }

@-ms-keyframes f_fadeG {
	0% {}
	background-color: #fff;
	100% {}
	background-color: #333; }

@-o-keyframes f_fadeG {
	0% {}
	background-color: #fff;
	100% {}
	background-color: #333; }

@keyframes f_fadeG {
	0% {
		background-color: #fff; }
	100% {
		background-color: #333; } }

//移动版样式，覆盖原有样式
@media (max-width: 720px) {
	.user-set-cont {
		.user-form {
			.form_pop {
				input[type="text"],input[type="password"] {
					width: 100%;
					&.middle {
						width: 50%;
						margin-right: 25px; }
					&.s_middle {
						width: 50%;
						margin-right: 25px; }
					&.small {
						width: 35%;
						margin-right: 20px; } } } } } }
