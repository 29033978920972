.pc-atlas {
	* {
		box-sizing: unset; } }

.pc-atlas-list {
	.lang-tags {
		.mod-article-list {
			.cont {
				.intro,p {
					margin-bottom: 0; }
				.intro {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical; } }
			.pic {
				.img_box {
					display: inline-block;
					position: relative; }
				.photo-tag {
					position: absolute;
					top: 0;
					left: 0;
					border-left: 4px solid #FF3399;
					padding: 0 9px;
					background-color: #000;
					font-size: 14px;
					font-size: 1.4rem;
					line-height: 24px;
					color: #fff;
					z-index: 3; } } } } }
.pc-atlas {
	.atlas_tip {
		top: 30% !important;
		padding: 12px 70px 12px 70px !important; }
	// .transform_im
	//     transform: translate3d(0px, 0px, 0px) !important
	.height_4 {
		height: 480px !important; }
	.height_6 {
		height: 600px !important; }
	.no {
		display: none !important; }
	.no_hi {
		visibility: hidden !important; }
	.atlas_wapper {
		width: 900px;
		margin: 55px auto 100px;
		padding: 50px 0;
		.first_one,.last_one {
			opacity: .35 !important;
			cursor: default; }
		.zoom {
			width: 80%;
			margin: 0 auto;
			.txt_box {
				.txt_both {
					font-size: 14px !important; } }
			.big_photo {
				.photo_top {
					.gallery-top {
						height: 480px; } } }
			.last_box {
				padding-left: 7% !important;
				padding-right: 7% !important; }
			.last_li {
				margin-bottom: 3%; }
			.bottom-arrows {
				.swiper-button-next {
					right: 20px; }
				.swiper-button-prev {
					left: 20px; } }
			.gallery-thumbs {
				.swiper-wrapper {
					margin-left: -39.8%; } } }
		.photo_box {
			margin-bottom: 40px;
			h1 {
				margin-bottom: 10px; }
			.ac_box {
				font-size: 14px;
				color: #323232;
				margin-bottom: 20px;
				display: inline-block;
				text-align: left;
				line-height: 16px;
				.ac {
					a {
						color: #C5002B; } } }
			.info_author {
				@include flex_sc();
				justify-content: flex-start;
				margin: 20px 0;
				>a {
					display: inline-block; }
				img {
					width: 30px;
					height: 30px;
					min-width: 30px;
					margin-right: 10px;
					border-radius: 50%; }
				.name {
					color: $tmt_color_red;
					font-size: 14px;
					@include ff();
					margin-right: 4px; }
				.time {
					color: #999999;
					font-size: 14px;
					@include ff(); } }
			.about {
				font-size: 18px;
				color: #333333;
				padding-bottom: 20px;
				text-align: center;
				border-bottom: 1px solid #E5E5E5;
				margin: 0 90px; }
			.about_photo {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				// flex-wrap: wrap
				padding: 40px 90px;
				.img_box {
					margin-bottom: 16px;
					position: relative;
					img {
						width: 220px;
						height: auto; } }
				.about_text {
					font-size: 16px;
					color: #333333;
					line-height: 24px;
					text-align: left; }
				li {
					width: 30%;
					margin-right: 3%;
 } }					// width: 220px
			.big_photo {
				position: relative; }
			.photo_top {
				position: relative;
				.swiper-button-next, .swiper-button-prev {
					margin-top: -25px;
					width: 50px;
					height: 50px;
					background-size: 15px;
					z-index: 2; }
				.swiper-button-prev {
					left: -90px; }
				.swiper-button-next {
					right: -90px; } }
			.txt_box {
				display: flex;
				justify-content: space-between;
				padding-top: 8px;
				// height: 79px
				// overflow: hidden
				.txt_both {
					// display: flex
					text-align: left;
					font-size: 16px;
					color: #151515;
					// height: 75px
 }					// overflow: hidden
				.num_both {
					font-size: 18px;
					color: #151515;
					display: inline-block;
					margin-right: 5px;
					.curr {
						font-size: 24px;
						color: #FF1E56; } }
				.like_box {
					margin-top: 15px;
					padding: 0 30px 0;
					text-align: center;
					border-left: 1px solid rgba(0,0,0,0.2);
					margin-left: 30px;
					height: 40px; }
				.like {
					display: flex;
					flex-direction: column;
					width: 30px;
					i {
						font-size: 28px; }
					.num {
						color: #C5002B;
						font-size: 14px; }
					.like_c {
						display: none; } }
				.current {
					.like_n {
						display: none; }
					.like_c {
						display: inline-block; } } }
			.post-options {
				font-size: 14px;
				color: #B3B3B3;
				line-height: 18px;
				padding: 0 90px;
				overflow: visible;
				margin-top: 10px;
				margin-bottom: 50px;
				i {
					color: #B3B3B3; }
				.icon-weibo {
					margin: 0 20px 0 15px;
					&:hover {
						color: #E74C3C; } }
				.icon-weixin {
					&:hover {
						color: #27AE60; } }
				.comment,.favourite,.num {
					&:hover {
						color: #C5002B;
						i {
							color: #C5002B; } } }
				.icon-ribbon-1 {
					display: none; }
				.current {
					.icon-ribbon {
						display: none; }
					.icon-ribbon-1 {
						display: inline-block;
						color: #C5002B; }
					.num {
						color: #C5002B; } } }
			.options {
				font-size: 13px;
				color: #808080;
				display: inline-block;
				a {
					display: inline-block;
					margin-right: 30px;
					color: #808080;
					i {
						margin-right: 5px; } } }
			.gallery-top {
				position: relative;
				height: 600px;
				transition-duration: 300ms;
				.swiper-wrapper {
					transition-duration: 300ms; }
				.swiper-slide {
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
 }					// background-color: #000
				.upper_layer_box {
					position: absolute;
					top: 0;
					width: 100%;
					.swiper-button-white {
						position: initial;
						top: 0;
						margin-top: 10px; } }
				.arrows_box {
					display: flex;
					width: 100%;
					padding: 0 30px;
					justify-content: space-between;
					align-items: center; }
				.top_box {
					display: flex;
					justify-content: space-between; }
				.avatar {
					margin-bottom: 20px;
					img {
						width: 34px;
						height: 34px;
						border: 1px solid #FFFFFF;
						border-radius: 50%;
						display: inline-block;
						margin-right: 14px; }
					.name {
						font-size: 13px;
						color: rgba(0, 164, 160, 0.8);
						text-align: left;
						line-height: 15px; } }
				.options {
					display: flex;
					justify-content: space-around;
					a,i,span {
						color: #808080;
						font-size: 13px; }
					i {
						margin-right: 10px;
 } } }						// display: none
			.bottom-arrows {
				width: 80%;
				// height: 30px
				.swiper-button-next, .swiper-button-prev {
					bottom: 74px !important;
					top: auto !important;
					margin-top: 0;
					z-index: 1; }
				.swiper-button-next {
					right: 35px; }
				.swiper-button-prev {
					left: 35px; } } }
		.swiper-button-white {
			width: 30px;
			height: 30px;
			background-color: rgba(0,0,0,0.5);
			// background-color: #ffffff
			border-radius: 50%;
			background-size: 6px 10px; }
		.gallery-thumbs {
			padding: 40px 0;
			width: 80%;
			overflow: hidden;
			// .swiper-button-prev
			//     left: 40px
			// .swiper-button-next
			//     right: 40px
			.swiper-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				// margin-left: -288px
				margin-left: -40%;
				.proportion {
					width: 100%;
					height: 66.66%;
					width: 100%;
					height: 69.5%;
					height: 66px;
					width: 96.2px;
					width: 96.4px;
					height: 64px;
					background: #ffffff;
					border: 1px #E0E0E0 solid;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat; }
				.slong {
					width: 66.66%;
					height: 100%;
					width: 64px;
					height: 96.4px; } }
			.s_last {
				.last_div {
					overflow: hidden;
					position: relative;
					// max-height: 120px
					// max-width: 90%
					.proportion {
						filter: blur(3px);
 } }						// transform: scale(1.2)
				// img
				//     filter: blur(3px)
				//     transform: scale(1.2)
				span {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.3);
					display: flex;
					// flex-direction: column
					justify-content: center;
					align-items: center;
					color: #ffffff;
					font-size: 14px;
					text-align: center;
 } } }					// height: 83px
		.last_pre_next {
			display: none;
			div {
				font-size: 18px;
				color: #C5002B;
				text-align: left;
				line-height: 24px;
				margin-top: 10px;
				a {
					color: #C5002B; } }
			.pre {
 } }				// margin-bottom: 10px
		.last_box {
			border: 3px solid rgba(64,69,77,0.10);
			box-sizing: border-box;
			padding: 6% 11% 0;
			// padding: 60px 120px 0
 }			// background: #000000
		.last_ul {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap; }
		.last_li {
			width: 28%;
			margin-bottom: 6%;
			img {
				margin-bottom: 10px; }
			.text {
				text-align: left;
				font-size: 14px;
				color: #333333; } }
		.btn_box {
			display: flex;
			justify-content: center;
			a {
				display: inline-block;
				padding: 12px 42px;
				font-size: 14px;
				color: #C5002B;
				text-align: left;
				line-height: 18px;
				text-align: center;
				border-radius: 2px; }
			.again {
				border: 2px solid #C5002B;
				margin-right: 50px; }
			.atlas_list {
				background: #C5002B;
				color: #ffffff;
				margin-left: 50px; } } }
	html, body {
		position: relative;
		height: 100%; }
	body {
		background: #000;
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		font-size: 14px;
		color: #000;
		margin: 0;
		padding: 0; }
	.photo_box {
		.swiper-container {
			width: 100%;
			height: 300px;
			margin-left: auto;
			margin-right: auto; }
		.swiper-slide {
			background-size: cover;
			background-position: center; } }
	.gallery-top {
		height: 80%;
		width: 100%; }
	.gallery-thumbs {
		height: 20%;
		box-sizing: border-box;
		padding: 10px 0; }
	.gallery-thumbs .swiper-slide {
		width: 20%;
		height: 98px;
		opacity: 1;
		text-align: center;
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		//   background: #00ff00
		//   border: 1px #E0E0E0 solid
		img {
			// border: 1px #E0E0E0 solid
			max-height: 120px;
			max-width: 90%; } }
	.gallery-thumbs .swiper-slide-active {
		// .ac_border
		.proportion {
			border: 5px #4A4A4A solid !important;
			// background-color: #4a4a4a !important
 }			// span
		.ac_border {
			border: 5px #4A4A4A solid !important;
			.proportion {
				border: none !important;
 } } }				// background-color: #ffffff !important
	//   opacity: 1
	// .aaaa
	//     position: absolute;
	//     top: 0;
	//     left: 5px;
	//     width: 100%;
	//     height: 100%;
	//     background-color: rgba(0,0,0,0.5)
	.shares {
		.wx-dropdown {
			width: 88px;
			height: 120px;
			padding: 16px 16px 10px;
			left: -51px;
			bottom: 40px; }
		.dropup-menu {
			&:before {
				content: "";
				position: absolute;
				right: calc(50% - 9px);
				right: -webkit-calc(50% - 9px);
				right: -moz-calc(50% - 9px);
				width: 13px;
				height: 7px;
				z-index: 4;
				background: url(/public/css/img/dropdown-menu-arrow.svg);
				top: initial;
				bottom: -7px;
				transform: rotate(180deg); } }
		.qrcode {
			width: 88px;
			height: 88px;
			margin-bottom: 6px; }
		.w_txt {
			font-size: 1.4rem;
			line-height: 20px;
			color: #000000; }
		.circle-wechat {
			position: relative;
			display: inline-block; } } }
.pc-atlas-list,.pc-atlas {
	.photo-tag {
		position: absolute;
		top: 0;
		left: 0;
		border-left: 4px solid #FF3399;
		padding: 0 9px;
		background-color: #000;
		font-size: 14px;
		font-size: 1.4rem;
		line-height: 24px;
		color: #fff;
		z-index: 3; }
	.bind-tip {
		display: inline-block; } }
