@charset 'utf-8';

// border-radius
@mixin rounded($radius: 2px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius; }

@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    border-top-left-radius: $topleft; }

// opacity
@mixin opacity($opacity: 0.5) {
    -moz-opacity: $opacity;
    -khtml-opacity: $opacity;
    -webkit-opacity: $opacity;
    opacity: $opacity;
    $opperc: $opacity * 100;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opperc});
    filter: alpha(opacity=#{$opperc}); }

// css3 animation
@mixin transform($arguments) {
    -webkit-transform: $arguments;
    -moz-transform: $arguments;
    -o-transform: $arguments;
    -ms-transform: $arguments;
    transform: $arguments; }
// transition
@mixin transition($duration:0.4s, $ease:ease-out,$delay:0s) {
    -webkit-transition: all $duration $ease $delay;
    -moz-transition: all $duration $ease $delay;
    -o-transition: all $duration $ease $delay;
    transition: all $duration $ease $delay; }
@mixin transition-index() {
    -webkit-transition: .1s background-color,0.1s border-color,0.1s color;
    -moz-transition: .1s background-color,0.1s border-color,0.1s color;
    -ms-transition: .1s background-color,0.1s border-color,0.1s color;
    -o-transition: .1s background-color,0.1s border-color,0.1s color;
    transition: .1s background-color,0.1s border-color,0.1s color; }
@mixin transition-duration($duration: 0.2s) {
    -moz-transition-duration: $duration;
    -webkit-transition-duration: $duration;
    -o-transition-duration: $duration;
    transition-duration: $duration; }

@mixin transformX($translateX: 0px) {
    transform: translateX($translateX);
    -ms-transform: translateX($translateX) /* IE 9 */;
    -moz-transform: translateX($translateX) /* Firefox */;
    -webkit-transform: translateX($translateX) /* Safari and Chrome */;
    -o-transform: translateX($translateX); }
@mixin transformY($translateY: 0px) {
    transform: translateY($translateY);
    -ms-transform: translateY($translateY) /* IE 9 */;
    -moz-transform: translateY($translateY) /* Firefox */;
    -webkit-transform: translateY($translateY) /* Safari and Chrome */;
    -o-transform: translateY($translateY); }
@mixin transform($translateX: 0px,$translateY: 0px) {
    transform: translate($translateX,$translateY);
    -ms-transform: translate($translateX,$translateY) /* IE 9 */;
    -moz-transform: translate($translateX,$translateY) /* Firefox */;
    -webkit-transform: translate($translateX,$translateY) /* Safari and Chrome */;
    -o-transform: translate($translateX,$translateY); }
@mixin rotate($deg: 0deg) {
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -webkit-transform: rotate($deg);
    transform: rotate($deg); }

@mixin fs($size) {
    font-size: $size+px;
    font-size: $size/10+rem; }
@mixin blur($px) {
    filter: url(blur.svg#blur) /* FireFox, Chrome, Opera */;
    -webkit-filter: blur($px+px) /* Chrome, Opera */;
    -moz-filter: blur($px+px);
    -ms-filter: blur($px+px);
    filter: blur($px+px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=$px, MakeShadow=false) /* IE6~IE9 */; }

@mixin plh($color) {
    /* Firefox 18- */
    input:-moz-placeholder, textarea:-moz-placeholder {
        color: $color; }
    /* Firefox 19+ */
    input::-moz-placeholder, textarea::-moz-placeholder {
        color: $color; }
    /* IE10+ */
    input:-ms-input-placeholder, textarea:-ms-input-placeholder {
        color: $color; }
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        color: $color; } }
@mixin font($color,$fontSise,$lineHeight) {
    color: $color;
    font-size: $fontSise+px;
    line-height: $lineHeight+px;
    @include ff(); }
@mixin ff() {
    // font-family: HYQiHei
    font-family: "Helvetica"; }
@mixin flex_sc() {
    display: flex;
    justify-content: space-between;
    align-items: center; }
