.award_page {
    .nav {
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 22px;
        font-size: 0;
        margin-bottom: 50px;
        .nav_tit {
            display: inline-block;
            width: 50%;
            font-size: 1.6rem;
            text-align: center;
            position: relative;
            a {
                color: #333333; }
            &.current {
                a {
                    color: $main_blue; } }
            &:first-child:after {
                content: "";
                display: inline-block;
                height: 18px;
                width: 1px;
                background-color: #BBBBBB;
                position: absolute;
                top: 3PX;
                right: -1px; } } }

    //评论
    .post-comment {
        h2 {
            @include fs(18);
            line-height: 18px;
            padding-bottom: 20px;
            border-bottom: 1px #e5e5e5  solid;
            margin-bottom: 50px;
            @extend .tc; }
        .user-name {
            margin-bottom: 18px;
            h3.name {
                line-height: 40px;
                color: #B3B3B3;
                @include fs(14);
                display: inline; }
            .captcha-part {
                margin-left: 70px; }
            .login-reminder {
                line-height: 36px;
                color: #929292;
                @include fs(14); } }
        .name-input,.captcha-input {
            height: 36px;
            width: 320px;
            line-height: 34px;
            border: 1px #D9D9D9 solid;
            @include rounded(4px);
            padding: 0 15px 0 10px;
            @include fs(13);
            color: #666;
            margin-right: 19px; }
        .captcha-input {
            width: 150px;
            margin-right: 10px; }
        #captcha_image {
            cursor: pointer; }
        .get-captcha {
            display: inline-block;
            *display: inline;
            line-height: 36px;
            color: #d9d9d9;
            position: relative;
            top: -2px;
            cursor: pointer;
            i {
                margin-left: 8px;
                vertical-align: middle;
                -moz-transition: all 0.1s ease-in-out;
                -webkit-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                -ms-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out; } }
        .avatar {
            img {
                @include rounded(50%);
                margin: -2px 5px 0 0; } }
        .comment-form {
            margin-bottom: 50px;
            textarea {
                padding: 10px 15px 15px 10px;
                @include fs(13);
                width: 700px;
                height: 70px;
                border: 1px #d8d8d8 solid;
                resize: none;
                max-height: 158px;
                @include rounded(4px);
                margin-bottom: 17px;
                color: #666; }
            .btn {
                margin-left: 10px; } }
        .more-comment {
            padding-top: 50px;
            border-top: 1px #f2f2f2 solid; }
        .comment-list {
            .js-praise {
                line-height: 38px; }
            .child_comment {
                margin-top: 30px;
                padding-left: 50px;
                padding-top: 30px;
                border-top: 1px solid #E5E5E5;
                li:last-child {
                    padding-bottom: 0; } } }
        .comment_page {
            text-align: center;
            margin-top: 30px;
            .pages {
                display: inline-block;
                width: auto;
                & > a,& > span {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    color: #808080;
                    @include rounded(50%);
                    background-color: #FFF;
                    margin: 0 5px;
                    &:hover {
                        background-color: #F2F2F2; }
                    &.active {
                        background-color: $main_blue;
                        color: #fff; }
                    &.next,&.prev {
                        width: auto;
                        padding: 0 15px;
                        border: 1px solid #D8D8D8;
                        @include rounded(40px); } } } } } }


