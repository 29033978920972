.post-container#C5002B#C5002B#C5002B#C5002B#C5002B#C5002B#C5002B {
    position: relative;
    z-index: 1;
    color: #333;
    &.c-page {
        padding-bottom: 70px; }
    .app_qrcode {
        position: fixed;
        top: 55px;
        right: 80px;
        width: 168px;
        height: 262px;
        background-image: url(img/code_bg.png);
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 25px;
        padding: 34px 24px;
        box-sizing: border-box;
        text-align: center;
        a {
            display: block; }
        .code_img {
            display: inline-block;
            margin-top: 15px;
            margin-bottom: 12px; }
        .a_tit {
            margin: 0;
            padding: 0;
            line-height: 18px;
            @include fs(13);
            color: #333333;
            &.down {
                @include fs(12);
                &::before, &::after {
                    content: "";
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    background-color: #02A4A0;
                    margin: 0 5px;
                    border-radius: 50%;
                    vertical-align: middle; } } }
        .btn_close {
            position: absolute;
            left: 50%;
            bottom: -14px;
            transform: translateX(-50%);
            width: 40px;
            height: 40px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%; } } }
    article {
        padding-top: 10px;
        .pro_icon {
            text-align: left;
            margin-bottom: 15px;
            img {
                height: 28px !important;
                margin: 0 !important; } }
        h1 {
            @include fs(36);
            line-height: 1.3em;
            margin-bottom: 18px;
            .popularize {
                display: inline-block;
                background-color: #F9C509;
                color: #fff;
                font-size: 12px;
                line-height: 15px;
                padding: 0 2px;
                vertical-align: top;
                margin-top: 6px; } }
        h2 {
            @include fs(28);
            font-weight: bold;
            color: $t_color;
            margin: 33px 0; }
        h3 {
            @include fs(24);
            font-weight: bold;
            color: $t_color;
            margin: 33px 0; }
        ul,ol {
            margin: 20px;
            font-size: 16px;
            line-height: 26px; }
        ol {
            list-style-type: decimal; }
        ul {
            list-style: initial; }
        br {
            display: none; }
        p {
            text-align: left;
            br {
                display: inline-block; }
            @include fs(18);
            color: $t_color !important;
            line-height: 31px;
            // margin: 20px 0
            margin-bottom: 30px;
            span {
                color: $t_color !important; }
            a {
                color: $orange !important;
                text-decoration: underline;
                &:hover {
                    color: $orange_hover; } } }
        a {
            color: $orange !important;
            text-decoration: underline;
            &:hover {
                color: $orange_hover; } }
        .caption {
            @include fs(14);
            color: #808080;
            text-align: center;
            &:after {
                content: '\00a0';
                display: block;
                width: 30%;
                border-bottom: 1px solid #ddd;
                margin: -2% auto 0 auto;
                position: relative; } }
        img {

            display: block;
            margin: 30px auto !important;
            height: auto; }
        blockquote {
            color: #808080;
            // @include fs(14)
            font-size: 14px !important;
            font-size: 1.4rem !important;
            border-left: 5px solid #EAEAEA;
            margin: 30px 5%;
            padding-left: 3%;
            line-height: 1.7em;
            p {
                color: #808080; } }
        table {
            width: 95%; }
        .aligncenter {
            @extend .center;
            display: block; }
        .iframe-wrapper {
            width: 100%;
            max-width: 500px;
            height: 375px;
            position: relative;
            margin: 0 auto;
            iframe {
                width: 100% !important;
                height: 100% !important;
                display: block;
                margin: 0 auto;
                position: absolute; } }
        iframe {
            width: 500px !important;
            height: 375px !important;
            display: block;
            margin: 0 auto; } }
    .sm {
        @include fs(16);
        padding: 0 5%;
        margin-bottom: 30px; }
    .post-info {
        @include fs(14);
        // padding-bottom: 20px
        margin-bottom: 30px;
        // border-bottom: 1px solid #E5E5E5
        color: #B3B3B3;
        img {
            @include rounded(50%);
            margin-right: 10px;
            display: inline-block;
            *display: inline;
            margin: 0 15px 0 0 !important; }
        a {
            text-decoration: none; } }
    p.post-abstract {
        padding: 20px 30px;
        @include fs(14);
        line-height: 24px;
        color: #808080;
        margin: 0 0 40px 0;
        background-color: #f6f6f6;
        .abstract-tit {
            font-weight: bold; } }

    .sm {
        color: #9FA3A7;
        @include fs(14);
        margin-bottom: 30px;
        a {
            color: $orange; }
        .link {
            color: #205B87; } }
    .post-tags {
        margin-bottom: 20px;
        .tag {
            display: inline-block;
            *display: inline;
            border: 1px solid #f2f2f2;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            margin: 0 7px 10px;
            // padding: 3px 5px
            color: gray;
            &:hover {
                color: $main;
                border-color: $main;
                background-color: rgba(34,187,149,.05);
                .gap-line,.tag-a,.act {
                    color: $main; } }
            &:first-child {
                margin-left: 0; }
            &:last-child {
                margin-right: 0; }
            &.current {
                background-color: $main;
                border-color: $main;
                .gap-line,.tag-a,.act {
                    color: #fff; }
                .follow {
                    display: none; }
                .unfollow {
                    display: inline-block; } }
            .tag-a {
                display: inline-block;
                padding: 3px 5px 3px 10px;
                color: gray;
                @include fs(14); }
            .gap-line {
                color: gray;
                // margin: 0 3px 0 5px
                position: relative;
                top: -1px; }
            .act {
                // padding: 3px 10px 3px 0
                display: inline-block;
                color: gray;
                cursor: pointer;
                width: 15px;
                text-align: center;
                padding-right: 3px; }
            .unfollow {
                display: none;
                @include fs(18);
                line-height: 12px; } } }



    .post-options {
        margin-bottom: 30px;
        .shares {
            .txt {
                @include fs(14);
                color: #808080;
                margin-right: -5px; }
            [class^="circle"] {
                margin-left: 15px;
                border: 1px #000 solid;
                @include rounded(50%);
                display: inline-block;
                width: 40px;
                height: 40px;
                text-align: center;
                i {
                    line-height: 40px;
                    @include fs(18); } }
            .circle-weibo {
                border-color: #e74c3c;
                i {
                    color: #e74c3c; } }
            .circle-wechat {
                border-color: #44B549;
                i {
                    color: #8ee566; } }
            .circle-tencent {
                border-color: #39b2e2;
                i {
                    color: #39b2e2; } } }
        .options {
            line-height: 40px;
            a {
                @include fs(18);
                color: #808080;
                &.current,&:hover {
                    color: $s_color; } }
            i {
                vertical-align: middle;
                display: inline-block;
                *display: inline;
                margin-top: -3px;
                display: inline-block;
                *display: inline;
                @include fs(16); }
            .num {
                @include fs(14);
                margin-right: 15px; }
            .like {
                .icon-Shape9 {
                    display: none;
                    margin-top: -3px; }
                &.current {
                    .icon-Shape9 {
                        display: inline-block;
                        *display: inline;
                        -webkit-animation: scalefade 300ms;
                        animation: scalefade 300ms; }
                    .icon-like-1 {
                        display: none; } } }
            .favourite {
                .icon-ribbon-1 {
                    display: none;
                    @include fs(14);
                    margin-right: 3px;
                    margin-left: 3px; }
                &.current {
                    .icon-ribbon-1 {
                        display: inline-block;
                        *display: inline;
                        -webkit-animation: scalefade 300ms;
                        animation: scalefade 300ms; }
                    .icon-ribbon {
                        display: none; } } } } }
    .tmt-qcode {
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 20px;
        padding: 3px 0;
        .code {
            padding: 20px 30px 20px 0;
            border-right: 1px solid #E5E5E5; }
        .txt {
            overflow: hidden;
            zoom: 1;
            @include fs(14);
            margin-top: 50px;
            padding-left: 30px;
            p {
                display: inline-block;
                *display: inline;
                vertical-align: middle; }
            &:after {
                width: 0;
                vertical-align: middle;
                // height: 100%
                content: ' ';
                display: inline-block;
                *display: inline; } } }
    .authors {
        margin-bottom: 30px; }
    // bar
    .is-hide {
        -webkit-animation: fade-down-out 175ms;
        animation: fade-down-out 175ms;
        opacity: 0;
        pointer-events: none; }
    .artilce-bar {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        box-shadow: 0 0 1px rgba(0,0,0,0.2);
        -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.2);
        -moz-box-shadow: 0 0 1px rgba(0,0,0,0.2);
        height: 50px;
        line-height: 50px;
        padding: 0 3%;
        background-color: #fff;
        overflow: visible;
        .authors {
            a {
                color: #B3B3B3; }
            img {
                @include rounded(50%);
                vertical-align: middle; }
            .name {
                margin: 0 5px 0 5px;
                @include fs(14);
                color: $orange;
                &:hover {
                    color: $orange_hover; } }
            .time {
                color: #b3b3b3; } }
        .social {
            line-height: 50px;
            a {
                @include fs(18);
                color: #333;
                &.current,&:hover {
                    color: $s_color; } }
            i {
                vertical-align: middle;
                display: inline-block;
                *display: inline;
                margin-top: -3px;
                display: inline-block;
                *display: inline;
                @include fs(16); }
            .num {
                @include fs(14);
                margin-right: 15px; }
            .like {
                .icon-Shape9 {
                    display: none;
                    margin-top: -3px; }
                &.current {
                    .icon-Shape9 {
                        display: inline-block;
                        *display: inline;
                        -webkit-animation: scalefade 300ms;
                        animation: scalefade 300ms; }
                    .icon-like-1 {
                        display: none; } } }
            .favourite {
                .icon-ribbon-1 {
                    display: none;
                    @include fs(14);
                    margin-right: 3px;
                    margin-left: 3px; }
                &.current {
                    .icon-ribbon-1 {
                        display: inline-block;
                        *display: inline;
                        -webkit-animation: scalefade 300ms;
                        animation: scalefade 300ms; }
                    .icon-ribbon {
                        display: none; } } } } }

    //评论
    // .post-comment
    //     h2
    //         @include fs(18)
    //         line-height: 18px
    //         padding-bottom: 20px
    //         border-bottom: 1px #e5e5e5  solid
    //         margin-bottom: 50px
    //         @extend .tc
    //     .user-name
    //         margin-bottom: 18px
    //         h3.name
    //             line-height: 40px
    //             color: #B3B3B3
    //             @include fs(14)
    //             display: inline
    //         .captcha-part
    //             margin-left: 70px
    //         .login-reminder
    //             line-height: 36px
    //             color: #929292
    //             @include fs(14)
    //     .name-input,.captcha-input
    //         height: 36px
    //         width: 320px
    //         line-height: 34px
    //         border: 1px #D9D9D9 solid
    //         @include rounded(4px)
    //         padding: 0 15px 0 10px
    //         @include fs(13)
    //         color: #666
    //         margin-right: 19px
    //     .captcha-input
    //         width: 150px
    //         margin-right: 10px
    //     #captcha_image
    //         cursor: pointer
    //     .get-captcha
    //         display: inline-block
    //         *display: inline
    //         line-height: 36px
    //         color: #d9d9d9
    //         position: relative
    //         top: -2px
    //         cursor: pointer
    //         i
    //             margin-left: 8px
    //             vertical-align: middle
    //             -moz-transition: all 0.1s ease-in-out
    //             -webkit-transition: all 0.1s ease-in-out
    //             -o-transition: all 0.1s ease-in-out
    //             -ms-transition: all 0.1s ease-in-out
    //             transition: all 0.1s ease-in-out
    //     .avatar
    //         img
    //             @include rounded(50%)
    //             margin: -2px 5px 0 0
    //     .comment-form
    //         margin-bottom: 50px
    //         textarea
    //             padding: 10px 15px 15px 10px
    //             @include fs(13)
    //             width: 700px
    //             height: 70px
    //             border: 1px #d8d8d8 solid
    //             resize: none
    //             max-height: 158px
    //             @include rounded(4px)
    //             margin-bottom: 17px
    //             color: #666
    //         .btn
    //             margin-left: 10px
    //     .more-comment
    //         padding-top: 50px
    //         border-top: 1px #f2f2f2 solid

    //评论
    .post-comment {
        h2 {
            @include fs(18);
            line-height: 18px;
            padding-bottom: 20px;
            border-bottom: 1px #e5e5e5  solid;
            margin-bottom: 50px;
            @extend .tc;
            color: #333333; }
        .btn {
            height: auto;
            padding: 10px 20px;
            line-height: 15px;
            border: 1px solid $tmt_color_red;
            border-radius: 2px;
            &.gray {
                border-color: #D8D8D8;
                color: #D8D8D8; } }
        .user-info {
            font-size: 0;
            // height: 30px
            margin-bottom: 36px;
            .avatar {
                display: inline-block;
                margin-right: 10px;
                vertical-align: top;
                img {
                    @include rounded(50%);
 } }                    // margin: -2px 5px 0 0
            h3.name {
                line-height: 30px;
                color: #000000;
                @include fs(14);
                display: inline; }
            .captcha-part {
                margin-left: 70px; }
            .login-reminder {
                line-height: 36px;
                color: #929292;
                @include fs(14); } }
        .name-input,.captcha-input {
            height: 36px;
            width: 320px;
            line-height: 34px;
            border: 1px #D9D9D9 solid;
            @include rounded(4px);
            padding: 0 15px 0 10px;
            @include fs(13);
            color: #666;
            margin-right: 19px; }
        .captcha-input {
            width: 150px;
            margin-right: 10px; }
        #captcha_image {
            cursor: pointer; }
        .get-captcha {
            display: inline-block;
            *display: inline;
            line-height: 36px;
            color: #d9d9d9;
            position: relative;
            top: -2px;
            cursor: pointer;
            i {
                margin-left: 8px;
                vertical-align: middle;
                -moz-transition: all 0.1s ease-in-out;
                -webkit-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                -ms-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out; } }

        .comment-form {
            margin-bottom: 20px;
            font-size: 0;
            textarea {
                padding: 13px 15px;
                @include fs(13);
                width: 700px;
                height: 120px;
                border: 1px solid #D8D8D8;
                resize: none;
                max-height: 158px;
                @include rounded(5px);
                margin-bottom: 30px;
                line-height: 17px;
                color: #B3B3B3; }
            .btn {
 }                // margin-left: 10px
            .bind-tip {
                line-height: 37px;
                @include fs(13);
                color: $tmt_color_red; } }
        // .more-comment
        //     padding-top: 50px
        //     border-top: 1px #f2f2f2 solid
        .login-tip {
            margin-bottom: 30px;
            line-height: 16px;
            @include fs(14);
            color: #808080;
            a {
                color: $tmt_color_red; } }
        .more-comment {
            margin-top: 20px;
            .btn {
                border: 1px solid #D9D9D9;
                border-radius: 40px;
                line-height: 14px;
                @include fs(14);
                color: #909090;
                cursor: pointer; } } }

    .fn-hide {
        display: none !important; }







    .related_articles {
        margin-bottom: 30px;
        h2 {
            @include fs(18);
            line-height: 18px;
            padding-bottom: 20px;
            border-bottom: 1px #e5e5e5 solid;
            margin-bottom: 30px; }
        ul {
            margin-right: -35px; }
        li {
            vertical-align: top;
            width: 210px;
            display: inline-block;
            *display: inline;
            *float: left;
            margin-right: 35px;
            *margin-right: 20;
            margin-bottom: 30px;
            overflow: hidden;
            img {
                margin-bottom: 20px; }
            .title {
                display: block;
                height: 60px;
                overflow: hidden;
                @include fs(15);
                line-height: 20px;
                *width: 210px;
                color: $black;
                &:hover {
                    color: $orange; } } } }

    #show-detail .author-cont .btn {
        right: 70px;
        top: 35px; }

    .artilce-bar-share {
        a {
            width: 135px;
            @include fs(14);
            display: block;
            margin: 10px 5px;
            color: $i_color;
            &:hover {
                color: $s_color; }

            i {
                width: 30px;
                display: inline-block;
                *display: inline; } } } }
// 投稿页面T弹框
.draft-pop-wrap {
    padding: 20px;
    h2 {
        font-size: 14px;
        margin-bottom: 20px; }
    .detail,.ipt {
        width: 308px;
        padding: 5px;
        height: 60px;
        border: 1px solid #e5e5e5;
        resize: none;
        margin-bottom: 20px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px; }
    .ipt {
        height: 30px;
        margin-bottom: 20px;
        font-size: 14px; }
    .tip {
        text-align: center;
        color: #B3B3B3;
        font-size: 13px;
        margin-bottom: 26px; }
    .upload {
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        .btn {
            width: 320px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px; } }
    .img {
        font-size: 14px;
        color: #808080;
        // height: 36px
        // line-height: 36px
        padding-bottom: 20px;
        text-align: center;
        // by tamamadesu
        .name {
            width: 250px;
            display: inline-block;
            *display: inline;
            word-break: break-all;
            line-height: 20px;
            margin: 0 0 10px 0; } }
    .upload_input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        -ms-filter: 'alpha(opacity=0)';
        font-size: 200px;
        direction: ltr;
        cursor: pointer; }
    .btns {
        text-align: center;
        button {
            margin: 0 15px; } }

    .img-m {
        margin-bottom: 20px;
        @include fs(13);
        color: #b3b3b3; } }

.error_alert_wrap {
    text-align: center; }
.error_alert_wrap .m {
    color: #808080;
    font-size: 16px;
    margin-bottom: 30px; }
.error_alert_wrap .tit {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px; }
// 文章赞赏功能
.overflow_hide {
    overflow: hidden; }
.get_award_box {
    // 赞赏视图
    .award_mode {
        border-bottom: 1px solid #E5E5E5;
        border-top: 1px solid #E5E5E5;
        width: 100%;
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tit {
            color: #333333;
            @include fs(18);
            line-height: 21px;
            margin-bottom: 40px; }
        .is_get_award_btn {
            width: 138px;
            margin: 0 !important;
            cursor: pointer; }
        .has_award_success {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            color: $tmt_color_red;
            margin-top: 40px;
            .s_avater {
                display: flex;
                img {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    position: relative;
                    border: 2px solid #fff;
                    margin: 0 !important;
                    &:nth-child(1) {
                        left: 0px; }
                    &:nth-child(2) {
                        left: -10px; }
                    &:nth-child(3) {
                        left: -20px; }
                    &:nth-child(4) {
                        left: -30px; }
                    &:nth-child(5) {
                        left: -40px; }
                    &:nth-child(6) {
                        left: -50px; } } }
            .s_info_btn {
                cursor: pointer; } } }
    // 打赏列表
    .list_mode {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        background: #fff;
        img {
            margin: 0 !important; }
        .awards_js_close {
            position: absolute;
            top: -35px;
            right: -170px;
            width: 20px;
            height: 20px; }
        .list_box {
            max-width: 580px;
            margin: 100px auto;
            position: relative;
            .title {
                @include fs(16);
                line-height: 22px;
                color: #333333;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                >img {
                    width: 19px;
                    height: 18px;
                    margin-right: 10px !important; } }
            .list-all_more {
                margin-top: 30px auto;
                img {
                    width: 90px; } }
            .list-all {
                margin: 0;
                width: 96%;
                margin-top: 20px;
                overflow-y: scroll;
                height: 80vh;
                padding-right: 4%;
                li {
                    display: flex;
                    padding: 30px 0;
                    border-top: 1px solid #E5E5E5;
                    justify-content: space-between;
                    align-items: flex-start;
                    .info {
                        display: flex;
                        >img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%; }
                        .content {
                            margin-left: 14px;
                            .username {
                                color: #808080 !important;
                                @include fs(16);
                                line-height: 22px;
                                margin-bottom: 12px; }
                            a {
                                text-decoration: unset; }
                            .tit {
                                color: $tmt_color_red !important;
                                @include fs(16);
                                line-height: 22px; } } }
                    .time {
                        color: #808080;
                        @include fs(13);
                        line-height: 14px; }
                    .all-comments {
                        text-align: center;
                        display: inline-block;
                        color: #fff !important;
                        padding: 3% 0;
                        font-size: 14px;
                        font-size: 1.4rem;
                        line-height: 20px;
                        background: $tmt_color_red;
                        box-shadow: 0 1px 2px 0 rgba(0,164,160,0.5);
                        border-radius: 50px;
                        padding: 10px 45px 10px 25px;
                        margin: 20px auto 0;
                        position: relative;
                        text-decoration: unset;
                        .phone {
                            position: absolute;
                            top: 6px;
                            display: inline-block;
                            margin-left: 10px;
                            width: 20px;
                            height: 27px;
                            background-image: url(/public/css/img/phone.svg);
                            background-size: cover; } } } } } }
    // 去打赏支付
    .pay_mode {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        background: #fff;
        .pay_box {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 460px;
            height: 100%;
            margin: 0 auto;
            .tit {
                color: #333333 !important;
                @include fs(24);
                line-height: 30px;
                margin-bottom: 60px; }
            .awards_js_close {
                position: absolute;
                top: 35px;
                right: -170px;
                width: 20px;
                height: 20px; }
            ul.get_amount {
                width: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                li {
                    width: 140px;
                    margin-bottom: 20px;
                    border: 1px solid rgba(191,191,191,1);
                    border-radius: 4px;
                    color: #999999;
                    @include fs(18);
                    line-height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 18px;
                    box-sizing: border-box;
                    cursor: pointer;
                    &.current {
                        border: 1px solid #E93F56;
                        position: relative;
                        color: #E93F56;
                        &:after {
                            content: '';
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            background: url(/public/img/posts/pc_awards_selected.png);
                            background-size: 100% 100%; } }
                    input {
                        width: 100%;
                        border: 0;
                        height: 22px;
                        padding: 0;
                        margin: 0;
                        text-align: center; } } }
            .get_way {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 40px;
                width: 100%;
                .tit {
                    color: #666666 !important;
                    @include fs(18);
                    line-height: 28px;
                    margin-bottom: 20px; }
                .way_list {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .wx_pay,.alipay {
                        width: 220px;
                        height: 56px;
                        cursor: pointer; }
                    .wx_pay {
                        background: url(/public/img/posts/pc_wxpay_no.png);
                        background-size: 100% 100%;
                        &.current {
                            background: url(/public/img/posts/pc_wxpay_yes.png);
                            background-size: 100% 100%; } }
                    .alipay {
                        background: url(/public/img/posts/pc_alpay_no.png);
                        background-size: 100% 100%;
                        &.current {
                            background: url(/public/img/posts/pc_alpay_yes.png);
                            background-size: 100% 100%; } } } }
            .to_paid_btn {
                // width: 136px
                height: 40px;
                min-height: 40px;
                background: #E93F56;
                border-radius: 80px;
                color: #fff;
                @include fs(14);
                // padding: 12px 35px
                margin: 60px auto 0;
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 130px; }
            .is_anonymou_tips {
                color: #666666;
                font-size: 13px;
                margin-top: 30px; }
            .is_anonymou {
                color: #808080;
                font-size: 14px;
                margin-top: 40px;
                display: flex;
                justify-content: flex-end;
                width: 100%;
                align-items: center;
                .is_anonymou_img {
                    width: 15px;
                    min-width: 15px;
                    margin: 0 12px 0 0 !important; } } }
        // 微信支付二维码
        .pay_qrcode {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 360px;
            height: 100%;
            margin: 0 auto;
            .awards_js_close {
                position: absolute;
                top: 35px;
                right: -170px;
                width: 20px;
                height: 20px; }
            p {
                margin: 0;
                @include fs(24);
                line-height: 30px; }
            .info {
                width: 100%;
                height: 220px;
                background: url(/public/img/posts/pc_wx_pay.png);
                background-size: 100% 100%;
                margin: 60px auto 40px;
                position: relative; }
            .amount {
                color: #666;
                @include fs(16);
                line-height: 28px; }
            #weixin_qrcode {
                position: absolute;
                right: 20px;
                top: 20px; } } }
    .sussess_mode {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        background: #fff;
        .sussess_box {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 240px;
            height: 100%;
            margin: 0 auto;
            .awards_js_close_s {
                position: absolute;
                top: 35px;
                right: -170px;
                width: 20px;
                height: 20px; }
            img {
                width: 100%; }
            .sussess_img {
                margin-bottom: 40px; }
            p {
                @include fs(16);
                line-height: 30px;
                text-align: center;
                margin: 0; }
            .sussess_js_close {
                width: 136px;
                margin: 60px auto; } } }
    .login_mode {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        background: #fff;
        .login_tips_box {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 450px;
            height: 100%;
            margin: 0 auto;
            .awards_js_close {
                position: absolute;
                top: 75px;
                right: -170px;
                width: 20px;
                height: 20px; }
            >p {
                text-align: center; }
            .tips_botton {
                display: flex;
                justify-content: space-around;
                align-items: center; }
            .to_paid {
                width: 140px;
                height: 46px;
                min-height: 46px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                color: #000;
                font-size: 16px;
                border: 1px solid #ccc; }
            .to_login {
                width: 140px;
                height: 46px;
                min-height: 46px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $tmt_color_red;
                color: #fff;
                font-size: 16px;
                margin-left: 40px; } } }
    .hide {
        display: none !important; } }


