.comments_mod_v0 {
    background: red;
    .post-comment {
        h2 {
            @include fs(18);
            line-height: 18px;
            padding-bottom: 20px;
            border-bottom: 1px #e5e5e5  solid;
            margin-bottom: 50px;
            @extend .tc; }
        .user-name {
            margin-bottom: 18px;
            h3.name {
                line-height: 40px;
                color: #B3B3B3;
                @include fs(14);
                display: inline; }
            .captcha-part {
                margin-left: 70px; }
            .login-reminder {
                line-height: 36px;
                color: #929292;
                @include fs(14); } }
        .name-input,.captcha-input {
            height: 36px;
            width: 320px;
            line-height: 34px;
            border: 1px #D9D9D9 solid;
            @include rounded(4px);
            padding: 0 15px 0 10px;
            @include fs(13);
            color: #666;
            margin-right: 19px; }
        .captcha-input {
            width: 150px;
            margin-right: 10px; }
        #captcha_image {
            cursor: pointer; }
        .get-captcha {
            display: inline-block;
            *display: inline;
            line-height: 36px;
            color: #d9d9d9;
            position: relative;
            top: -2px;
            cursor: pointer;
            i {
                margin-left: 8px;
                vertical-align: middle;
                -moz-transition: all 0.1s ease-in-out;
                -webkit-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                -ms-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out; } }
        .avatar {
            img {
                @include rounded(50%);
                margin: -2px 5px 0 0; } }
        .comment-form {
            margin-bottom: 50px;
            textarea {
                padding: 10px 15px 15px 10px;
                @include fs(15);
                width: 700px;
                height: 70px;
                border: 1px #d8d8d8 solid;
                resize: none;
                max-height: 158px;
                @include rounded(4px);
                margin-bottom: 17px;
                color: #333; }
            .btn {
                margin-left: 10px; }
            .bind-tip {
                line-height: 37px;
                @include fs(13);
                color: #94A0B0; } }
        .more-comment {
            padding-top: 50px;
            border-top: 1px #f2f2f2 solid; } }
    .comment-list {
        li {
            padding-bottom: 30px;
            margin-bottom: 24px;
            border-bottom: 1px #f2f2f2 solid;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0; }
            .success {
                margin-top: 30px; }
            .comment-form {
                margin: 30px 0 0 52px !important;
                textarea {
                    width: 648px !important; }
                .avatar {
                    position: static; }
                .bind-tip {
                    line-height: 37px;
                    @include fs(13);
                    color: #94A0B0; } } }
        .avatar {
            position: relative;
            top: 6px;
            img {
                margin-right: 10px !important;
                @include rounded(50%); } }
        span {
            color: #B3B3B3;
            line-height: 22px;
            @include fs(14);
            margin-right: 10px; }

        .name {
            color: $tmt_color_red;
            margin-right: 10px;
            @include fs(14);
            &:hover {
                color: $tmt_color_red_hover; } }
        .js-set-top,.js-delete {
            &:hover {
                color: $tmt_color_red; } }

        .part {
            display: inline-block;
            *display: inline;
            margin-right: 10px;
            &:hover {
                i,span {
                    color: $tmt_color_red; } }
            i {
                margin-right: 5px;
                color: #b3b3b3;
                @include fs(14);
                vertical-align: middle;
                position: relative;
                top: -2px; }
            &.current {
                span,i {
                    color: $tmt_color_red; }
                .icon-Shape9,.icon-Shape9-1 {
                    display: inline-block;
                    *display: inline;
                    -webkit-animation: scalefade 300ms;
                    animation: scalefade 300ms; }
                .icon-like-1,.icon-tread {
                    display: none; } }
            .icon-tread {
                top: -1px; }
            .icon-Shape9,.icon-Shape9-1 {
                display: none; } }
        .toped {
            // border: 1px $tmt_color_red solid
            color: $main;
            @include fs(16);
            i {
                position: relative;
                top: 2px;
                left: -6px; } }
        .comment-cont {
            margin-top: -4px;
            margin-bottom: 17px;
            @include fs(16);
            line-height: 24px;
            img {
                width: 20px;
                height: 20px;
                margin: 0 5px;
                position: relative;
                top: -3px; } }
        .comment-cont,.tools {
            margin-left: 52px;
            span {
                @include fs(13); } }
        .comment-deleted {
            margin-bottom: 0; }
        .comment-info {
            line-height: 22px; }
        [class*="js-"] {
            cursor: pointer; } } }

.comments_mod_v1 {
    .post-comment {
        h2 {
            @include fs(18);
            line-height: 18px;
            padding-bottom: 20px;
            border-bottom: 1px #e5e5e5  solid;
            margin-bottom: 50px;
            @extend .tc;
            color: #333333; }
        .btn {
            height: auto;
            padding: 10px 20px;
            line-height: 15px;
            border: 1px solid #C5002B;
            border-radius: 2px;
            &.gray {
                border-color: #D8D8D8;
                color: #D8D8D8; } }
        .user-info {
            font-size: 0;
            // height: 30px
            margin-bottom: 36px;
            .avatar {
                display: inline-block;
                margin-right: 10px;
                vertical-align: top;
                img {
                    @include rounded(50%);
 } }                    // margin: -2px 5px 0 0
            h3.name {
                line-height: 30px;
                color: #000000;
                @include fs(14);
                display: inline; }
            .captcha-part {
                margin-left: 70px; }
            .login-reminder {
                line-height: 36px;
                color: #929292;
                @include fs(14); } }
        .name-input,.captcha-input {
            height: 36px;
            width: 320px;
            line-height: 34px;
            border: 1px #D9D9D9 solid;
            @include rounded(4px);
            padding: 0 15px 0 10px;
            @include fs(13);
            color: #666;
            margin-right: 19px; }
        .captcha-input {
            width: 150px;
            margin-right: 10px; }
        #captcha_image {
            cursor: pointer; }
        .get-captcha {
            display: inline-block;
            *display: inline;
            line-height: 36px;
            color: #d9d9d9;
            position: relative;
            top: -2px;
            cursor: pointer;
            i {
                margin-left: 8px;
                vertical-align: middle;
                -moz-transition: all 0.1s ease-in-out;
                -webkit-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                -ms-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out; } }

        .comment-form {
            margin-bottom: 20px;
            font-size: 0;
            textarea {
                padding: 13px 15px;
                @include fs(15);
                width: 700px;
                height: 120px;
                border: 1px solid #D8D8D8;
                resize: none;
                max-height: 158px;
                @include rounded(5px);
                margin-bottom: 30px;
                line-height: 17px;
                color: #333; }
            .btn {
 }                // margin-left: 10px
            .bind-tip {
                line-height: 37px;
                @include fs(13);
                color: #C5002B; } }
        // .more-comment
        //     padding-top: 50px
        //     border-top: 1px #f2f2f2 solid
        .login-tip {
            margin-bottom: 30px;
            line-height: 16px;
            @include fs(14);
            color: #808080;
            a {
                color: #C5002B; } }
        .more-comment {
            margin-top: 20px;
            .btn {
                border: 1px solid #D9D9D9;
                border-radius: 40px;
                line-height: 14px;
                @include fs(14);
                color: #909090;
                cursor: pointer; } } }
    .comment-list {
        .pl5 {
            padding-left: 5px; }
        .pr5 {
            padding-right: 5px; }
        .comment-item {
            padding: 30px 0;
            border-top: 1px solid #F2F2F2;
            // border-bottom: 1px solid #F2F2F2
            &:first-child {
                border-top: none; }
            // &:last-child
            //     border-bottom: none
            .comment-info {
                span {
                    vertical-align: top;
                    i {
                        vertical-align: top; } }
                .avatar {
                    display: inline-block;
                    margin-right: 15px;
                    img {
                        @include rounded(50%); } }
                .user {
                    line-height: 18px;
                    @include fs(16);
                    color: #323232;
                    vertical-align: top;
                    .name {
                        // padding: 0 5px
                        color: #C5002B;
                        vertical-align: top; } }
                .toped {
                    display: inline-block;
                    line-height: 18px;
                    @include fs(16);
                    color: #C5002B;
                    i {
                        vertical-align: middle; } }
                .reply {
                    margin-right: 20px; } }
            .operate {
                @include fs(13);
                color: #808080;
                line-height: 14px;
                .vote {
                    display: inline-block;
                    cursor: pointer;
                    &.current {
                        .icon-Shape9 {
                            display: inline-block; }
                        .icon-like-1 {
                            display: none; }
                        .num {
                            color: #C5002B; } }
                    .icon-Shape9 {
                        display: none;
                        color: #C5002B; }
                    .icon-like-1 {
                        display: inline-block; } }
                span {
                    cursor: pointer; }
                i {
                    padding-right: 5px; } }
            .comment-cont {
                margin-left: 60px;
                margin-bottom: 20px;
                line-height: 24px;
                @include fs(16);
                color: #333333;
                img {
                    width: 20px;
                    margin-left: 1px;
                    vertical-align: text-top; } }
            .comment-tools {
                margin-left: 60px;
                line-height: 14px;
                @include fs(14);
                color: #666666;
                overflow: hidden;
                a {
                    line-height: 14px;
                    @include fs(14);
                    color: #666666; }
                .time {
                    margin-right: 5px; } }
            .reply-part {
                background-color: #FEFEFE;
                box-shadow: 0 0 2px 0 rgba(0,0,0,0.20);
                margin-top: 20px;
                margin-left: 60px;
                padding: 30px 30px 10px 30px;
                .reply-list {
                    li {
                        font-size: 0;
                        padding: 20px 0;
                        border-top: 1px solid #E9ECF3;
                        // border-bottom: 1px solid #E9ECF3
                        overflow: hidden;
                        &:first-child {
                            border-top: none;
 }                            // padding-bottom: 0
                        // &:last-child
                        //     padding-bottom: 0
                        //     border-bottom: none
                        &.border-dashed {
                            border-bottom: 1px dashed #E9ECF3; }
                        &.more-reply {
                            border-top: 1px dashed #E9ECF3;
                            border-bottom: 1px dashed #E9ECF3;
                            .btn-more {
                                @include fs(14);
                                color: #C5002B;
                                line-height: 14px; } }
                        &.foot {
                            border-top: none; }
                        .reply-cont {
                            margin-bottom: 20px;
                            line-height: 24px;
                            @include fs(16);
                            color: #151515;
                            max-width: 100%;
                            overflow: hidden;
                            .name {
                                color: #C5002B; }
                            .del_txt {
                                color: #B3B3B3; }
                            img {
                                width: 20px;
                                margin-left: 1px;
                                vertical-align: text-top; } }
                        .reply-tools {
                            line-height: 14px;
                            overflow: hidden;
                            .time {
                                margin-right: 5px; }
                            .time, .model {
                                @include fs(14);
                                color: #666666; }
                            .stick {
                                margin-right: 30px;
                                @include fs(14);
                                color: #808080;
                                a {
                                    @include fs(14);
                                    color: #808080; } }
                            .reply {
                                margin-right: 20px;
                                @include fs(13);
                                color: #808080; } }
                        .reply-edit {
                            border-top: 1px solid #E9ECF3 !important; } } }
                .comments-edit {
                    padding-top: 20px; }
                .edit-user-info {
                    font-size: 0;
                    margin-bottom: 20px;
                    .name {
                        line-height: 40px;
                        @include fs(16);
                        color: #C5002B; }
                    .avatar {
                        display: inline-block;
                        margin-right: 10px;
                        vertical-align: top;
                        img {
                            @include rounded(50%); } } }
                h3.name {
                    line-height: 30px;
                    color: #000000;
                    @include fs(14);
                    display: inline; }
                textarea {
                    padding: 13px 15px;
                    @include fs(15);
                    // width: 585px
                    width: 100%;
                    height: 80px;
                    border: 1px solid #D8D8D8;
                    resize: none;
                    max-height: 158px;
                    @include rounded(5px);
                    margin-bottom: 20px;
                    line-height: 17px;
                    color: #333; }
                .bind-tip {
                    line-height: 37px;
                    @include fs(13);
                    color: #C5002B; }
                .btn-box {
                    .btn-cancel {
                        margin-right: 10px; } }
                .comments-edit {
                    border-top: 1px solid #E9ECF3; } } } } }
