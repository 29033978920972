@function px($px,$px2) {
    @return ($px / $px2 )*100%; }
.pc-tag {
    .tag_article_list {
        .article_box {
            >li {
                &.hide {
                    display: none !important; } } }
        .fn-hide,.hide {
            display: none; } } }
.pc-tag-special {
    .mod-article-list {
        li {
            .info {
                display: unset; } } } }
.qcode_pop {
    display: none; }
.wrapper.no_second_nav {
    min-height: initial !important; }
.list-page {
    @extend .c-page;
    padding-top: 50px;
    width: 820px;
    .list_top {
        &.award_top {
            padding-top: 71px; }
        &.special {
            .title {
                margin-bottom: 10px; }
            .options {
                margin-top: 30px; } }
        .pic {
            margin-bottom: 30px; }
        .title {
            @include fs(30);
            line-height: 52px;
            margin-bottom: 20px;
            padding-bottom: 0;
            border: none; }
        .abstract {
            margin-bottom: 24px;
            line-height: 30px;
            @include fs(16);
            color: #666666;
            a {
                color: #FB625A; } }
        #number_of_followers {
            margin-bottom: 30px;
            text-align: center;
            @include fs(16); }
        .share {
            // text-align: right
            margin-bottom: 50px;
            .text {
                @include fs(14);
                line-height: 23px;
                color: #b3b3b3; }
            i {
                margin-left: 12px;
                color: #D46055; }
            .icon-wechat {
                color: #44B54B; } }
        .auction {
            @extend .tc;
            margin-bottom: 63px;
            .btn {
                margin-bottom: 30px; }
            p {
                @include fs(14);
                line-height: 14px;
                color: #808080;
                margin-bottom: 14px; } } }
    h1,.t,.h-t {
        text-align: center;
        color: #333;
        @include fs(24);
        padding-bottom: 58px;
        border-bottom: 1px solid #F2F2F2; }
    .h-t {
        border-bottom: none; }
    &.my_bookmark {
        .mod-article-list {
            margin-bottom: 50px; } }
    &.my_product {
        h1 {
            border-bottom: 1px solid #F2F2F2 !important;
 } }            // @include fs(16)
    &.my_post {
        margin-top: 100px;
        h1 {
            padding-bottom: 30px;
            border-bottom: none; }
        nav {
            // width: 391px
            line-height: 38px;
            margin: 0 auto;
            margin-bottom: 18px;
            text-align: center;
            background-color: #F6F6F6;
            color: #808080;
            // padding-bottom: 20px
            // border-bottom: 1px solid #F2F2F2
            @include fs(14);
            @extend .tc;
            &.bookmark-nav {
                width: 550px;
                a {
                    margin: 0 16px; } }
            .num {
                color: #B3B3B3;
                margin-left: 8px; }
            .line {
                height: 14px;
                display: inline-block;
                *display: inline;
                vertical-align: middle;
                border-right: 1px solid #d7d7d7; }
            a {
                color: #B3B3B3;
                margin: 0 21px;
                display: inline-block;
                *display: inline;
                width: 90px;
                &:visited {
                    color: #b3b3b3; }
                &:hover {
                    color: #666;
                    .num {
                        color: #666; } } }
            a.current {
                color: $main_blue;
                .num {
                    color: $main_blue; } } }
        .load-more {
            margin-top: 0;
            padding-top: 50px;
            border-top: 1px solid #f2f2f2;
            margin-bottom: 30px; }

        .mod-article-list {
            li {
                padding: 20px 0;
                &:last-child {
                    border-bottom: 0; }
                .tools {
                    margin-left: 0;
                    a:hover {
                        color: #666; } }
                .title {
                    @include fs(18); }
                .cont {
                    width: 700px; }
                .info {
                    line-height: 15px;
                    margin-bottom: 0;
                    a {
                        color: #b3b3b3; } }
                .reason {
                    @include fs(18);
                    line-height: 21px;
                    color: #333;
                    margin-bottom: 20px; } }
            li.li-pading {
                padding: 30px 0; }
            .quote {
                margin: 20px 0 0 20px;
                padding-left: 10px;
                border-left: 4px #EAEAEA solid;
                .tools {
                    margin-left: 0;
                    // a:hover
 }                    //  // color: #b3b3b3
                .title {
                    color: #808080;
                    @include fs(16);
                    line-height: 30px;
                    margin-bottom: 13px; }
                .info {
                    padding: 5px 0;
                    margin-top: 0; } }

            .reject-msg {
                @include fs(18);
                line-height: 35px;
                color: #333333; } } }
    .presenter {
        padding: 50px 0 60px;
        .pic {
            display: inline-block;
            margin-bottom: 20px; }
        img {
            border-radius: 50%; }
        .txt {
            font-size: 18px;
            color: #333333; } }
    .subscribe_box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .subscribe_num {
            font-size: 14px;
            color: #C5002B;
            margin-right: 30px;
            margin-bottom: 0 !important; }
        .img_box {
            font-size: 0;
            width: 200px;
            img {
                border-radius: 50%;
                margin-left: -10px;
                border: 2px solid #fff; } } }
    .options {
        margin-top: 40px !important; }
    h2.special_column_title {
        font-size: 24px;
        color: #333333;
        margin: 60px auto 0px;
        padding-bottom: 40px;
        border-bottom: 1px solid #F2F2F2; } }
.mod-article-list {
    ul {
        overflow: hidden; }
    li {
        padding: 30px 0;
        border-bottom: 1px solid #F2F2F2;
        // @extend .clear
        position: relative;
        display: flex;
        justify-content: space-between;
        &::-webkit-scrollbar {
            display: none; }
        &:last-child {
            border-bottom: 0; }
        .title {
            position: relative; }
        .topping_img {
            width: 51px;
            height: 24px;
            margin-right: 5px;
            position: absolute;
            top: 35px;
            left: -60px; }
        .title {
            display: block;
            color: #333;
            @include fs(24);
            margin-bottom: 12px;
            line-height: 1.4em; }
        .intro {
            @include fs(16);
            line-height: 1.6em;
            margin-bottom: 15px; }
        .cont {
            // @extend .fl
            width: 470px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column; }
        .pic {
            // @extend .fr
            position: absolute;
            top: 50%;
            right: 0;
            @include transformY(-50%);
            // -webkit-transform: translateY(-50%)
            // transform: translateY(-50%)
            margin-left: 30px; }
        .info {
            margin: 10px 0;
            color: #B3B3B3;
            @include fs(13);
            // @extend .clear
            display: flex;
            justify-content: space-between;
            a {
                color: $orange;
                &:hover {
                    color: $orange-hover; } }
            .line {
                margin: 0 5px; }
            .info_time {
                @include fs(12); } }
        .tag {
            color: $s_color;
            @include fs(13);
            overflow: inherit;
            i {
                @include fs(18);
                display: inline-block;
                *display: inline;
                vertical-align: middle;
                margin-top: -4px;
                margin-right: 5px; }
            a {
                color: $s_color !important;
                margin: 0 8px 10px 0;
                &:hover {
                    color: $orange_hover !important; } } }
        .author {
            @extend .fl;
            img {
                @include rounded(50%);
                margin-right: 10px; } }
        .check_all {
            @extend .fr; }
        .relate {
            @extend .fr;
            .k {
                margin-left: 20px;
                i {
                    margin-right: 5px; } } }
        .tools {
            margin-left: 15px;
            a {
                color: #B3B3B3;
                &:visited {
                    color: #b3b3b3; }
                &:hover {
                    color: #FD6639; } } } }
    li.tag_atlas {
        .cont {
            width: 100% !important;
            .info {
                width: 100%;
                overflow: auto;
                .check_all {
                    font-size: 13px;
                    color: #B3B3B3;
                    text-align: left;
                    line-height: 18px; } }
            .atlaspic {
                position: relative;
                margin-bottom: 15px;
                .atlas_icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 50px;
                    height: 24px;
                    img {
                        width: 100%; } }
                .atlas_show {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    a {
                        width: px(228,700);
                        display: inline-block;
                        img {
                            width: 100%; } } } }
            .js_like {
                margin-left: 30px; } } }
    li.tag_video {
        .pic {
            .video_pic_img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 42px;
                height: 42px;
                transform: translate(-50%,-50%); } } }
    li.tag_nictation,.word_list_li {
        .pic {
            .nictation_pic_img {
                position: absolute;
                left: 0%; } } }
    li.word_list_li {
        h2 {
            margin-top: 0 !important; } }
    .reminder {
        @include fs(16);
        @extend .tc;
        color: #B3b3b3;
        line-height: 21px;
        margin-top: 60px; } }

.load-more {
    text-align: center;
    margin-top: 50px; }

// 热门列表
.hot-list {
    h1 {
        border: none;
        padding-bottom: 15px; }
    .tit-info {
        text-align: center;
        color: #B3B3B3;
        padding-bottom: 50px;
        border-bottom: 1px solid #e5e5e5;
        @include fs(13);
        margin-bottom: 5px; }
    .list-tag {
        a {
            color: #B3B3B3 !important; } }
    .mod-article-list li {
        min-height: 150px;
        .options {
            @extend .fr;
            a {
                display: inline-block;
                *display: inline;
                color: #808080;
                &:hover,&.current {
                    color: $s_color;
                    text-decoration: none;
                    .icon {
                        color: $s_color; } } }
            .icon {
                vertical-align: middle;
                text-align: center;
                display: inline-block;
                *display: inline;
                margin-right: 6px;
                @include fs(16); }
            .icon-comment {
                position: relative;
                top: 2px; }
            .num {
                @include fs(13);
                position: relative;
                top: 2px; } } } }
// 最新文章
.lastest-list {
    h1 {
        border: none;
        padding-bottom: 50px; }
    .lastest-part {
        margin-bottom: 20px;
        .date-bar {
            color: $s_color;
            @include fs(16);
            font-weight: bold;
            border-left: 4px solid $s_color;
            padding-left: 10px;
            margin-bottom: 20px; }
        .latest-list {
            padding-top: 5px;
            border-top: 1px solid #e5e5e5;
            margin: 0 0 30px 0;
            li {
                border-bottom: 1px solid #f2f2f2;
                padding: 20px 0;
                &:last-child {
                    border-bottom: none; } }
            .title {
                @include fs(18);
                color: $t_color;
                margin-bottom: 8px; }
            .time {
                color: $i_color;
                @include fs(13);
                i {
                    padding-right: 5px; } } } }
    .pagination {
        text-align: center; } }

// 推荐列表
.recommend-list {
    h1 {
        border: none;
        padding-bottom: 30px; }
    .how {
        color: $orange;
        @include fs(14);
        line-height: 14px;
        margin-bottom: 30px;
        cursor: pointer;
        &:hover {
            color: $orange-hover; } }
    .kind {
        width: 391px;
        line-height: 38px;
        margin: 0 auto;
        margin-bottom: 18px;
        background-color: #FFF;
        text-align: center;
        background-color: #F6F6F6;
        // padding-bottom: 20px
        // border-bottom: 1px solid #E5E5E5
        @include fs(13);
        .line {
            height: 14px;
            display: inline-block;
            *display: inline;
            vertical-align: middle;
            border-right: 1px solid #d7d7d7; }
        a {
            color: $c_color;
            margin: 0 36px; }
        .num {
            color: $i_color;
            margin-left: 5px; }
        .current {
            color: $s_color;
            .num {
                color: $s_color; } } }
    .r-author-list {
        li {
            border-bottom: 1px solid #F2F2F2; }
        .author-info {
            padding: 32px 0; } }
    .pagination {
        margin-bottom: 30px;
        border-top: none; } }


// 英文栏目
.english {
    .mod-user-info {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #F2F2F2; } }

// 关于我们
.about-page,.helps {
    color: #000;
    .t {
        border: none;
        padding-bottom: 50px; }
    .a-nav {
        text-align: center;
        height: 38px;
        line-height: 38px;
        margin-bottom: 50px;
        background-color: #F6F6F6;
        .line {
            line-height: 20px;
            height: 14px;
            display: inline-block;
            *display: inline;
            vertical-align: middle;
            border-right: 1px solid #D7D7D7;
            margin: -2px 30px 0; }
        a {
            @include fs(14);
            color: $c_color;
            &.current,&:hover {
                color: $s_color; } } }
    .cont {
        color: #393839;
        @include fs(16);
        a {
            color: $s_color; }
        p,.part {
            line-height: 26px;
            margin-bottom: 30px; }
        .part {
            ul {
                list-style-type: disc; }
            .tit {
                margin-bottom: 40px;
                @include fs(16); }
            .b-tit {
                padding-left: 10px;
                border-left: 4px solid $s_color;
                margin-bottom: 20px;
                @include fs(16); }
            p {
                margin-bottom: 0; }
            ol {
                list-style-type: decimal;
                line-height: 26px;
                padding-left: 40px; } }
        .list {
            margin-bottom: 40px;
            p {
                margin-bottom: 30px;
                &.low-bottom {
                    margin-bottom: 15px; } }
            ol {
                list-style-type: decimal;
                line-height: 26px;
                padding-left: 40px; }
            .tit, {
                padding-left: 10px;
                border-left: 4px solid $s_color;
                margin-bottom: 20px;
                @include fs(16); } }
        .inner {
            padding-top: 30px;
            border-top: 1px solid #E5E5E5;
            margin-bottom: 30px; }
        .article-list {
            li {
                margin-bottom: 50px; }
            .tit {
                border-left: 4px solid $s_color;
                padding-left: 10px;
                color: $c_color;
                @include fs(14);
                margin-bottom: 15px; }
            a {
                @include fs(18);
                color: $t_color; } }
        .bt {
            padding-top: 30px;
            border-top: 1px solid #E5E5E5; }
        .writing {
            h2 {
                padding-left: 10px;
                border-left: 4px solid #C5002B;
                margin-bottom: 50px;
                font-size: 16px;
                font-size: 1.6rem;
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    height: 1px;
                    width: 100%;
                    background-color: #e5e5e5;
                    position: absolute;
                    bottom: -20px;
                    left: -5px; } }
            p {}
            p.caption {
                font-size: 14px;
                font-size: 1.4rem;
                color: #808080;
                text-align: center;
                &:after {
                    content: '\00a0';
                    display: block;
                    width: 30%;
                    border-bottom: 1px solid #ddd;
                    margin: -2% auto 0 auto;
                    position: relative; } }
            img {
                width: 700px;
                min-width: 700px;
                margin: 0 auto;
                display: block; } } }
    .f-link {
        a {
            display: inline-block;
            *display: inline;
            margin-bottom: 20px;
            margin-right: 40px;
            @include fs(16);
            color: $c_color;
            &:hover {
                color: $s_color; } } } }
.tooltip-weixin-inner {
    padding: 20px;
    h2 {
        @include fs(18);
        color: $t_color;
        text-align: center;
        margin-bottom: 20px; }
    p {
        @include fs(13);
        color: $c_color;
        margin-bottom: 28px; } }

// 修改列表
.hot-list,.pc-tag,.pc-user,.award {
    .mod-article-list li .cont {
        width: 470px; } }


//产品编辑页
.report h1 {
    margin-bottom: 50px;
    font-size: 24px;
    font-size: 2.4rem;
    border-bottom: 1px solid #F2F2F2 !important;
    text-align: center;
    padding-bottom: 30px; }




.recommend-list {
    .r-author-list {
        .author-info {
            .author-name {
                display: inline-block;
                line-height: 20px; }
            .pro-sign {
                position: relative;
                margin-left: 15px;
                top: -2px; } } } }
.user-article-list {
    .tit {
        .nav_ul,.list_tag_nav_pc {
            display: flex;
            justify-content: space-around; }
        .nav_li,.list_tag_nav_li {
            font-size: 22px;
            color: #000000;
            cursor: pointer;
            &.current {
                color: #C5002B; } } }
    .article_box {
        .article_li {
            .word_list {}
            .word_list_li {
                border-bottom: 2px solid #F2F2F2;
                h2 {
                    margin-top: 30px;
                    margin-bottom: 12px;
                    a {
                        @include fs(24);
                        color: #000000; } }
                .time {
                    margin-bottom: 15px;
                    @include fs(13);
                    time {
                        color: #9B9B9B; }
                    a {
                        color: #02ACB6;
                        margin-left: 25px;
                        display: inline-block; } }
                .word_cont {
                    margin-bottom: 24px;
                    font-size: 16px;
                    color: #9B9B9B;
                    .open_btn {
                        color: #02ACB6;
                        display: inline-block;
                        margin-left: 15px;
                        cursor: pointer; } }
                .word_list_img {
                    display: inline-block;
                    img {
                        width: 300px;
                        height: 208px;
                        margin-bottom: 30px; } } } } } }

.tag_group_big,.tag_group_scroll {
    flex-direction: column;
    .group_big {
        padding-top: 0;
        position: relative;
        >a {
            width: 100%;
            position: relative; }
        img {
            width: 100%; }
        .group_info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 700px;
            min-width: 700px;
            max-width: 700px;
            padding: 30px 20px;
            z-index: 3;
            .text {
                @include fs(24);
                line-height: 30px;
                color: #FFFFFF; }
            .info {
                @include fs(14);
                color: #F2F2F2;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start; }
            span {
                display: flex;
                align-items: center; }
            .js_like {} }
        .mask_bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 2; } }
    .group_tit {
        font-size: 1.6rem;
        font-size: 16px;
        line-height: 22px;
        padding-left: 10px;
        border-left: 4px solid $main_blue;
        margin-bottom: 17px;
        display: flex;
        justify-content: space-between;
        h4 {
            display: inline-block; }
        .all {
            font-size: 13px;
            color: #B3B3B3;
            line-height: 18px;
            .icon-arrow-r {
                margin-left: 3px; } } }
    .group {
        .group_cell {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            // margin-right: 20px
            padding: 0;
            border: 0;
            &:last-child {
                margin-right: 0; }
            .see_blue {
                position: relative;
                display: inline-block;
                margin-right: 6px;
                width: 14px;
                height: 12px;
                background-image: url(/public/css/img/see.svg);
                background-size: cover; }
            .post_tag {
                color: #333;
                @include fs(16); }
            .bottom_banner {
                position: inherit;
                // bottom: 0
                width: 100%;
                padding: 10px 15px 0 3px;
                margin-right: 30px;
                min-height: 58px;
                a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; } }
            .title {
                color: #333333;
                @include fs(14);
                line-height: 22px;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical; }
            .shadow {
                position: absolute;
                width: 100%;
                height: 100%;
                // height: 150px
                top: 0;
                background-color: rgba(0,0,0,.1); }
            .info {
                color: #808080;
                margin-right: 30px;
                .js_like {
                    margin-left: 30px; } } }
        .slick-arrow {
            z-index: 2;
            width: 32px;
            height: 46px; }
        .slick-next {
            right: -32px; }
        .slick-prev {
            left: -35px; }
        .slick-prev,.slick-next {
            &:before {
                color: #C5002B;
                // opacity: .4
                font-size: 16px;
                font-family: 'icomoon'; } }
        .slick-prev {
            &:before {
                content: "\e604"; } }
        .slick-next {
            &:before {
                content: "\e605";
                padding-top: 20px; } }
        .slick-prev, .slick-next {
            top: 30%; } } }
// 置顶修改
.user-article-list {
    width: 820px;
    .tit,.nav {
        margin-left: 60px;
        margin-right: 60px; }
    .mod-article-list {
 } }        // padding: 0 60px
.article_li_w {
    .mod-article-list {
 } }        // padding: 0 60px
.article_li_w {
    .list_top {
        padding: 0 60px; } }
.list_top_w {
    padding: 0 60px; }

// edge榜单
.edge_list_box {

    .title_h1 {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        margin: 0px auto 50px;
        padding: 50px 0;
        text-align: center;
        background: url('/public/img/about/edge/tit_bg.png');
        background-size: 100% 100%;
        min-width: 1020px;
        margin-top: 55px;
        // text-align: center
        // font-size: 24px
        // line-height: 34px
 }        // color: #333333
    .news-part {
        margin-top: 50px;
        line-height: 34px;
        font-size: 24px;
        color: #232323;
        min-width: 1020px;
        width: 1020px;
        margin: 50px auto;
        h2 {
            text-align: center;
            font-size: 24px;
            line-height: 34px;
            color: #333333; }
        .news-list {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                box-sizing: border-box;
                cursor: pointer;
                width: 500px;
                height: 130px;
                margin-right: 20px;
                margin-bottom: 20px;
                padding: 20px;
                background: #FFFFFF;
                box-shadow: 0 0 2px 0 rgba(20, 28, 44, 0.20);
                &:nth-child(2n) {
                    margin-right: 0; }
                .imgs-box {
                    width: 120px;
                    float: left;
                    overflow: hidden; }
                img {
                    transform: scale(1);
                    transition: all .5s linear; }
                .detail {
                    margin-left: 140px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between; }
                .title {
                    line-height: 24px;
                    vertical-align: top;
                    font-size: 16px;
                    color: #3A405B;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden; }
                .info {
                    line-height: 22px;
                    font-size: 14px;
                    color: #C5002B; }
                .date {
                      color: #343A00; }
                &:hover {
                  img {
                    transform: scale(1.1); } } }
            .word_logo {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 66px;
                    height: 36px;
                    background: url('/public/img/about/edge/logo_shunyan.png');
                    background-size: 100% 100%; } }
            .atlas_logo {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 66px;
                    height: 36px;
                    background: url('/public/img/about/edge/logo_altas.png');
                    background-size: 100% 100%; } }
            .video_logo {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 66px;
                    height: 36px;
                    background: url('/public/img/about/edge/logo_video.png');
                    background-size: 100% 100%; } } }
        .news-load {
            width: 120px;
            height: 36px;
            margin: 0 auto;
            margin-top: 40px;
            background-color: #fff;
            box-shadow: 0px 0px 4px 0px rgba(195,195,195,1);
            text-align: center;
            line-height: 36px;
            font-size: 13px;
            color: #333333;
            cursor: pointer;
            transform: scale(1);
            transition: all .5s linear; } }
    .subscribe-part {
        position: relative;
        margin-top: 80px;
        padding: 30px 0;
        background-color: rgba(0, 32, 59, 0.98);
        text-align: center;
        .subscribe-part-tip {
            margin-right: 40px;
            font-size: 16px;
            color: #fff; }
        .email {
            width: 260px;
            height: 22px;
            margin-right: 40px;
            padding: 11px 20px;
            border-radius: 2px;
            font-size: 14px;
            color: #3A405B;
            border: none; }
        .subscribe-btn {
            width: 120px;
            height: 40px;
            border-radius: 2px;
            background-color: #C5002B;
            border: 1px solid rgba(58, 64, 91, 0.20);
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            vertical-align: middle; } }
    // 榜单模块
    .edge_part {
        display: flex;
        flex-wrap: wrap;
        width: 1090px;
        min-width: 1090px;
        margin: 0 auto 0px;
        justify-content: space-around;
        padding-bottom: 40px;
        .item {
            background: #fff;
            // box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.1)
            border: 1px solid rgba(0,0,0,0.1);
            width: 340px;
            min-width: 340px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px 86px;
            box-sizing: border-box;
            margin-bottom: 30px;
            position: relative;
            .btn_box {
                position: absolute;
                bottom: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 124px;
                    min-width: 124px;
                    margin: 0 4px; } }
            .tit_logo {
                width: 44px; }
            .bg_logo {
                width: 100%;
                height: 100%; }
            h3 {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
                margin: 18px auto 10px;
                text-align: center; }
            p {
                font-size: 14px;
                font-weight: 400;
                color: #666;
                line-height: 20px;
                margin-bottom: 10px;
                text-align: left; }
            .tags {
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                li {
                    width: 96px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(101,200,198,0.1);
                    margin-bottom: 10px;
                    text-align: center;
                    padding: 5px 12px;
                    line-height: 16px;
                    min-height: 42px;
                    font-size: 12px;
                    color: #C5002B;
                    box-sizing: border-box; } } }
        .flex_center {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 0;
            border: none;
            h3 {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%); } } }
    // 往期评选
    .past_part {
        background: #F8F8FA;
        padding: 30px 0;
        h2 {
            text-align: center;
            font-size: 24px;
            line-height: 34px;
            color: #333333; }
        .past_box {
            width: 1020px;
            min-width: 1020px;
            margin: 30px auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                min-width: 320px;
                width: 320px;
                background: #fff;
                box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.1);
                margin-bottom: 36px;
                img {
                    width: 100%; }
                .item_content {
                    padding: 18px 22px 50px;
                    h3 {
                        font-size: 16px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 24px;
                        margin: 0px auto 10px;
                        text-align: left; }
                    .tags {
                        display: flex;
                        flex-wrap: wrap;
                        li {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba(101,200,198,.09);
                            margin-bottom: 6px;
                            margin-right: 6px;
                            padding: 5px;
                            line-height: 16px;
                            font-size: 12px;
                            color: #C5002B;
                            width: unset;
                            min-width: unset;
                            img {
                                width: 17px;
                                min-width: 17px;
                                margin-right: 4px; } } } } }
            .bg_no {
                background: none;
                box-shadow: none;
                margin: 0; } }
        .news-load {
            width: 120px;
            height: 36px;
            margin: 0 auto;
            // margin-top: 40px
            background-color: #fff;
            box-shadow: 0px 0px 4px 0px rgba(195,195,195,1);
            text-align: center;
            line-height: 36px;
            font-size: 13px;
            color: #333333;
            cursor: pointer;
            transform: scale(1);
            transition: all .5s linear; } } }










