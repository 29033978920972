@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg); } }
@keyframes turnl {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); } }
@keyframes turnr {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }

.qianzai-header {
    line-height: 80px;
    height: 80px;
    .inner {
        width: 980px;
        margin: 0 auto;
        color: #666;
        .options {
            @include fs(18);
            .btn {
                border-color: rgba(128,128,128,.5);
                margin-right: 20px; }
            .line {
                position: relative;
                top: -2px; } }

        .logo_img {
            display: inline-block;
            *display: inline;
            vertical-align: top; }
        .columns {
            margin-left: 10px;
            display: inline-block;
            *display: inline;
            .column-line {
                margin-right: 10px;
                @include fs(16);
                display: inline-block;
                *display: inline;
                vertical-align: top; }
            .column-title {
                @include fs(20);
                margin-right: 44px;
                display: inline-block;
                *display: inline; }
            a.news-menu,
            a.projects-menu {
                i {
                    display: inline-block;
                    *display: inline; }
                i.hover {
                    animation: turnl 0.3s linear 0s 1 normal running forwards; }
                i.hover-out {
                    animation: turnr 0.5s linear 0s 1 normal running forwards; } } }
        a {
            color: #666; }
        ul.column-list {
            display: inline-block;
            *display: inline;
            li {
                display: inline-block;
                *display: inline;
                @include fs(18);
                // margin-right: 48px
                margin-right: 25px;
                a {
                    position: relative;
                    display: block;
                    color: #666666;
                    padding: 0 6px;
                    &:hover,&.current {
                        color: $main_blue;
                        .avia-menu-fx {
                            opacity: 1;
                            visibility: visible; } } }
                .avia-menu-fx {
                    position: absolute;
                    bottom: 0px;
                    height: 3px;
                    z-index: 2;
                    width: 100%;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    -moz-transition: all 0.3s ease-out;
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                    background-color: $main_blue; } } }

        .dropdown-news-menu,.dropdown-projects-menu {
            left: -245px;
            // left: -130px
            top: -35px;
            .dropdown-menu {
                padding: 32px 30px 12px;
                left: -14px;
                &:before {
                    right: 15px; } }
            ul {
                line-height: 20px; }
            li {
                margin-right: 0;
                width: 60px;
                line-height: 20px;
                margin-bottom: 20px;
                a {
                    @include fs(14);
                    line-height: 20px;
                    &.current {
                        color: $main_blue; } } }
            a {
                padding: 0; } }
        .dropdown-projects-menu {
            left: -377px; }
        .user-dropdown ul {
            line-height: 40px; }
        .dropdown-menu-user {
            .dropdown-menu {
                right: 0px;
                top: 70px;
                padding: 3px 0px;
                line-height: 32px;
                @include rounded(3px);
                a {
                    display: inline-block;
                    *display: inline;
                    width: 86px;
                    text-align: center;
                    padding-right: 0;
                    margin-right: 0;
                    color: #666666;
                    &:hover {
                        color: $main_blue; } }
                .current {
                    color: $main_blue; }
                &::before {
                    right: calc(10px);
                    right: -webkit-calc(10px);
                    right: -moz-calc(10px); } } }
        .line {
            height: 15px;
            display: inline-block;
            *display: inline;
            vertical-align: middle;
            margin: 0 5px;
            border-right: 1px solid #666; } } }
.vc-ident {
    color: #333333;
    width: 100%;
    overflow: hidden;
    .top-intro {
        background-image: url("/public/css/img/vc-bg.jpg");
        background-position: center 0;
        min-height: 100vh;
        background-size: cover;
        .cont {
            @extend .border-box;
            position: relative;
            width: 1200px;
            margin: 0 auto;
            height: 100vh;
            .logo {
                display: block;
                padding-top: 60px; } }
        .a1 {
            animation-delay: .1s; }
        .a2 {
            animation-delay: .5s; }
        .a3 {
            animation-delay: .9s; }
        .a4 {
            animation-delay: 1.3s; }
        .a5 {
            animation-delay: 1.7s; }
        .words {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(-50%,-80%);
            text-align: center;
            .title {
                font-size: 64px;
                line-height: 84px;
                color: #FC4B4B;
                font-weight: bold; }
            .line {
                display: inline-block;
                *display: inline;
                width: 260px;
                height: 2px;
                background-color: #FC4B4B;
                margin: 20px 0 40px 0; }
            .intro {
                font-size: 20px;
                line-height: 20px;
                font-weight: bold;
                color: #fff; } }
        .cover-part {
            width: 414px;
            height: 324px;
            z-index: 3;
            position: absolute;
            text-align: center;
            right: -89px;
            bottom: 42px; }
        .b-part {
            width: 0px;
            height: 0px;
            overflow: hidden;
            &.active {
                width: 414px;
                height: 324px;
                @include transition(.6s,linear,.09s); } }
        .w-pic,.b-pic {
            position: relative;
            display: inline-block;
            *display: inline;
            right: 0;
            h4 {
                display: inline-block;
                *display: inline;
                color: #fff;
                position: absolute;
                font-size: 14px;
                line-height: 20px; } }
        .w-pic {
            img {
                animation: spin 2s ease 2.1s; }
            .t1,.t6 {
                left: 50%;
                @include transformX(-50%); }

            .t1 {
                top: -28px; }
            .t6 {
                bottom: -28px; }
            .t2,.t3 {
                top: 55px; }
            .t4,.t5 {
                bottom: 60px; }

            .t2,.t4 {
                @include transformX(-100%);
                left: -15px; }
            .t3,.t5 {
                @include transformX(100%);
                right: -15px; } }
        .b-pic {
            margin-top: 117px;
            position: absolute;
            width: 235px;
            height: 134px;
            right: 89px;
            bottom: 73px;
            h4 {
                color: #333; }
            .t4,.t5 {
                bottom: 45px; }
            .t6 {
                left: 50%;
                @include transformX(-50%);
                bottom: -43px; }
            .t4 {
                @include transformX(-100%);
                left: -15px; }
            .t5 {
                @include transformX(100%);
                right: -15px; } } }

    .form-banner {
        width: 100%;
        background-color: #fff;
        position: relative;
        @include transformX(100vw);
        // right: 100vw
        top: 40px;
        // display: none
        &.active {
            display: block;
            top: 0px;
            @include transformX(0vw);
            -webkit-transition: transform .5s linear;
            -moz-transition: transform .5s linear;
            -o-transition: transform .5s linear;
            transition: transform .5s linear; }
        .bias {
            width: 200%;
            left: -50%;
            position: absolute;
            height: 540px;
            @include rotate(-9.7deg);
            top: -144px;
            background-color: #FFF; }
        .words {
            position: relative;
            text-align: center;
            z-index: 2;
            position: relative;
            top: -70px;
            padding-bottom: 70px;
            .title {
                font-size: 36px;
                line-height: 37px;
                margin-bottom: 21px; }
            .line {
                display: inline-block;
                *display: inline;
                background-color: #333;
                width: 80px;
                height: 2px;
                margin-bottom: 20px; }
            .intro {
                font-size: 18px;
                line-height: 22px;
                color: #666;
                margin-bottom: 59px; }
            .be-vc {
                padding: 17px 50px;
                color: #fff;
                font-size: 24px;
                background-color: #FF3D00;
                border: 0;
                margin-bottom: 71px;
                font-weight: bold; }

            .bottom-banner {
                position: absolute;
                left: 0;
                width: 100%;
                background-color: #F2F2F2;
                line-height: 150px;
                text-align: center;
                font-size: 24px;
                .cont {
                    width: 1200px;
                    margin: 0 auto;
                    overflow: hidden; }
                .guid {
                    color: #646464;
                    margin-right: 55px; }
                .focus {
                    line-height: 65px;
                    background-color: #FC4B4B;
                    padding: 0 46px;
                    border: 0;
                    color: #fff;
                    font-weight: bold; }
                input {
                    border: 2px solid #aaa;
                    line-height: 65px;
                    padding: 0 20px;
                    color: #aaa;
                    @include rounded(4px);
                    margin-right: 30px;
                    background-color: transparent; } } }

        .benefit {
            width: 966px;
            margin: 0 auto 90px;
            li {
                display: inline-block;
                *display: inline;
                text-align: center;
                width: 30%;
                vertical-align: top;
                img {
                    margin-bottom: 64px; } }
            .txt {
                display: block;
                font-size: 24px;
                line-height: 38px; } }

        .vc-form {
            padding-top: 80px;
            width: 1100px;
            margin: 0 auto 81px;
            font-size: 0;
            .title {
                font-size: 36px;
                line-height: 37px;
                margin-bottom: 21px; }
            .line {
                width: 80px;
                height: 2px;
                background-color: #333;
                margin-bottom: 30px; }
            form {
                padding-bottom: 300px;
                position: relative; }
            .guid {
                font-size: 16px;
                line-height: 16px;
                color: #808080;
                margin-bottom: 45px; }
            .left,.right {
                display: inline-block;
                *display: inline; }
            .left {
                width: 36.36%;
                margin-right: 9.09%; }
            .right {
                width: 54.55%;
                vertical-align: top; }
            .part {
                margin-bottom: 50px;
                &:last-child {
                    margin-bottom: 0; }
                label {
                    display: block;
                    font-size: 16px;
                    margin-bottom: 16px; }
                .unempty {
                    color: $main_blue; }
                .tip {
                    font-size: 13px;
                    color: #808080; }
                input[type="text"],textarea {
                    @extend .border-box;
                    width: 80%;
                    height: 38px;
                    border: 1px solid #D9D9D9;
                    @include rounded(4px);
                    margin-bottom: 15px;
                    font-size: 14px;
                    line-height: 38px;
                    padding: 0 10px;
                    &.small {
                        width: 40%; } }
                .get-code {
                    text-align: center;
                    background-color: #f2f2f2;
                    display: inline-block;
                    *display: inline;
                    width: 35%;
                    line-height: 38px;
                    color: #A0A0A0;
                    font-size: 16px;
                    margin-left: 20px;
                    cursor: pointer;
                    color: #fff;
                    background-color: #FF3D00;
                    &.disable {
                        background-color: #f2f2f2;
                        color: #A0A0A0; } }
                input[type="text"] {
                    padding-right: 40px; }
                textarea {
                    width: 100%;
                    min-height: 100px;
                    resize: no; }
                .help {
                    color: #808080;
                    font-size: 13px;
                    line-height: 13px; }
                .status {
                    display: inline-block;
                    *display: inline;
                    line-height: 38px;
                    margin-left: -30px;
                    vertical-align: top;
                    i {
                        display: none;
                        font-size: 24px;
                        vertical-align: middle; } }

                .label-status {
                    line-height: normal; }
                .success {
                    .icon-circle-check {
                        display: inline-block;
                        *display: inline;
                        color: #1FCBA3; } }
                .error {
                    .icon-circle-cross {
                        display: inline-block;
                        *display: inline;
                        color: #FF5A60; } }
                .underline {
                    padding-bottom: 16px;
                    border-bottom: 1px solid #D9D9D9; }

                .photo-bannel {
                    @extend .border-box;
                    border: 2px solid #E5E5E5;
                    background-color: #F2F2F2;
                    width: 110px;
                    height: 110px;
                    text-align: center;
                    cursor: pointer;
                    i {
                        line-height: 106px;
                        font-size: 28px;
                        color: #B3B3B3; } }
                .checkboxs {
                    label {
                        display: inline-block;
                        *display: inline;
                        width: 175px;
                        margin-bottom: 20px; }
                    input {
                        margin-right: 7px; } }
                .dropdown-part {
                    margin: 0 0 30px 0; } }
            .identity-part {
                .form-part {
                    margin-bottom: 20px; }
                .scale-label {
                    vertical-align: top;
                    margin-right: 20px; }
                .radios {
                    display: inline-block;
                    *display: inline;
                    input {
                        margin-right: 10px; } }
                .set-part {
                    .start-time {
                        span {
                            font-size: 16px;
                            line-height: 38px; } }
                    .form-part {
                        margin-bottom: 20px; }
                    .input-label {
                        width: 14%;
                        margin-right: 10px;
                        margin-bottom: 0; }
                    input[type="text"] {
                        width: 38%;
                        margin-bottom: 0; }
                    .little-input {
                        width: 16% !important;
                        margin-right: 20px;
                        margin-bottom: 0; }
                    .until_now {
                        margin: 0 10px; } }
                .input-label {
                    display: inline-block;
                    *display: inline; } }
            .case-part {
                .case-infors {
                    span {
                        color: #333;
                        font-size: 16px;
                        line-height: 17px; }
                    label {
                        width: 150px;
                        display: inline-block;
                        *display: inline;
                        font-size: 16px;
                        color: #909090; }
                    .right-button {
                        vertical-align: bottom; }
                    .case-info {
                        margin-bottom: 20px; } }
                .add {
                    color: #FF5A60; }
                .hide-form {
                    border: #D8D8D8 1px solid;
                    padding: 30px 30px 30px 30px;
                    @include rounded(5px);
                    .input-label {
                        display: inline-block;
                        *display: inline;
                        width: 30%;
                        margin-right: 10px;
                        margin-bottom: 0; }
                    .form-part {
                        margin-bottom: 20px;
                        font-size: 16px; }
                    .input_normal {
                        width: 53%;
                        margin-bottom: 0; }
                    .s-label {
                        display: inline-block;
                        *display: inline;
                        margin-right: 20px;
                        input {
                            margin-right: 7px; } }
                    .middle_input {
                        width: 41%; }
                    .little-input {
                        width: 140px;
                        margin-bottom: 0;
                        vertical-align: middle;
                        margin-right: 15px; }
                    .start-time {
                        display: inline-block;
                        *display: inline;
                        &.block {
                            display: block;
                            overflow: hidden; } }
                    .dropdown-part {
                        display: inline-block;
                        *display: inline;
                        vertical-align: middle;
                        margin-bottom: 0;
                        margin-right: 20px;
                        .dropdown-btn,ul {
                            width: 140px; }
                        .dropdown-btn {
                            padding-top: 9px;
                            padding-bottom: 4px;
                            border-color: #D8D8D8; } }
                    .investment_stage {
                        .dropdown-btn,ul {
                            width: 230px; } }
                    .btn {
                        margin: 0 15px; }
                    .buttons {
                        text-align: right;
                        padding-right: 129px; } } }

            .mobile-part {
                .status {
                    margin-left: 10px; } }
            .bio-part {
                position: relative;
                .status {
                    position: absolute;
                    right: 10px;
                    bottom: 25px; } }
            .summit-part {
                position: absolute;
                bottom: 0;
                left: 50%;
                @include transformX(-50%);
                .notice {
                    display: block;
                    color: #333;
                    font-size: 16px;
                    margin-bottom: 21px;
                    input {
                        margin-right: 7px; }
                    a {
                        color: #FF3D00; } }
                .submit {
                    padding: 21px 50px;
                    color: #fff;
                    font-size: 24px;
                    background-color: #FF3D00;
                    border: 0; } } } } }


.vc_index {
    .top_banner {
        text-align: center;
        background-image: url("/public/css/img/vc-bg-top.jpg");
        // background-color: #333
        background-size: cover;
        background-position: top center;
        // -webkit-filter: blur(2px)
        min-height: 560px;
        .cont {
            padding-top: 60px;
            color: #FFF;
            .title {
                @include fs(72);
                line-height: 83px;
                margin-bottom: 27px;
                text-shadow: 0 2px 3px rgba(0,0,0,.5);
                letter-spacing: 20px;
                display: none; }
            img {
                margin-bottom: 27px; }
            .intro {
                margin-bottom: 70px;
                @include fs(16);
                line-height: 19px;
                text-shadow: 0 1px 1px rgba(0,0,0,.5);
                font-weight: bolder;
                letter-spacing: 24px;
                margin-right: -30px; }
            ul {
                @extend .border-box;
                margin: 0 auto;
                width: 802px;
                height: 120px;
                padding: 20px 0;
                border: 1px solid rgba(255,255,255,.3);
                text-align: left;
                font-size: 0;
                margin-bottom: 70px;
                li {
                    text-align: center;
                    display: inline-block;
                    *display: inline;
                    width: 266px;
                    border-right: 1px solid rgba(255,255,255,.3);
                    padding: 12px 0;
                    h2 {
                        @include fs(18);
                        line-height: 28px;
                        font-weight: bold; }
                    &:last-child {
                        border-right: none; } } }
            .options {
                a {
                    margin: 0 20px; }
                .black {
                    width: 140px; }
                .large {
                    width: 180px; } } } }
    .funding_list {
        width: 980px;
        margin: 0 auto;
        padding: 60px 0 46px;
        .title {
            @include fs(30);
            line-height: 33px;
            letter-spacing: 2.5px;
            margin-bottom: 40px; }
        table {
            color: #666666;
            margin-bottom: 40px;
            th:first-child {
                text-align: left;
                padding-left: 80px; }
            th:last-child {
                padding-right: 10px; }
            td {
                text-align: center;
                @include fs(16);
                &.tl {
                    text-align: left;
                    padding-left: 10px; }
                &:last-child {
                    padding-right: 10px; }
                img {
                    border: 1px solid #EBECED; } }
            tr {
                height: 92px; }
            tr:nth-child(2n+1) {
                border-top: 1px #F2F2F2 solid;
                border-bottom: 1px #F2F2F2 solid;
                background-color: #F7F9FA; }
            tr:first-child {
                line-height: 50px;
                @include fs(16);
                border: none;
                color: #808080;
                height: auto;
                background-color: rgba(86,150,249,.08); }
            .tit {
                color: #5696F9;
                @include fs(18);
                font-weight: bold;
                a {
                    color: #5696F9; } }
            .intro {
                color: #666;
                @include fs(13);
                padding-right: 20px;
                // font-weight: bold
                line-height: 18px; } } }
    .trendmakers {
        width: 100%;
        background-color: #F7FAFF;
        padding: 60px 0;
        a {
            color: inherit !important; }
        .cont {
            width: 1090px;
            margin: 0 auto; }
        .title {
            text-align: center;
            @include fs(30);
            line-height: 33px;
            margin-bottom: 59px; }
        ul {
            margin-right: -20px;
            font-size: 0;
            margin-bottom: 60px; }
        li {
            display: inline-block;
            margin-right: 20px;
            width: 350px;
            @include fs(14);
            vertical-align: top;

            .banner {
                border-bottom: 3px solid #FC4B4B; }
            .inner {
                padding: 18px 14px;
                position: relative;
                border: 1px solid rgba(198,200,206,.5);
                .tit {
                    @inline fs(18) {}
                    line-height: 20px;
                    margin-bottom: 7px; }
                .intro {
                    @inline fs(14) {}
                    line-height: 24px;
                    height: 48px;
                    overflow: hidden; } }
            .logo {
                position: absolute;
                top: -30px;
                right: 15px; } }
        .vc-btn {
            background-color: #F7FAFF;
            color: #808080 !important; } }
    .posts {
        width: 100%;
        background-color: #5696F9;
        color: #fff;
        padding: 60px 0;
        @extend .border-box;
        a {
            color: #fff; }
        .cont {
            width: 980px;
            margin: 0 auto 30px;
            .title {
                text-align: center;
                @include fs(30);
                line-height: 33px;
                margin-bottom: 59px; }
            h4 {
                a {
                    overflow: hidden;
                    display: block; } }
            .point {
                @include fs(22);
                vertical-align: bottom;
                float: left;
                margin-right: 10px; }
            .left,.right {
                width: 402px;
                border-top: 4px #fff solid;
                padding-top: 20px;
                li {
                    margin-bottom: 30px; }
                .tit {
                    @include fs(20);
                    line-height: 21px;
                    margin-bottom: 33px;
                    font-weight: bold; }
                h4 {
                    @include fs(18);
                    line-height: 28px;
                    margin-bottom: 5px;
                    font-weight: bold; }
                p {
                    @include fs(14);
                    line-height: 26px;
                    padding-left: 17px;
 }                    // font-weight: bold
                p.time {
                    line-height: 17px; } } }
        .vc-btn {
            background-color: #5696F9;
            border: 1px solid rgba(255,255,255,.3); } }
    .investors {
        background-image: url("/public/css/img/vc-bg2.png");
        background-position: center;
        background-size: cover;
        .cont {
            width: 1080px;
            margin: 0 auto;
            padding: 60px; }
        .title {
            text-align: center;
            @include fs(30);
            line-height: 33px;
            margin-bottom: 59px; }
        .investor-list {
            font-size: 0;
            margin-left: -60px;
            margin-bottom: 60px;
            li {
                width: 320px;
                text-align: center;
                display: inline-block;
                margin-left: 60px;
                background-color: #FFF;
                vertical-align: top;
                img {
                    margin-bottom: 10px;
                    border: 10px solid #F2F2F2;
                    @include rounded(50%); }
                .name {
                    @include fs(20);
                    line-height: 23px;
                    margin-bottom: 14px;
                    color: #2D3A4F; }
                .job {
                    @include fs(16);
                    line-height: 20px;
                    color: #808080; }
                .top {
                    padding: 20px 20px 30px;
                    border: 1px solid rgba(198,200,206,.5);
                    border-bottom: 0; }
                .bottom {
                    border-top: 3px solid #93BDFF;
                    padding: 39px 20px 43px;
                    .intro {
                        color: #666666;
                        @include fs(14);
                        line-height: 22px; } } } }

        .vc-btn {
            border: 1px solid #666;
            color: #000;
            opacity: .4;
            font-weight: normal;
            letter-spacing: 1px;
            background: -moz-linear-gradient(top,rgba(255,255,255,.5),rgba(0,0,0,.5));
            background: -webkit-linear-gradient(top,rgba(255,255,255,.5),rgba(0,0,0,.5));
            background: -o-linear-gradient(left,rgba(255,255,255,.5),rgba(0,0,0,.5)); } }
    .be_investor {
        padding: 74px 0 59px;
        text-align: center;

        .title {
            color: #333333;
            @include fs(30);
            line-height: 33px;
            margin-bottom: 39px; }
        .intro {
            color: #666;
            @include fs(16);
            line-height: 30px;
 }            // font-weight: bold
        .vc-btn {
            margin: 59px 0 100px; }
        .slide-part {
            position: relative;
            width: 860px;
            margin: 0 auto;
            margin-bottom: 50px;
            .slick-next,.slick-prev {
                opacity: .99;
                margin-top: -20px;
                &:hover {
                    opacity: 1; } }
            .slick-next:before,.slick-prev:before {
                font-family: 'icomoon';
                color: #aaa;
                @include fs(40); }
            .slick-next:before {
                content: '\e902' !important; }
            .slick-prev:before {
                content: "\e903" !important; }
            .part {
                text-align: center;
                img {
                    display: block;
                    margin: 0 auto; }
                .li {
                    display: none; } }
            .slick-list {
 }                // padding-top: 30px !important
            // .blurry
            //     display: inline-block
            //     *display: inline
            //     overflow: hidden
            //     position: relative
            //     @include rounded(50%)
            //     img
            //         @include blur(25)
            //     .shadow
            //         position: absolute
            //         width: 100%
            //         height: 100%
            //         top: 0
            //         left: 0
            //         background-color: rgba(86,150,249,.4)
            //         z-index: 2
            //     .icon
            //         position: absolute
            //         width: 100%
            //         height: 100%
            //         top: 0
            //         left: 0
            //         z-index: 3
            //         i
            //             @include fs(70)
            //             position: absolute
            //             top: 50%
            //             left: 50%
            //             @include transform(-50%,-50%)
            //             color: #fff
            .active {
                position: relative;
                .li {
                    display: block; }
                .si {
                    display: none; }
                // .blurry
                //     width: 260px !important
                //     height: 260px
                //     position: relative
                //     top: -30px
                //     img
                //         @include blur(3)
                //         width: 260px
                //         height: 260px
                //     .shadow
                //         background-color: rgba(86,150,249,.2)
                //     .icon i
                //         color: #FC3768
 } }                //         @include fs(97)


        .show-part {
            width: 860px;
            margin: 0 auto;
            overflow: hidden;
            .part {
                a {
                    color: #FC3768;
                    @include fs(20);
                    line-height: 22px;
                    margin-bottom: 19px;
                    display: block;
                    font-weight: bold; }
                .intro {
                    @include fs(16);
                    line-height: 22px;
                    color: #666;
                    font-weight: normal; } } } }
    .bottom_banner {
        position: relative;
        color: #fff;
        .cont {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(-50%, -50%);
            text-align: center;
            .title {
                @include fs(36);
                line-height: 50px;
                margin-bottom: 17px; }
            p {
                @include fs(24);
                color: #fff;
                a {
                    color: #fff; }
                line-height: 30px; } } } }
.vc_detail {
    .project_banner {
        width: 100%;
        height: 260px;
        background-color: #F7F9FA;
        @extend .border-box;
        margin-bottom: 60px;
        .cont {
            width: 980px;
            margin: 0 auto;
            padding: 20px 0;
            color: #808080;
            font-size: 0; }
        .left {
            padding-top: 20px;
            width: 530px;
            .infor {
                overflow: hidden; }
            .logo {
                margin-right: 30px; }
            .title {
                @include fs(24);
                line-height: 33px;
                color: #333;
                margin-bottom: 20px; }
            .intro {
                @include fs(16);
                line-height: 28px;
                margin-bottom: 35px; }
            .share {
                color: #b3b3b3;
                @include fs(14px);
                line-height: 18px; }
            .share-part {
                .qrcode {
                    width: 115px;
                    padding: 20px 20px 10px;
                    text-align: center;
                    background-color: #FFF;
                    border: 1px solid #EFEFEF;
                    display: none;
                    position: relative;
                    top: -108px;
                    left: 160px;
                    .triangle {
                        position: absolute;
                        display: inline-block;
                        *display: inline;
                        border: 15px solid transparent;
                        border-right-color: #EFEFEF;
                        left: -30px;
                        top: 50%;
                        margin-top: -20px;
                        span {
                            position: absolute;
                            display: inline-block;
                            *display: inline;
                            border: 14px solid transparent;
                            border-right-color: #fff;
                            left: -12px;
                            top: -14px; } }
                    .img {
                        text-align: center;
                        margin-bottom: 15px;
                        img {
                            display: inline-block;
                            *display: inline !important; } }
                    .tit {
                        color: #666666;
                        @include fs(14);
                        line-height: 20px; } }
                .txt {
                    @include fs(14);
                    line-height: 18px;
                    margin-right: 10px; }
                a {
                    @include fs(16);
                    width: 26px;
                    height: 26px;
                    border: 1px #E73C58 solid;
                    color: #E73C58;
                    @include rounded(50%);
                    margin-right: 15px;
                    text-align: center;
                    display: inline-block;
                    *display: inline;
                    i {
                        vertical-align: middle; } }
                .js_weixin {
                    color: #4BBB08;
                    border-color: #4BBB08; } } }
        .right {
            width: 408px;
            border-left: 1px solid #D8D8D8;
            padding-left: 60px;
            @include fs(14);
            @extend .border-box;
            .coins {
                @include fs(24);
                line-height: 24px;
                margin-bottom: 15px; }
            .already {
                margin-bottom: 30px; }
            .stages {
                color: #5998F9;
                @include fs(18);
                line-height: 19px; }
            .count_down {
                margin-bottom: 9px;
                color: $main_blue;
                .day {
                    color: #808080; } }

            .bar {
                width: 100%;
                background-color: #EFEFEF;
                height: 12px;
                @include rounded(20px);
                overflow: hidden;
                margin-bottom: 28px;
                .has {
                    background-color: #5998F9;
                    height: 100%; } }
            .goal,.min,.share {
                display: inline-block;
                *display: inline;
                .num {
                    @include fs(16);
                    line-height: 24px; }
                .txt {
                    line-height: 24px;
                    margin-bottom: 9px; } }
            .goal {
                width: 140px; } } }

    .project_infor {
        width: 980px;
        margin: 0 auto;
        .title {
            @include fs(20);
            line-height: 28px;
            margin-bottom: 30px; }
        .left {
            width: 640px;
            margin-right: 60px;
            .pics {
                margin-bottom: 26px;
                width: 640px;
                height: 320px;
                overflow: hidden;
                img {
                    width: auto !important;
                    height: auto !important;
                    max-width: 640px;
                    max-height: 320px;
                    margin: 0 auto; } }
            .small_pics {
                margin-bottom: 36px;
                text-align: center;
                height: 78px;
                img {
                    margin: 0 21px;
                    cursor: pointer;
                    border: 4px solid #fff;
                    &.current {
                        border-color: #FF3D00; } } }
            .describe {
                @include fs(18);
                line-height: 31px;
                margin-bottom: 50px;
                a {
                    color: $main_blue;
                    text-align: center; }
                img {
                    display: block;
                    margin: 20px auto; } }

            .group,.grow_data {
                border-top: 1px solid #f2f2f2;
                padding-top: 40px; }
            .group {
                .pannel {
                    border: 1px solid #f2f2f2;
                    margin-bottom: 30px;
                    padding: 20px 30px 20px 40px;
                    position: relative;
                    img {
                        position: absolute;
                        top: 50%;
                        margin-top: -40px;
                        @include rounded(50%); }
                    .name {
                        color: #666;
                        @include fs(16);
                        line-height: 20px;
                        margin-bottom: 5px; }
                    .job {
                        color: #808080;
                        @include fs(14);
                        line-height: 20px;
                        margin-bottom: 10px; }
                    .intro {
                        color: #666;
                        @include fs(13);
                        line-height: 22px; } }
                .infor {
                    display: inline-block;
                    *display: inline;
                    width: 460px; } }
            .grow_data {
                border-bottom: 1px solid #f2f2f2;
                margin-bottom: 30px;
                .fl {
                    margin-right: 5px; }
                .point {
                    display: inline-block;
                    *display: inline;
                    width: 12px;
                    height: 12px;
                    @include rounded(50%);
                    background-color: #F25A5A;
                    margin-right: 10px;
                    line-height: 21px; }
                .gap-line {
                    margin-left: 5px; }
                li {
                    margin-bottom: 30px;
                    .time {
                        @include fs(18);
                        line-height: 21px;
                        color: #666; }
                    &:last-child {
                        margin-bottom: 30px; } }
                .right_cont {
                    overflow: hidden;
                    .tit {
                        @include fs(16);
                        line-height: 22px;
                        color: #666; } } }
            .analysis {
                li {
                    margin-bottom: 50px; }
                .fl {
                    @include fs(18);
                    line-height: 30px;
                    color: #666;
                    width: 450px;
                    a {
                        color: #666; } }
                .right_cont {
                    color: #FF6700;
                    @include fs(16);
                    line-height: 30px; }
                .txt {
                    margin-right: 10px; }
                .gap {
                    margin-right: 10px;
                    display: inline-block;
                    *display: inline;
                    height: 10px;
                    width: 1px;
                    background: #D8D8D8; }
                .price {
                    color: #3AC0C3; } }
            .report {
                border-top: 1px solid #f2f2f2;
                padding-top: 30px;
                margin-bottom: 60px;
                .tit {
                    @include fs(18);
                    line-height: 30px;
                    color: #666;
                    width: 450px; }
                .right_cont {
                    float: right;
                    color: #FFFFFF;
                    border: 2px solid #F24964;
                    @include rounded(4px);
                    line-height: 36px;
                    i {
                        color: #F24964; }
                    .icon {
                        display: inline-block;
                        *display: inline;
                        padding: 0 8px; }
                    .txt {
                        color: #fff;
                        background-color: #F24964;
                        @include rounded(2px);
                        display: inline-block;
                        *display: inline;
                        padding: 0 8px;
                        @include fs(14);
                        line-height: 38px; } }
                .img {
                    margin-bottom: 20px; }
                .pannel {
                    border: 1px solid #E9E9E9;
                    .top {
                        padding: 30px 110px 0 30px; }
                    .tit {
                        line-height: 25px;
                        margin-bottom: 20px; }
                    .intro {
                        color: #666666;
                        line-height: 26px;
                        @include fs(16); }
                    .see {
                        margin-bottom: 30px; }
                    .update {
                        line-height: 26px;
                        @include fs(14);
                        color: #FF6700; }
                    .buy {
                        margin-bottom: 40px;
                        color: #fff; }
                    .remind {
                        background-color: #F7F9FA;
                        @include fs(18);
                        line-height: 60px;
                        color: #666;
                        padding: 0 30px;
                        .vc-btn {
                            margin-top: 11px;
                            color: #3AC0C3;
                            border-color: #3AC0C3;
                            line-height: 36px;
                            font-weight: normal; } } } }
            .others {
                margin-bottom: 80px;
                li {
                    line-height: 60px;
                    background-color: #F7F9FA;
                    padding: 0 30px 0 20px;
                    margin-bottom: 20px;
                    .txt {
                        @include fs(16);
                        color: #666; }
                    i {
                        @include fs(24);
                        color: #b3b3b3;
                        vertical-align: sub; }
                    .fr {
                        color: #808080; } } } }

        .right {
            overflow: hidden;
            .tit {
                line-height: 54px;
                background-color: #F7F9FA;
                @include fs(18);
                color: #666;
                padding: 0 10px; }
            .part {
                color: #808080;
                @include fs(14);
                border-bottom: 1px #F2F2F2 solid;
                padding: 20px 0;
                line-height: 20px;
                .go {
                    line-height: 28px;
                    border: 1px solid #FF3D00;
                    background-color: rgba(255,61,0,.05);
                    color: #FF3D00;
                    @include fs(16);
                    padding: 0 16px;
                    @include rounded(2px); }
                .time {
                    margin-top: 14px;
                    line-height: 18px;
                    color: #b3b3b3;
                    @include fs(13); }
                &:last-child {
                    border-bottom: none; }
                a {
                    color: $main_blue; } }
            .side-part {
                margin-bottom: 60px; }
            .about {
                label {
                    display: block;
                    margin-bottom: 5px; } } } }
    .guid_part {
        background-color: #F7FAFF;
        padding: 82px 0;
        .cont {
            width: 648px;
            margin: 0 auto;
            .title {
                @include fs(30);
                line-height: 33px;
                margin-bottom: 29px; }
            .intro {
                margin-bottom: 59px;
                @include fs(16);
                line-height: 19px;
                color: #666; }
            .parts {
                text-align: left;
                position: relative;
                font-size: 0;
                .line {
                    position: absolute;
                    width: 1px;
                    background-color: #d8d8d8;
                    height: 300px;
                    top: 36px;
                    right: 50%; } }
            .part {
                @include fs(16);
                color: #666;
                width: 250px;
                display: inline-block;
                *display: inline;
                vertical-align: top;
                margin-bottom: 77px;
                p {
                    padding-left: 40px;
                    width: 200px; }
                i {
                    margin-right: 8px; }
                img {
                    margin-right: 12px; } }
            .tit {
                @include fs(20);
                color: #FF7878;
                line-height: 23px;
                margin-bottom: 19px; } } } }
.vc_list {
    .cont {
        width: 980px;
        margin: 0 auto; }
    .news {
        background-color: #F7F9FA;
        line-height: 60px;
        color: #808080;
        @include fs(16);
        a {
            color: #808080; }
        .tit {
            display: inline-block;
            *display: inline;
            @include fs(16);
            a {
                color: $main_blue; } }
        .more {
            i {
                color: #666666;
                @include fs(12);
                line-height: 60px;
                display: inline-block;
                *display: inline;
                margin-left: 5px; } } }
    .selects {
        margin-top: 40px;
        @include fs(16);
        color: #666666;
        a {
            color: #666;
            &:hover,&.current {
                color: $main_blue; } }
        .part {
            line-height: 20px;
            margin-bottom: 15px; }
        .tit {
            font-weight: bold;
            display: inline-block;
            *display: inline;
            margin-right: 20px; }
        .all {
            display: inline-block;
            *display: inline; }
        ul,li {
            display: inline-block;
            *display: inline; }
        .left {
            float: left;
            margin-right: 38px;
            line-height: 36px; }
        ul {
            width: 775px;
            overflow: hidden; }
        li {
            line-height: 36px;
            text-align: left;
            margin-right: 38px; }
        ul.ul_platform {
            height: 36px;
            overflow: hidden;
            transition: all ease 0.3;
            -o-transition: all ease 0.3;
            -moz-transition: all ease 0.3;
            -webkit-transition: all ease 0.3;
            &.auto_size {
                height: auto; } }
        .js-open {
            cursor: pointer;
            line-height: 36px;
            color: #B3B3B3;
            @include fs(13);
            vertical-align: top;
            i {
                @include fs(28);
                color: $main_blue;
                display: inline-block;
                *display: inline;
                vertical-align: middle; }
            i.hover {
                animation: turnl 0.3s linear 0s 1 normal running forwards; }
            i.hover-out {
                animation: turnr 0.5s linear 0s 1 normal running forwards; } } }
    .project_list {
        .get_more {
            border-top: 1px solid #F2F2F2;
            margin-bottom: 80px;
            padding-top: 60px; }
        li {
            padding: 30px 10px;
            border-top: 1px solid #E8E8E8;
            .left {
                width: 640px;
                .logo {
                    margin-right: 30px;
                    margin-top: 16px;
                    border: 1px solid #EBECED; }
                .infor {
                    overflow: hidden; }
                .title {
                    @include fs(24);
                    line-height: 33px;
                    margin-bottom: 12px;
                    a {
                        color: #333; } }
                .intro {
                    @include fs(16);
                    line-height: 28px;
                    color: #666;
                    margin-bottom: 15px; }
                .txt {
                    @include fs(13);
                    line-height: 15px;
                    color: #808080; } }
            .right {
                width: 240px;
                text-align: center;
                padding-left: 30px;
                border-left: 1px solid rgba(216,216,216,.5);
                color: #808080;
                .coins {
                    @include fs(24);
                    line-height: 27px;
                    margin-bottom: 10px; }
                .already {
                    @include fs(14);
                    line-height: 14px;
                    margin-bottom: 21px; }
                .progress {
                    margin-bottom: 16px;
                    .percent {
                        @include fs(16);
                        line-height: 18px; } }

                .platform {
                    @include fs(14);
                    line-height: 18px; }

                .bar {
                    width: 75%;
                    height: 20px;
                    overflow: hidden;
                    @include rounded(20px);
                    background-color: #EFEFEF;
                    text-align: left;
                    .has {
                        height: 100%;
                        background-color: #5998F9; } } } }
        .related_case_reports {
            padding: 20px 48px 0px 10px;
            border-top: 1px dashed #F2F2F2;
            margin-top: 30px;
            .left_cont {
                color: #666666; }
            .type {
                float: left;
                margin-right: 30px;
                display: inline-block;
                *display: inline;
                background-color: #F2F7FF;
                @include fs(16);
                line-height: 36px;
                padding: 0 18px; }
            .intro {
                @include fs(18);
                line-height: 30px;
                color: #666;
                display: block;
                overflow: hidden; }

            .right_cont {
                color: #FF6700;
                @include fs(16);
                line-height: 30px;
                float: right;
                .txt {
                    margin-right: 10px; }
                .gap {
                    margin-right: 10px;
                    display: inline-block;
                    *display: inline;
                    height: 10px;
                    width: 1px;
                    background: #D8D8D8; }
                .price {
                    color: #3AC0C3; } } } } }
.vc_news {
    .top_news {
        background-color: #F7F9FA;
        .cont {
            width: 820px;
            margin: 0 auto;
            font-size: 0;
            height: 148px;
            padding-top: 30px;
            @extend .border-box;
            margin-bottom: 40px;
            .tit {
                color: $main_blue;
                @include fs(16);
                line-height: 16px;
                margin-bottom: 22px; }
            .more {
                color: #808080;
                @include fs(13);
                line-height: 15px;
                i {
                    margin-left: 10px;
                    @include fs(12); } } }
        .part {
            color: #666666;
            display: inline-block;
            *display: inline;
            width: 45%;
            margin-right: 10%;
            vertical-align: top;
            &:last-child {
                margin-right: 0; }
            a {
                color: #666; }
            .news_t {
                @include fs(16);
                line-height: 18px;
                margin-bottom: 10px; }
            .time {
                @include fs(14);
                line-height: 18px; } } }

    .list {
        width: 700px;
        margin: 0 auto;
        padding-bottom: 60px;
        .title {
            @include fs(20);
            line-height: 28px;
            padding-bottom: 10px;
            display: inline-block;
            *display: inline;
            border-bottom: 2px solid $main_blue; }
        .pagination {
            border-top: none; }
        .news_list {
            margin-top: -1px;
            margin-bottom: 30px;
            li {
                border-top: 1px solid #E8E8E8;
                padding: 30px 0 20px;
                color: #666;
                a {
                    color: #666; }
                .news_t {
                    @include fs(20);
                    line-height: 28px;
                    margin-bottom: 10px; }
                .time {
                    @include fs(14);
                    line-height: 26px;
                    margin-bottom: 12px; }
                .summary {
                    @include fs(16);
                    line-height: 28px;
                    a {
                        color: #000; }
                    p {
                        margin-bottom: 15px; } } }
            .share-part {
                padding-left: 21px;
                margin-top: 35px;
                position: relative;
                .qrcode {
                    width: 115px;
                    padding: 20px 20px 10px;
                    text-align: center;
                    background-color: #FFF;
                    border: 1px solid #EFEFEF;
                    display: none;
                    position: absolute;
                    top: -84px;
                    left: 190px;
                    .triangle {
                        position: absolute;
                        display: inline-block;
                        *display: inline;
                        border: 15px solid transparent;
                        border-right-color: #EFEFEF;
                        left: -30px;
                        top: 50%;
                        margin-top: -20px;
                        span {
                            position: absolute;
                            display: inline-block;
                            *display: inline;
                            border: 14px solid transparent;
                            border-right-color: #fff;
                            left: -12px;
                            top: -14px; } }
                    .img {
                        text-align: center;
                        margin-bottom: 15px;
                        img {
                            display: inline-block;
                            *display: inline !important; } }
                    .tit {
                        color: #666666;
                        @include fs(14);
                        line-height: 20px; } }
                .txt {
                    @include fs(14);
                    line-height: 18px;
                    margin-right: 10px;
                    color: #B3B3B3; }
                a {
                    @include fs(16);
                    width: 26px;
                    height: 26px;
                    border: 1px #E73C58 solid;
                    color: #E73C58;
                    @include rounded(50%);
                    margin-right: 15px;
                    text-align: center;
                    display: inline-block;
                    *display: inline;
                    i {
                        vertical-align: middle; } }
                .js_weixin {
                    color: #4BBB08;
                    border-color: #4BBB08; } } }
        .days_news {
            border-top: 1px solid #E8E8E8;
            padding-top: 30px;
            margin-bottom: 60px;
            .circle {
                display: inline-block;
                *display: inline;
                width: 9px;
                height: 9px;
                @include rounded(50%);
                background-color: $main_blue;
                margin-right: 12px;
                &.gray {
                    background-color: #DDE4E5; } }
            .date {
                @include fs(14);
                color: $main_blue;
                line-height: 26px;
                margin-bottom: 15px; }
            .news_list {
                li {
                    border-top: none;
                    margin-bottom: 30px;
                    padding: 0;
                    .news_t {
                        line-height: 30px;
                        padding-left: 21px;
                        cursor: pointer; }
                    .summary {
                        padding-left: 21px; }
                    .time {
                        @include fs(16);
                        line-height: 20px;
                        margin-bottom: 20px; } } } }
        .get_more {
            .vc-btn {
                padding: 0 25px; } } } }
.vc_knowledge {
    border-top: 1px solid #ccc;
    padding-top: 40px;
    .list {
        .news_list {
            // li:first-child
            //  // border-top: none
            li {
                padding-bottom: none;
                ul {
                    list-style-type: disc;
                    margin-left: 20px; }
                ol {
                    margin-left: 20px;
                    list-style-type: decimal; }
                li {
                    border: none;
                    padding: 0; }
                .news_t {
                    color: #666666;
                    font-weight: bold;
                    margin-bottom: 18px; }
                .red_point {
                    color: $main_blue;
                    @include fs(30);
                    line-height: 28px;
                    display: inline-block;
                    *display: inline;
                    margin-right: 10px; }
                .summary {
                    @include fs(16);
                    line-height: 28px;
                    margin-bottom: 20px;
                    p {
                        margin-bottom: 20px; }
                    strong {
                        color: #535353; }
                    a {
                        color: $main_blue;
                        text-decoration: underline; }
                    h1,h2,h3,h4,h5,h6 {
                        color: #666;
                        @include fs(18);
                        line-height: 28px;
                        margin-bottom: 10px;
                        font-weight: bold; } }

                .see_all {
                    color: $main_blue;
                    @include fs(14);
                    line-height: 26px; } } }

        .title {
            margin-bottom: -1px; }
        .intro {
            @include fs(16);
            line-height: 28px;
            color: #666666;
            padding-top: 30px;
            border-top: 1px solid #E8E8E8;
            margin-bottom: 60px; } } }



.wrapper.knowledge {
    margin-top: 0;
    border-top: 1px;
    border-top: 1px solid #ccc;
    .upvote {
        display: inline-block;
        *display: inline;
        line-height: 32px;
        color: $main_blue;
        border: 1px solid $main_blue;
        padding: 0 12px;
        margin-bottom: 70px;
        a {
            color: $main_blue; }
        .num {
            margin-right: 0; } }
    .post-options {
        margin-bottom: 70px; }
    .options .bookmarks {
        color: #808080;
        @include fs(14);
        .gap {
            position: relative;
            top: -2px; } } }

.vc-ident.investor {
    .form_error {
        background-color: #fff;
        position: fixed;
        left: 50%;
        top: 50%;
        @include transform(-50%,-50%);
        font-size: 24px;
        color: #666666;
        padding: 58px 77px 58px 100px;
        line-height: 25px;
        box-shadow: 0 0 30px 0 rgba(0,0,0,.5);
        z-index: 2;
        width: 500px;
        @extend .border-box;
        .tit {
            color: #FC4B4B;
            font-size: 64px;
            line-height: 76px;
            margin-bottom: 20px; }
        .close-btn {
            display: inline-block;
            *display: inline;
            width: 46px;
            height: 46px;
            text-align: center;
            background-color: rgba(0,0,0,.5);
            border: 2px solid rgba(255,255,255,.3);
            @include rounded(50%);
            color: #fff;
            position: absolute;
            top: -20px;
            right: -20px;
            cursor: pointer;
            i {
                line-height: 46px; } } }
    .form-banner {
        @include transformX(0vw);
        top: 0;
        border-top: 1px solid #F0F2F3;
        width: 100%;
        .vc-form {
            width: 700px;
            form {
                padding-bottom: 150px; }
            .title {
                margin-bottom: 29px; }
            .guid {
                margin-bottom: 66px; }
            .left,.right {
                display: block;
                width: 100%; }
            .part {
                input[type="text"] {
                    width: 46%;
                    margin-bottom: 0; }
                textarea {
                    width: 100%;
                    margin-bottom: 0; }
                .get-code {
                    width: 20%; }
                .help {
                    margin-top: 10px; } }
            .part:last-child {
                margin-bottom: 50px; }
            .mobile-part {
                .get-code {
                    margin-left: 0;
                    margin-right: 20px; }
                input[type="text"].small {
                    width: 23%; } }
            .part-captcha {
                input[type="text"].small {
                    width: 23%; }
                .text {
                    color: #808080;
                    @include fs(13);
                    margin-left: 20px;
                    a {
                        color: #FD6639;
                        text-decoration: underline; } }
                .get-code {
                    background-color: transparent; } }
            .summit-part {
                bottom: -40px;
                .notice {
                    margin-bottom: 30px; }
                text-align: center;
                .submit {
                    padding: 16px 36px; } }
            .allow {
                margin-top: 20px;
                @include fs(14);
                color: #666;
                input {
                    position: relative;
                    top: -1px;
                    margin-right: 5px; } } } } }
.vc_investor {
    .m_investors {
        padding: 40px 0 60px;
        .cont {
            width: 980px;
            margin: 0 auto; }
        .title {
            color: #fff;
            @include fs(24);
            line-height: 29px; }
        .top_icon {
            margin-bottom: 20px; }
        .avatar {
            @include rounded(50%); } }

    .top_investors {
        background-color: #5696F9;
        .title {
            margin-bottom: 30px; }
        .says {
            @include fs(0);
            li {
                display: inline-block;
                *display: inline;
                opacity: .6;
                transform: scale(.8);
                text-align: center;
                &.active,&.slick-center {
                    opacity: 1;
                    transform: scale(1); }
                .avatar {
                    margin-bottom: 25px;
                    display: inline-block;
                    *display: inline; } }
            .pannel {
                background-color: #FFF;
                @include rounded(10px);
                @include fs(18);
                color: #666;
                padding: 50px 20px 45px;
                min-height: 235px;
                position: relative;
                box-shadow: 0 0 8px #1361D9;
                .name {
                    border-bottom: 2px solid #5696F9;
                    margin-bottom: 20px;
                    padding-bottom: 20px; }
                .main {
                    text-align: left; }
                .triangle {
                    position: absolute;
                    display: inline-block;
                    *display: inline;
                    left: 50%;
                    top: -16px;
                    margin-left: -30px; } } }

        .slick-dots {
            li {
                width: auto;
                opacity: 1;
                button::before {
                    color: #fff;
                    font-size: 22px;
                    opacity: 0.5; }
                &.slick-active {
                    button::before {
                        opacity: 1; } } } } }

    .institutional_investors {
        .title {
            margin-bottom: 50px;
            color: #666; }
        ul {
            @include fs(0);
            margin-right: -10px;
            text-align: left; }
        .pannel {
            vertical-align: top;
            text-align: left;
            @include fs(16);
            color: #666;
            display: inline-block;
            *display: inline;
            border: 1px solid #e3e3e3;
            margin-right: 10px;
            @extend .border-box;
            width: 320px;
            height: 220px;
            margin-bottom: 30px;
            .top {
                line-height: 100px;
                padding:  0 17px 0 20px;
                border-bottom: 1px solid #f2f2f2;
                .avatar {
                    margin-top: 17px;
                    border: 3px solid #F2F2F2; }
                .name {
                    @include fs(18);
                    font-weight: bold; } }
            .bottom {
                padding: 30px 20px;
                line-height: 18px; }
            .company {
                color: #5696F9;
                margin-bottom: 20px; }
            .job {
                font-weight: bold; } }
        .last {
            text-align: center;
            padding: 68px 0 33px;
            .pancil {
                margin-bottom: 20px;
                color: #979797;
                display: inline-block;
                *display: inline;
                border: 1px dashed #979797;
                @include rounded(50%);
                padding: 22px;
                i {
                    @include fs(34); } }
            a {
                color: #666666;
                @include fs(16);
                line-height: 18px; } }
        .vc-btn {
            font-weight: normal;
            border: 1px solid #D1D1D1; } }
    .individual_investors {
        background-color: #F7FAFF;
        .title {
            margin-bottom: 13px;
            color: #666; }
        .show {
            text-align: right;
            margin-bottom: 10px;
            a {
                color: $main_blue;
                @include fs(16);
                line-height: 18px; } }
        ul {
            border-top: 1px solid #D8D8D8;
            margin-bottom: 67px; }
        li {
            padding: 30px 40px 30px 0;
            border-bottom: 1px dashed #D8D8D8;
            .left {
                width: 130px;
                float: left;
                border-right: 1px solid #D8D8D8;
                margin-right: 30px;
                img {
                    margin-bottom: 20px; }
                .name {
                    @include fs(18);
                    line-height: 20px; } }

            .right {
                text-align: left;
                @include fs(16);
                line-height: 30px;
                color: #808080;
                overflow: hidden;

                .tit {
                    display: inline-block;
                    *display: inline;
                    float: left;
                    color: #666; }
                p {
                    overflow: hidden; }
                .intro {
                    margin-bottom: 20px; } } }
        .vc-btn {
            font-weight: normal;
            background-color: transparent;
            color: #808080; } } }
.vc_model {
    padding-top: 80px;
    border-top: 1px solid #CCCCCC;
    .cont {
        width: 700px;
        margin: 0 auto;
        text-align: center; }
    .title {
        @include fs(30);
        line-height: 33px;
        margin-bottom: 30px; }
    .intro {
        color: #666666;
        @include fs(16);
        line-height: 28px;
        margin-bottom: 70px; } }
.vc_result {
    .privilege {
        margin-right: -130px;
        li {
            display: inline-block;
            *display: inline;
            width: 255px;
            text-align: left;
            margin-right: 130px;
            margin-bottom: 100px;
            .num {
                float: left;
                display: inline-block;
                *display: inline;
                width: 24px;
                line-height: 24px;
                text-align: center;
                background-color: #3AC0C3;
                color: #fff;
                @include fs(16);
                margin-right: 10px; }
            .text {
                width: 120px;
                overflow: hidden; }
            img {
                position: relative;
                top: -20px; } } }
    .options {
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 60px;
        margin-bottom: 60px;
        .next {
            margin-right: 60px;
            color: #666666;
            line-height: 46px;
            @include fs(18);
            text-decoration: underline; }
        .vc-btn {
            line-height: 46px;
            @include fs(22);
            @include rounded(4px); } }
    .qrcode {
        margin-bottom: 80px;
        .intro {
            padding: 0 100px;
            @include fs(20);
            line-height: 32px;
            margin-bottom: 30px; }
        img {
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.5); } } }

.vc_update {
    .cont {
        width: 916px; }
    .pay {
        margin-bottom: 92px;
        .top_banner, {
            line-height: 70px;
            color: #596679;
            padding: 0 30px;
            text-align: left;
            border: 1px solid #DEE1E7;
            .tit {
                margin-right: 10px;
                @include fs(18);
                display: inline-block;
                *display: inline; }
            .intro {
                @include fs(14);
                .num {
                    color: #3AC0C3;
                    @include fs(18);
                    font-weight: bold; } }
            a {
                color: $main_blue; } }
        table {
            border-left: 1px solid #DEE1E7;
            border-right: 1px solid #DEE1E7;
            width: 100%;
            .circle {
                display: inline-block;
                *display: inline;
                @include rounded(50%);
                background-color: #D8D8D8;
                width: 20px;
                height: 20px;
                line-height: 20px;
                background-color: #D8D8D8;
                color: #fff;
                text-align: center;
                @include fs(20);
                &.current {
                    background-color: #3AC0C3; }
                i {
                    @include fs(16); } }

            tr {
                line-height: 46px;
                background-color: #F7F9FA;
                &:nth-child(2n+1) {
                    background-color: #FFF; }
                text-align: center;
                @include fs(16);
                color: #666; }
            td:first-child {
                padding-left: 40px;
                text-align: left; }
            th {
                @include fs(18);
                color: #666;
                line-height: 78px;
                text-align: center; }
            th:first-child {
                text-align: left;
                padding-left: 40px; } }
        .options {
            padding: 40px;
            border: 1px solid #DEE1E7;
            border-top: 0;
            p {
                color: #596679;
                @include fs(13);
                margin-bottom: 10px; }
            .disable {
                background-color: #D8D8D8; }
            .vc-btn {
                border: 2px solid #F24964;
                @include rounded(4px);
                background-color: #FFF;
                color: #F24964;
                padding-right: 0;
                .fee {
                    background-color: #F24964;
                    color: #fff;
                    display: inline-block;
                    *display: inline;
                    padding: 0 12px;
                    margin-left: 20px; } } } } }
.vc_order {
    .order {
        text-align: left;
        margin-bottom: 60px; }
    .order-part {
        padding-bottom: 30px;
        border-bottom: 1px #F2F2F2 solid;
        margin-bottom: 30px;
        &.last {
            margin-bottom: 50px;
            border-bottom: none; }
        .item {
            @include fs(18);
            line-height: 19px;
            margin-bottom: 18px; }
        .txt {
            @include fs(16);
            line-height: 17px;
            input {
                margin-right: 7px; } }
        .value {
            @include fs(16);
            color: #666;
            line-height: 17px; }
        .weixin {
            color: #00AF42;
            margin-right: 124px; }
        .ali {
            color: #16A5E6; } }
    .option {
        .vc-btn {
            background-color: #F24964;
            padding: 0 40px;
            @include rounded(4px); } } }


.vc_model_pop {
    .qrcode {
        margin-bottom: 80px;
        .intro {
            padding: 0 100px;
            @include fs(20);
            line-height: 32px;
            margin-bottom: 30px; }
        img {
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.5); } }
    #qrcodeTable {
        margin: 60px 0 40px; }
    .bottom-msg {
        @include fs(13);
        color: #666666; }
    .options {
        margin: 30px 0 60px;
        .vc-btn {
            background-color: transparent;
            color: #FD6639;
            border: 1px solid #FD6639;
            @include rounded(40px);
            font-weight: normal; } }
    .select {
        .part {
            text-align: center;
            display: inline-block;
            *display: inline;
            width: 240px;
            padding: 40px 0 30px;
            margin: 0 50px;
            cursor: pointer;
            .circle {
                display: inline-block;
                *display: inline;
                width: 36px;
                line-height: 36px;
                color: #fff;
                @include rounded(50%);
                @include fs(20);
                margin-bottom: 31px; }
            .txt {
                @include fs(20);
                line-height: 23px; }
            &.error {
                border: 1px dashed #F24964;
                color: #F24964;
                .circle {
                    background-color: #F24964; } }
            &.success {
                border: 1px dashed #3AC0C3;
                color: #3AC0C3;
                .circle {
                    background-color: #3AC0C3;
                    @include fs(24); } } } }
    .pay_select {
        .part {
            display: inline-block;
            *display: inline;
            margin: 0 50px;
            .pannel {
                width: 240px;
                border: 1px dashed #00AF42;
                padding: 0 15px;
                line-height: 160px;
                color: #00AF42;
                @include fs(16);
                margin-bottom: 30px; }
            .ali {
                border-color: #16A5E6;
                color: #16A5E6;
                display: block; }
            p {
                @include fs(13);
                color: #797979; }
            #weixin_qrcode {
                display: inline-block;
                *display: inline;
                vertical-align: middle;
                margin-left: 10px;
                padding: 5px;
                box-shadow: 0 0 2px rgba(0,0,0,.2);
                line-height: 1em; } } } }

.vc_analysis {
    border-top: 1px solid #ccc;
    padding-top: 40px;
    .news {
        margin-bottom: 50px; }
    .right_cont {
        color: #FF6700;
        @include fs(16);
        line-height: 30px;
        float: right;
        padding-left: 10px;
        .txt {
            margin-right: 10px; }
        .gap {
            margin-right: 10px;
            display: inline-block;
            *display: inline;
            height: 10px;
            width: 1px;
            background: #D8D8D8; }
        .price {
            color: #3AC0C3; } } }

.vc_analysis_detail {
    border-top: 1px solid #ccc;
    .post-container {
        width: 100%; }
    .post-options {
        .shares {
            position: relative;
            [class^="circle"] {
                border: none; }
            .qrcode {
                width: 115px;
                padding: 20px 20px 10px;
                text-align: center;
                background-color: #FFF;
                border: 1px solid #EFEFEF;
                display: none;
                position: absolute;
                top: -72px;
                left: 180px;
                z-index: 2;
                .triangle {
                    position: absolute;
                    display: inline-block;
                    *display: inline;
                    border: 15px solid transparent;
                    border-right-color: #EFEFEF;
                    left: -30px;
                    top: 50%;
                    margin-top: -20px;
                    span {
                        position: absolute;
                        display: inline-block;
                        *display: inline;
                        border: 14px solid transparent;
                        border-right-color: #fff;
                        left: -12px;
                        top: -14px; } }
                .img {
                    text-align: center;
                    margin-bottom: 15px;
                    img {
                        display: inline-block;
                        *display: inline !important; } }
                .tit {
                    color: #666666;
                    @include fs(14);
                    line-height: 20px; } } }
        .is_help {
            margin-bottom: 50px;
            .vc-btn {
                color: #EE6E50;
                border-color: #EE6E50;
                font-weight: normal;
                &.current {
                    background-color: #EE6E50;
                    color: #fff; } } } }
    article,.post-options,.relevance,.evaluate {
        width: 700px;
        margin:  0 auto; }
    article {
        margin-bottom: 60px; }
    .try_end {
        text-align: center;
        width: 100%;
        background-color: #F7FAFF;
        margin-bottom: 30px;
        .cont {
            padding: 60px 0 40px;
            width: 980px;
            margin: 0 auto; }
        .hint {
            @include fs(20);
            line-height: 29px;
            margin-bottom: 40px; }
        .pannel {
            border: 1px #3AC0C3 dashed;
            display: inline-block;
            *display: inline;
            padding: 50px 73px 40px;
            margin-bottom: 60px;
            .money {
                color: #3AC0C3;
                @include fs(30);
                line-height: 30px;
                margin-bottom: 20px; }
            .txt {
                @include fs(18);
                line-height: 23px;
                color: #808080; } }
        .buy {
            margin-bottom: 60px; }
        .remind {
            padding-top: 40px;
            border-top: 1px dashed #D8D8D8;
            @include fs(20);
            span {
                color: #F24964; }
            .vc-btn {
                line-height: 42px; } } }
    .shares {
        margin-bottom: 37px; }
    .relevance {
        .title {
            @include fs(20);
            line-height: 28px;
            margin-bottom: 20px; }
        .product_pannel {
            border: 1px solid #E8EEF7;
            margin-bottom: 60px;
            .top {
                padding: 30px;
                border-bottom: 1px solid #E8EEF7; }
            .logo {
                margin-right: 20px; }
            .info {
                overflow: hidden; }
            .title {
                @include fs(18);
                margin-bottom: 17px;
                a {
                    color: #666; } }
            .intro {
                @include fs(13);
                line-height: 18px;
                color: #808080; }
            .data {
                ul {
                    padding: 9px 0px; }
                li {
                    display: inline-block;
                    *display: inline;
                    padding: 6px 15px;
                    border-right: 1px solid #E8EEF7;
                    width: 82px;
                    &:last-child {
                        border-right: 0; } }
                .item {
                    color: #576B9D;
                    @include fs(13);
                    line-height: 15px;
                    margin-bottom: 13px; }
                .value {
                    @include fs(16);
                    line-height: 21px;
                    color: #666;
                    white-space: nowrap; } } } }
    .evaluate {
        color: #9EA9B2;
        margin-bottom: 60px;
        .title {
            padding: 0 0 20px 10px;
            @include fs(18);
            line-height: 19px;
            border-bottom: 1px #E1E8EE solid; }
        table {
            color: #9EA9B2;
            @include fs(16);
            width: 100%;
            td {
                padding: 10px; }
            tr:nth-child(even) {
                background-color: #F3F6F9; }
            .value,.gap {
                color: #808080; }
            .stars {
                vertical-align: top;
                position: relative;
                width: 100px;
                display: inline-block;
                *display: inline;
                margin-right: 10px;
                .star_bg,.star {
                    position: absolute;
                    display: inline-block;
                    *display: inline;
                    width: 100px;
                    max-width: 100px;
                    white-space: nowrap;
                    font-size: 0;
                    text-align: center;
                    i {
                        color: #4FBC7C;
                        font-size: 16px;
                        display: inline-block;
                        *display: inline;
                        width: 20px;
                        line-height: 22px; } }
                .star {
                    z-index: 1;
                    width: 0;
                    overflow: hidden; } } } } }

.vc_report_detail {
    width: 980px;
    margin: 0 auto;
    padding-top: 50px;
    border-top: 1px solid #ccc;
    .report_banner {
        border: 1px solid #E8E8E8;
        max-height: 300px;
        color: #666;
        margin-bottom: 60px;
        .left {
            margin-right: 30px; }
        .right {
            overflow: hidden;
            padding: 30px 110px 0 0;
            .qrcode {
                width: 115px;
                padding: 20px 20px 10px;
                text-align: center;
                background-color: #FFF;
                border: 1px solid #EFEFEF;
                display: none;
                position: relative;
                top: -125px;
                left: 160px;
                .triangle {
                    position: absolute;
                    display: inline-block;
                    *display: inline;
                    border: 15px solid transparent;
                    border-right-color: #EFEFEF;
                    left: -30px;
                    top: 50%;
                    margin-top: -20px;
                    span {
                        position: absolute;
                        display: inline-block;
                        *display: inline;
                        border: 14px solid transparent;
                        border-right-color: #fff;
                        left: -12px;
                        top: -14px; } }
                .img {
                    text-align: center;
                    margin-bottom: 15px;
                    img {
                        display: inline-block;
                        *display: inline !important; } }
                .tit {
                    color: #666666;
                    @include fs(14);
                    line-height: 20px; } } }
        .title {
            @include fs(20);
            line-height: 25px;
            margin-bottom: 20px; }
        .buy_pannel {
            width: 440px;
            background-color: #F7FAFF;
            padding: 25px 20px 20px;
            margin-bottom: 30px;
            .money {
                color: #3AC0C3;
                margin-bottom: 25;
                @include fs(36);
                margin-bottom: 25px;
                .sign {
                    @include fs(20); } }
            .privilege {
                @include fs(16);
                line-height: 25px;
                .money {
                    color: #F24964;
                    @include fs(16); }
                .upgrade {
                    color: #666;
                    margin-left: 20px;
                    text-decoration: underline; } } }
        .vc-btn {
            padding: 0 36px; }
        .intro {
            margin-left: 30px; } }
    .report_cont {
        .title {
            @include fs(20);
            line-height: 28px;
            display: inline-block;
            *display: inline;
            padding-bottom: 10px;
            border-bottom: 2px solid #EE6E50;
            position: relative;
            bottom: -1px; }
        .intros {
            border-top: 1px solid #E8E8E8;
            padding-top: 40px;
            line-height: 22px;
            color: #666666;
            @include fs(16);
            margin-bottom: 80px;
            p {
                min-height: 22px; }
            .tit {
                margin-bottom: 22px;
                font-weight: bold; } } } }









