.clear {
    &::before,
    &::after {
        content: '';
        display: table; }
    &::after {
        clear: both; } }

.center-block {
    display: block;
    margin: 0 auto; }

.color-orange {
    color: $orange;
    &:hover {
        color: $orange_hover; } }

.color-unclickable {
    color: $unclickable; }
.border-box {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }

@keyframes audio_loading {
    0% {
        transform: rotate(0deg); }
    50% {
        transform: rotate(180deg); }
    100% {
      transform: rotate(360deg); } }
@-webkit-keyframes audio_loading {
    0% {
        transform: rotate(0deg); }
    50% {
        transform: rotate(180deg); }
    100% {
      transform: rotate(360deg); } }
@-moz-keyframes audio_loading {
    0% {
        transform: rotate(0deg); }
    50% {
        transform: rotate(180deg); }
    100% {
      transform: rotate(360deg); } }
@-webkit-keyframes audio_loading {
    0% {
        transform: rotate(0deg); }
    50% {
        transform: rotate(180deg); }
    100% {
      transform: rotate(360deg); } }
