.tmtpass {
    min-height: 900px;
    height: 100vh;
    background-image: url("/public/css/v1_imgs/pass_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    * {
        box-sizing: border-box; }
    a.link,.link {
        color: $tmt_color_red;
        cursor: pointer; }
    .pannel {
        // position: absolute
        // top: 50%
        // left: 50%
        width: 1100px;
        height: 530px;
        // margin-top: -237px
        // margin-left: -550px
        font-size: 0;
        [class^='pass_'] {
            @include fs(14);
            color: #B3B3B3;
            height: 100%; }
        .pass_logo {
            background-color: $tmt_color_red;
            text-align: center;
            width: 420px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 195px;
                margin-top: -40px; } } }



    .pass_form {
        position: relative;
        top: 0;
        width: 680px;
        background-color: #FFF;
        // overflow: hidden
        form {
            padding: 40px 120px 40px 60px;
            height: 100%; }
        input[type="text"],input[type="password"] {
            border: none;
            padding: 0 5px 5px;
            line-height: 50px;
            border-bottom: 1px solid #D8D8D8;
            width: 500px;
            @include fs(15);
            color: #333333;
            &.middle {
                width: 363px;
                margin-right: 25px; }
            &.s_middle {
                width: 330px;
                margin-right: 25px; }
            &.small {
                width: 150px;
                margin-right: 20px; } }
        .bank {
            img {
                border: 1px solid transparent;
                display: inline-block;
                &.current {
                    border-color: $main; } } }
        .btn_v1 {
            text-align: center;
            background-color: #FFF;
            border: 1px solid $tmt_color_red;
            color: $tmt_color_red;
            line-height: 40px;
            padding: 0 20px;
            @include fs(16);
            vertical-align: bottom;
            display: inline-block;
            // border-radius: 80px
            &.blue {
                background-color: $tmt_color_red;
                color: #fff; }
            &.large {
                line-height: 43px;
                padding: 0 62px;
                @include fs(18); }
            &.banner {
                width: 100%;
                padding-left: 0;
                padding-right: 0; }
            &.disable {
                border-color: #d8d8d8;
                color: #d8d8d8; } }
        .form_part {
            margin-bottom: 40px;
            overflow: visible;
            white-space: nowrap;
            input[type='checkbox'] {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                margin-right: 8px;
                visibility: hidden; }


            input[type="checkbox"]::after {
                content: "";
                width: 14px;
                height: 14px;
                color: #fff;
                visibility: visible;
                background: url(/public/img/common/icon_check.png) no-repeat;
                background-size: 100% 100%;
                display: block; }


            input[type="checkbox"]:checked::after {
                display: block;
                content: "";
                width: 14px;
                height: 14px;
                cursor: pointer;
                background: url(/public/img/common/icon_checked.png) no-repeat;
                background-size: 100% 100%; }
            i {
                cursor: pointer;
                &:hover {
                    color: #333; } }
            &.mobile_part {
                display: flex;
                justify-content: space-between;
                &.hide {
                    display: none; }
                input.mobile {
                    width: 85%;
                    padding-left: 15px; }
                .sel_area,.sel_areaa {
                    display: inline-block;
                    line-height: 50px;
                    color: #757575;
                    border-bottom: 1px solid #D8D8D8;
                    @include fs(18);
                    position: relative;
                    cursor: pointer;
                    text-align: center;
                    width: 15%;
                    &.hide {
                        display: none; }
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 25px;
                        background-color: #D8D8D8;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -13px; }
                    i {
                        vertical-align: text-bottom; } } } }


        .form_last {
            margin-bottom: 15px; }
        .form_first {
            margin-bottom: 15px; }
        .agree {
            line-height: 20px;
            margin-bottom: 15px;
            margin-top: 15px;
            color: #B3B3B3;
            @include fs(16);
            text-align: center;
            .agreement {
                color: $tmt_color_red;
                margin-left: 5px;
                text-decoration: underline; } }
        .get_more {
            margin-top: -20px;
            @include fs(16);
            color: #b3b3b3;
            line-height: 25px;

            .remember {
                margin-right: 8px;
                visibility: hidden; }


            label {
                color: #909090; }
            .forget {
                color: #B3B3B3; } }
        .login-tip {
            display: none; }
        .title {
            @include fs(24);
            color: #333;
            line-height: 24px;
            margin-bottom: 40px;
            .or {
                color: #808080;
                @include fs(13);
                margin: 0 20px; }
            .link {
                @include fs(13);
                cursor: pointer;
                text-decoration: underline; }
            span {
                display: inline-block; } }
        .intro {
            @include fs(13);
            line-height: 21px;
            margin-bottom: 20px; }
        .option {
            button {
                margin-right: 20px;
                position: absolute;
                bottom: 50px; }
            .agree {
                @include fs(13);
                text-align: left; }
            a {
                margin: 0 5px; }
            .hint {
                position: absolute;
                bottom: 50px;
                right: 0;
                right: 120px;
                line-height: 45px;
                display: inline-block;
                a {
                    margin: 0;
                    text-decoration: underline; } } }

        .form_pop {
            position: absolute;
            width: 500px;
            height: 403px;
            top: 50%;
            left: 50%;
            margin-left: -250px;
            margin-top: -201px;
            padding: 50px 99px;
            background-color: #FFF;
            box-shadow: 0 0 50px 0 rgba(0,0,0,.3);
            .form_msg {
                top: 50px; }
            .tit {
                padding-top: 87px;
                color: #666;
                @include fs(24);
                line-height: 28px;
                margin-bottom: 53px; }
            .form_part {
                margin-bottom: 50px; }
            .close-btn {
                position: absolute;
                top: 40px;
                right: 40px;
                color: #A0A0A0;
                @include fs(23);
                cursor: pointer;
                transform: translate(0,0) translateZ(0);
                -webkit-transform: translate(0,0) translateZ(0); } }
        .form_result {
            display: table;
            // display: none
            padding: 0 50px;
            background-color: #FFF;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            .cell {
                display: table-cell;
                text-align: center;
                vertical-align: middle; }
            .title {
                margin-bottom: 40px; }
            .result_cont {
                margin-bottom: 50px;
                p {
                    line-height: 24px;
                    color: #666; } }
            .remind {
                margin-top: 50px;
                @include fs(13); } }

        .captcha-box {
            margin-top: -13px;
            .img-box {
                margin-top: -12px;
                margin-right: 12px;
                display: inline-block;
                left: 0;
                top: 0;
                img {
                    width: 105px;
                    height: 42px; } } }
        .phone_code_box {
            margin-top: -13px; }
        .hide {
            display: none; }
        .third_party {
            .sns-btn {
                display: inline-block;
                width: 40px;
                height: 40px;
                color: #fff;
                border-radius: 50%;
                line-height: 40px;
                text-align: center;
                margin-left: 20px;
                margin-right: 0;
                font-size: 18px;
                &.sina-weibo {
                    background-color: #F56467; }
                &.wechat {
                    background-color: #01C801;
                    margin-left: 30px; } } }
        .third_user_info {
            position: relative;
            padding-bottom: 20px;
            border-bottom: 1px solid #D8D8D8;
            overflow: hidden;
            .img-box {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%; } }
            .nickname {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                // margin-top: -20px
                margin: 0;
                line-height: 16px;
                @include fs(14);
                color: #333333;
                span {
                    line-height: 16px;
                    @include fs(12);
                    color: #2A2A2A; } } } }

    .form_msg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        text-align: center;
        ul {
            list-style-type: none; }
        li,p {
            max-width: 70%;
            display: inline-block;
            padding: 12px 20px 10px;
            color: #594042;
            @include fs(13);
            line-height: 1em;
            background-color: #FFF2F2;
            border: 1px solid #FFDBDB; } }
    .bind_form {
        .form_tabs {
            border-bottom: 1px solid #D8D8D8;
            margin-bottom: 20px;
            .title {
                color: #666;
                margin-bottom: -1px;
                padding-bottom: 10px;
                display: inline-block;
                cursor: pointer;
                &.current {
                    cursor: default;
                    border-bottom: 1px solid $tmt_color_red;
                    color: $tmt_color_red; } }
            .fr {
                margin-top: -1px; } }
        .third_info {
            position: relative;
            margin-top: 80px;
            .third_avatar {
                border-radius: 50%;
                position: absolute;
                left: 0;
                bottom: 5px; }
            input {
                text-align: right; } } }
    .dropdown-menu {
        padding: 10px 0;
        top: 14px;
        left: -94px;
        &:before {
            right: calc(50% - -32px);
            right: -webkit-calc(50% - -32px);
            right: -moz-calc(50% - -32px); }
        .areas {
            max-height: 350px;
            overflow: scroll;
            li {
                display: block;
                width: 198px;
                padding: 0 19px;
                line-height: 40px;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:hover {
                    background-color: #F5F5F5; } } } }
    .select_type {
        text-align: center;
        margin-bottom: 50px;
        .tab {
            background-color: #F6F6F6;
            line-height: 38px;
            display: inline-block;
            .s_type {
                display: inline-block;
                width: 128px;
                color: #757575;
                @include fs(14);
                position: relative;
                cursor: pointer;
                &.current {
                    color: $tmt_color_red; }
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -7px;
                    display: inline-block;
                    width: 1px;
                    height: 14px;
                    background-color: #D8D8D8; }
                &:last-child:after {
                    display: none; } } } }
    .third_party {
        margin-top: -20px; }
    .bottom20 {
        margin-bottom: 20px !important; } }
#wechat_qr {
    background: #fff;
    border: 1px solid rgba(26, 53, 71, 0.12);
    border-radius: 4px;
    box-shadow: 0 -1px 2px rgba(26, 53, 71, 0.1);
    width: 200px;
    height: 260px;
    text-align: center;
    position: absolute;
    top: -264px;
    right: 40px;
    transition: 0.4s cubic-bezier(0.3, 0, 0, 1.3);
    transform: scale(0.8) translateY(10%);
    opacity: 0;
    z-index: -1;
    &.visible {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: alpha(opacity=100);
        zoom: 1;
        pointer-events: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        z-index: 10 !important; }
    &:after {
        content: "";
        position: absolute;
        bottom: -7px;
        right: calc(50% - 7px);
        right: -moz-calc(50% - 7px);
        width: 13px;
        height: 7px;
        z-index: 4;
        background: url(/public/css/img/dropdown-menu-arrow.svg);
        transform: rotate(180deg); }
    iframe {
        width: 100%;
        height: 252px;
        overflow: hidden; } }






//移动版样式，覆盖原有样式
@media (max-width: 720px) {
    .find_password {
        .tmtpass {
            .pass_form {
                input[type="text"] {
                    line-height: 26px;
                    border-radius: 0; } } } }
    .tmtpass {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-color: #FFF;
        background-image: none;
        .btn_v1 {
            border-right: 80px; }
        .pannel {
            position: relative;
            width: 100%;
            height: auto;
            top: 0 !important;
            left: 0;
            margin-left: 0;
            margin-top: 0px;
            // transform: translateY(-50%)
            padding-top: 64px;
            .pass_logo {
                display: none; } }
        .form_msg {
            top: 64px;
            position: fixed; }
        .pass_form {
            position: static;
            width: 100%;
            .btn_v1 {
                border-radius: 100px; }
            .bank {
                display: none; }
            .form_part {
                &.mobile_part {
                    input.mobile {
                        width: 75%; }
                    .sel_areaa {
                        width: 25%; } } }
            .form_part:first-child {
                margin-bottom: 40px; }
            .form_result {
                padding: 50px 4%; }
            .login-tip {
                display: block;
                width: 100%;
                margin-bottom: 40px;
                line-height: 32px;
                border: 1px solid $tmt_color_red;
                text-align: center;
                @include fs(13);
                color: #808080;
                a {
                    text-decoration: underline;
                    color: #21B890; } }
            .title {
                text-align: center;
                // color: $main
                @include fs(26);
                @include fs(16); }
            .intro {
                text-align: center; }
            .option {
                position: static;
                padding: 0;
                margin-top: 45px;
                .another {
                    @include fs(16);
                    line-height: 23px;
                    text-align: center;
                    margin: 30px auto;
                    position: relative;
                    .line-left,.line-right {
                        width: 75px;
                        display: inline-block;
                        border-bottom: 1px solid #b3b3b3;
                        position: absolute;
                        top: 11px;
 }                        // margin-bottom: 5px
                    .line-left {
                        left: 0; }
                    .line-right {
                        right: 0; } } }
            form {
                padding: 0 6% 50px; }
            input[type="text"],input[type="password"] {
                width: 100%;
                &.middle {
                    width: 50%;
                    margin-right: 25px; }
                &.s_middle {
                    width: 50%;
                    margin-right: 25px; }
                &.small {
                    width: 35%;
                    margin-right: 20px; } }
            .help_result {
                position: absolute;
                top: 50%;
                // transform: translateY(-50%)
                background-color: #FFF;
                padding: 50px 4%;
                height: auto; }
            .third_party .sns-btn.wechat {
                margin-left: 30px !important;
                display: none; }
            .form_pop {
                width: 95%;
                margin-top: 0;
                margin-left: 0;
                transform: translate(-50%,-50%);
                padding: 25px 4%;
                .close-btn {
                    top: 10px;
                    right: 10px; } }
            .third_party {
                float: none;
                margin-bottom: 0px;
                display: block;
                text-align: center;
                .sns-btn {
                    display: inline-block;
                    // width: 100%
                    height: 45px;
                    margin: 0 20px;
                    border: 2px solid #F56467;
                    border-radius: initial;
                    line-height: 45px;
                    width: 45px;
                    color: #F56467;
                    border-radius: 50%;
                    i {
                        @include fs(20);
 }                        // margin-right: 15px
                    &.sina-weibo {
                        background-color: #FFF;
                        margin-bottom: 15px; }
                    &.sns-weixin {
                        background-color: #FFF;
                        color: #01C801;
                        border: 1px solid #01C801; }
                    .hide {
                        // display: inline-block
                        font-size: 18px; } } }
            .option {
                // margin-top: 75px
                .btn_v1 {
                    margin-bottom: 15px;
                    width: 100%;
                    margin-right: 0px;
                    border-radius: 80px; }

                .btn_v1.large {
                    @include fs(21);
                    line-height: 59px;
                    border-radius: 100px; }
                button {
                    position: static; }
                .agree {
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap; }
                .hint {
                    display: block;
                    float: none;
                    margin-top: 15px;
                    text-align: center;
                    position: relative;
                    right: auto;
                    bottom: auto;
                    .hint_left,.hint_right {
                        position: absolute;
                        line-height: 23px;
                        @include fs(16); }
                    .hint_left {
                        left: 0;
                        a {
                            text-decoration: underline; } }
                    .hint_right {
                        right: 0;
                        a {
                            color: #b3b3b3; } } } }
            .dropdown-menu {
                left: -70px; } }
        .bind_form .form_tabs .title {
            @include fs(16); } } }






//by hopekayo
.reminder-cont {
    text-align: center !important;
    min-width: 280px;

    .btn {
        margin: 0; }
    // .close-btn
    //     position: absolute
    //     right: 10px
    //     color: #b3b3b3
    //     @include fs(14)

    .txt {
        @include fs(24);
        margin-bottom: 40px; }
    .msg-txt {
        @include fs(14);
        color: $gray;
        margin-bottom: 35px; }
    .error_msg {
        .msg {
            margin-bottom: 30px; } }
    .captcha-input {
        width: 50%; }
    .input_gray {
        width: 60%; }
    .bottom-line {
        border-bottom: 1px #D8D8D8 solid;
        padding-bottom: 15px; }
    .input-part {
        margin-bottom: 50px;
        overflow: hidden;
        .btn {
            margin: 5px; } }
    p.end-msg {
        @include fs(13);
        color: #B3B3B3;
        margin-top: 30px; }
    .avatar {
        margin-bottom: 40px;
        img {
            @include rounded(50%); } }
    .help-part {
        margin-top: 45px;
        text-align: left; }

    .btn-right {
        position: relative;
        right: 12%;
        *display: none;
        opacity: 0;
        &.show {
            right: 0;
            opacity: 1;
            *display: inline-block; } }

    .btn-left {
        position: relative;
        left: 12%;
        *display: none;
        opacity: 0;
        &.show {
            left: 0;
            opacity: 1;
            *display: inline-block; } }
    .v_active {
        position: relative;
        z-index: 3;
        height: 38px; }

    .text-black {
        color: black; } }




