.app-down-container {
    position: relative;
    z-index: 11;
    .section {
        text-align: center;
        position: relative;
        height: 100%;
        background-color: #F9F9F9;
        background-size: 100%;
        background-repeat: no-repeat;
        .cont {
            width: 840px;
            margin: 0 auto;
            // padding: 0 300px
            overflow: hidden; }
        .left,.right {
            display: inline-block;
            *display: inline; }
        .left {
            float: left;
 }            // margin-right: 100px
        .right {
            float: right; }
        .intro-text {
            // width: 362px
            margin-top: 100px;
            text-align: left;
            .top {
                margin-bottom: 28px; }
            h3 {
                display: block;
                *display: inline;
                margin-right: 25px;
                margin-bottom: 10px;
                line-height: 44px;
                // @include fs(36)
                font-size: 36px;
                color: #333333;
                font-weight: bold; }
            .tit-img {
                display: block;
                margin-bottom: 20px; }
            .tit-bar {
                display: block;
                width: 60px;
                height: 6px; }
            .list {
                padding-left: 20px;
                li {
                    list-style: disc;
                    // @include fs(18)
                    font-size: 18px;
                    color: #6C6C6C;
                    line-height: 30px;
                    font-weight: bold; } }
            img {
                vertical-align: top; }
            .circle {
                display: inline-block;
                *display: inline;
                width: 48px;
                height: 48px;
                text-align: center;
                line-height: 50px;
                vertical-align: top;
                background-color: #FFF;
                @include rounded(50%);
                border: 1px solid #E5E5E5;
                color: #A0A5A9;
                @include fs(20); }
            .intro {
                color: #666666;
                @include fs(18);
                line-height: 28px; } }
        .app-img {
            img {
                width: 285px;
                height: auto; } }
        .bottom {
            position: absolute;
            bottom: 0;
            left: 50%;
            // transform: translateX(-50%)
            @include transformX(-50%); }
        .icons {
            margin-bottom: 60px;
            a {
                margin: 0 10px;
                color: $tmt_color_red;
                opacity: 0.5; }
            i {
                @include fs(24); } } }

    .bg-whiter {
        background-color: #fff; }
    .page-1 {
        position: relative;
        background-color: #FFFFFF;
        padding-top: 50px;
        .cont {
            width: 1160px; }
        .apps-tit {
            img {
                width: 535px;
                height: 106px; } }
        .back-btn {
            position: absolute;
            top: 40px;
            right: 60px;
            width: 120px;
            height: 32px;
            border: 1px solid $tmt_color_red;
            border-radius: 100px;
            a {
                display: inline-block;
                line-height: 33px;
                @include fs(16);
                color: $tmt_color_red;
                font-weight: bold; } }
        .side-left {
            position: absolute;
            top: 50%;
            left: 100px;
            transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            text-align: center;
            z-index: 13;
            .apps_logo {
                width: 113px;
                height: 113px;
                margin-bottom: 30px; }
            .intro {
                margin-bottom: 10px;
                @include fs(24);
                color: #333333;
                font-weight: bold;
                span {
                    color: #00A4A1; } }
            .noway {
                width: 141px;
 } }                // height: 31px
        .exhibition-72, .exhibition-home {
            display: inline-block;
            position: absolute;
            .list {
                position: absolute;
                background-color: #FFFFFF;
                box-shadow: 0 0 6px 0 rgba(2,164,160,0.60);
                border-radius: 4px;
                padding: 15px 40px;
                li {
                    position: relative;
                    margin-bottom: 12px;
                    @include fs(16);
                    color: #333333;
                    text-align: left;
                    white-space: nowrap;
                    font-weight: bold;
                    &:last-of-type {
                        margin-bottom: 0; }
                    .dot {
                        position: absolute;
                        top: 50%;
                        left: -14px;
                        transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        margin-right: 10px;
                        background-color: $tmt_color_red;
                        border-radius: 50%;
                        vertical-align: middle; }
                    span {
                        color: #00A4A1; } } } }
        .exhibition-72 {
            display: inline-block;
            position: absolute;
            top: 245px;
            left: 418px;
            img {
                width: 378px;
                height: 482px; }
            .list {
                left: -13px;
                bottom: 135px;
                box-shadow: 0 0 6px 0 rgba(2,164,160,0.60); } }
        .exhibition-home {
            display: inline-block;
            position: absolute;
            top: 122px;
            right: 266px;
            img {
                width: 327px;
                height: 558px; }
            .list {
                top: 133px;
                left: 165px;
                box-shadow: 0 0 6px 0 rgba(0,164,161,0.60); } }
        .question {
            position: absolute;
            left: 60px;
            bottom: 85px;
            img {
                width: 133px;
                height: 96px; } }
        .version-box {
            font-size: 0;
            position: absolute;
            right: 60px;
            bottom: 90px;
            .iphone, .android {
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;
                img {
                    height: 40px; } }
            .download-qrcode {
                display: inline-block;
                background-color: #FFFFFF;
                vertical-align: top;
                cursor: pointer;
                img {
                    height: 40px; } } }

        .fp-tableCell {
            vertical-align: top; }
        h2 {
            color: #FFFFFF;
            @include fs(60);
            line-height: 62px;
            margin-bottom: 20px; }
        h3 {
            color: #fff;
            @include fs(24);
            line-height: 25px;
            margin-bottom: 35px; }
        .buttons {
            margin-bottom: 50px;
            .btn {
                //margin: 0 15px
                margin: 15px 0;
                color: #fff;
                @include fs(18);
                border-color: #fff;
                display: block;
                i {
                    margin-right: 10px; }
                &:hover {
                    background-color: #fff;
                    color: #21B890; } }
            .icon-iphone,.icon-ipad {
                position: relative;
                top: 1px; } }

        .bottom {
            position: static;
            transform: translateX(0);
            img {
                margin: 0 auto;
                width: 540px;
                height: auto;
                margin-bottom: -80%; } }
        .qrcode {
            display: inline-block;
            margin-bottom: 20px;
            margin-left: 15px;
            margin-right: 15px;
            *display: inline;
            width: 130px;
            padding: 20px;
            background-color: #FFF;
            @include rounded(19px);
            h4 {
                @include fs(18);
                line-height: 19px;
                margin-bottom: 11px; } } }
    .page-2 {
        background-image: url('../css/img/app_down/apps_bg1.png'); }
    .page-3 {
        background-image: url('../css/img/app_down/apps_bg2.png'); }
    .page-4 {
        background-image: url('../css/img/app_down/apps_bg3.png'); }
    .page-5 {
        background-image: url('../css/img/app_down/apps_bg4.png'); } }

@keyframes bg_zoom {

    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }
@-webkit-keyframes bg_zoom {

    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }
@-moz-keyframes bg_zoom {
    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }
@-o-keyframes bg_zoom {

    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }


@keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
@-webkit-keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
@-moz-keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
@-o-keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
// .m-app-down
//     background-color: #FFFFFF
//     max-width: 750px
//     margin: 0 auto
//     position: relative
//     overflow: hidden
//     // height: 100%
//     .bg_img
//         width: 100%
//         height: 100%
//         height: 100vh
//         top: 0
//         left: 0
//         position: absolute
//         background-image: url("/public/css/img/app_down/h5-bg.jpg")
//         background-position: center
//         background-size: cover
//         z-index: 1
//         filter : blur(0px)
//         transform: scale(1.9)
//         animation: bg_zoom 15s linear

//     .bg_img1
//         width: 100%
//         height: 100%
//         height: 100vh
//         top: 0
//         left: 0
//         position: absolute
//         background-image: url("/public/css/img/app_down/h5-bg1.jpg")
//         background-position: center
//         background-size: cover
//         z-index: 1

//         // background-size: 150%
//         animation: shift_in 20s linear infinite

//     .page
//         position: relative
//         z-index: 2
//         width: 100%
//         height: 100%
//         height: 100vh
//         text-align: center
//         color: #fff
//         display: table
//         a
//             color: #fff
//     .cont
//         display: table-cell
//         vertical-align: middle
//     img.logo
//         margin-bottom: 30px
//         text-align: center
//     h2
//         @include fs(25)
//         line-height: 25px
//         letter-spacing: 8px
//         color: #fff
//         font-weight: bold
//         text-shadow: 0 2px 0 rgba(0,0,0,.5)
//         margin-bottom: 17px
//     h3
//         @include fs(12)
//         line-height: 15px
//         margin-bottom: 50px
//         &:before,&:after
//             content: ''
//             display: inline-block
//             height: 1px
//             width: 10px
//             background-color: #FFF
//             vertical-align: middle
//             margin: 0 10px
//     .btn
//         display: block
//         margin: 0 auto
//         margin-bottom: 20px
//         font-weight: bold



.tooltipster-content {
    width: 290px !important;
    box-shadow: 0 0 3px 0 rgba(0,164,161,0.40);
    display: flex;
    justify-content: space-around;
 }    // .qrcode_tmt
@media (max-width: 768px) {
    .m-app-down {
        background-color: #FFFFFF;
        max-width: 750px;
        margin: 0 auto;
        .section {
            position: relative;
            height: 100vh;
            background-size: 100%;
            background-repeat: no-repeat;
            .download-btn {
                margin-top: 39px;
                padding: 7px 25px;
                background: rgba(0,164,161,0.70);
                box-shadow: 0 0 4px 0 #70F3D3;
                border-radius: 2px;
                border: none;
                @include fs(13);
                color: #FFFFFF;
                font-weight: bold; }
            .apps-tit {
                padding-top: 38px;
                text-align: center; }
            .exhibition-home, .exhibition-72 {
                position: relative;
                .list {
                    position: absolute;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 3px 0 rgba(2,164,160,0.60);
                    border-radius: 2px;
                    padding: 10px 30px;
                    z-index: 2;
                    li {
                        position: relative;
                        margin-bottom: 5px;
                        text-align: left;
                        white-space: nowrap;
                        @include fs(10);
                        color: #333333;
                        &:last-of-type {
                            margin-bottom: 0; }
                        .dot {
                            position: absolute;
                            top: 50%;
                            left: -14px;
                            transform: translateY(-50%);
                            -o-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            -moz-transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            display: inline-block;
                            width: 4px;
                            height: 4px;
                            margin-right: 10px;
                            background-color: $tmt_color_red;
                            border-radius: 50%;
                            vertical-align: middle; }
                        span {
                            color: #00A4A1; } } } }

            .exhibition-home {
                margin-top: 3vh;
                padding-left: 15px;
                z-index: 2;
                .list {
                    top: 62px;
                    left: 126px; } }
            .exhibition-72 {
                margin-top: -121px;
                padding-right: 15px;
                text-align: right;
                .list {
                    bottom: 51px;
                    right: 113px; } }
            // .next-arrow
            //     position: absolute
            //     bottom: 20px
            //     left: 50%
            //     transform: translateX(-50%)
            //     img
            //         width: 18px
            //         height: 15px
            .arrow {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                -o-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                width: 18px;
                height: 15px;
                background-image: url('../css/img/app_down/arrow_down_m.png');
                background-size: 100%;
                background-repeat: no-repeat;
                border: none;
 }                // margin-left: 0

            .classify-intro {
                text-align: center; }
            .app-img {
                position: relative;
                margin-top: 30px;
                text-align: center;
                .list {
                    position: absolute;
                    bottom: -17px;
                    right: 20px;
                    padding: 8px 15px;
                    background: rgba(0,164,161,0.70);
                    box-shadow: 0 0 2px 0 #70F3D3;
                    border-radius: 2px;
                    li {
                        position: relative;
                        @include fs(8);
                        color: #FFFFFF;
                        .download-icon {
                            margin-right: 10px;
                            width: 11px;
                            height: 10px;
                            -webkit-tap-highlight-color: rgba(255,255,255,0);
                            animation: fadeInUp 1.5s .2s ease infinite;
                            -webkit-animation: fadeInUp 1.5s .2s infinite; }
                        .underline {
                            position: absolute;
                            left: 0;
                            bottom: -2px;
                            display: inline-block;
                            width: 11px;
                            height: 1px;
                            background-color: #FFFFFF; } } } }

            .tmt-info {
                padding-top: 88px;
                // padding-top: 150px
                text-align: center;
                img {
                    display: block;
                    margin: 0 auto; }
                .tmt-logo {
                    width: 120px;
                    height: 121px; }
                .tmt-txt {
                    margin-top: 15px;
                    width: 89px;
                    height: 25px; }
                .txt-72ways {
                    margin-top: 30px;
                    @include fs(22);
                    color: #333333;
                    font-weight: bold;
                    span {
                        color: #00A4A1; } }
                .noway {
                    width: 122px;
                    height: 20px;
                    margin-top: 2px; }

                .txt-intro {
                    margin-top: 40px;
                    line-height: 19px;
                    @include fs(10);
                    color: #606060;
                    letter-spacing: 1px; } }

            .footer {
                position: absolute;
                bottom: 30px;
                width: 100%;
                .list {
                    text-align: center;
                    li {
                        display: inline-block;
                        padding: 0 25px;
                        border-right: 1px solid $tmt_color_red;
                        &:last-of-type {
                            border-right: none; }
                        a {
                            @include fs(12);
                            color: $tmt_color_red;
                            font-weight: bold; } } }
                .info {
                    margin-top: 2vh;
                    text-align: center;
                    @include fs(10);
                    color: #808080; } } }

        .page-1 {
            background-image: url('../css/img/app_down/apps_bg1_m.png'); }
        .page-2 {
            background-image: url('../css/img/app_down/apps_bg2_m.png');
            // .app-img
            //     .list
            //         top: 200px
            //         // left: 166px
            //         right: 40px
            .classify-intro {
                padding-top: 30px; }
            .app-img {
                margin-top: 30px; } }
        .page-3 {
            background-image: url('../css/img/app_down/apps_bg3_m.png');
            // .app-img
            //     .list
            //         top: 86px
            //         // left: 193px
            //         right: 40px
            .classify-intro {
                padding-top: 40px; }
            .app-img {
                margin-top: 36px; } }
        .page-4 {
            background-image: url('../css/img/app_down/apps_bg4_m.png');
            // .app-img
            //     .list
            //         top: 297px
            //         // left: -45px
            //         left: 30px
            .classify-intro {
                padding-top: 30px; }
            .app-img {
                margin-top: 30px; } }
        .page-5 {
            background-image: url('../css/img/app_down/apps_bg5_m.png');
            // .app-img
            //     .list
            //         top: 278px
            //         // left: 159px
            //         right: 50px
            .classify-intro {
                padding-top: 32px; }
            .app-img {
                margin-top: 28px; } }
        .page-6 {
            background-image: url('../css/img/app_down/apps_bg6_m.png'); } } }



@media (max-width: 320px) {
    .m-app-down {
        .section {
            .tmt-info {
                padding-top: 30px;
                .download-btn {
                    margin-top: 10px; }
                .txt-intro {
                    margin-top: 10px; } }
            .footer {
                .list {
                    li {
                        padding: 0 10px; } } } } } }


















