.event-post-list {
  padding-top: 151px;
  h1.title {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 12px;
    @include fs(24);
    font-weight: bold; }
  .bio {
    padding-bottom: 50px;
    border-bottom: 1px solid #E5E5E5; }
  .mod-article-list {
    .cont {
      float: right;
      .intro {
        color: #808080; } }
    .category {
      margin-bottom: 15px;
      span {
        display: inline-block;
        *display: inline;
        padding: 0 5px;
        line-height: 17px;
        @include fs(12);
        background-color: $main;
        color: #fff; } }
    .pic {
      left: 0;
      margin-left: 0;
      position: static;
      float: left;
      @include transformY(0); } } }

.event-detail {
  padding-top: 110px!important;
  article {
    h1 {
      margin-bottom: 30px; } }
  .more {
    a {
      color: $main;
      text-decoration: underline; } }
  .article-list {
    font-size: 0; }
  .related_articles {
    margin-bottom: 50px; }

  .event-info {
    .pic {
      img {
        margin: 0 !important; } }
    .panel {
      background-color: #F6F6F6;
      padding: 30px;
      margin-bottom: 27px;
      p {
        @include fs(14);
        line-height: 21px;
        margin-bottom: 0; }
      .summary {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #D8D8D8; }
      .info {
        position: relative;
        p {
          line-height: 28px;
          .title {
            font-weight: bold; }
          .word {
            color: $main !important; } }
        .btn {
          position: absolute;
          top: 0;
          right: 20px;
          display: block;
          width: 86px;
          height: 36px;
          background-color: #178CF2;
          line-height: 36px;
          @include fs(14);
          color: #FFFFFF !important;
          text-decoration: none !important;
          border-radius: 0;
          cursor: pointer;
          &.disabled {
            background-color: #999999; } } } } } }

.event-container {
  background-color: #f7f9ff;
  width: 100%;
  margin: 0 auto;
  padding-top: 54px;
  //tip是小角标的公用样式
  .tip {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding: 6px 20px;
    border-bottom-right-radius: 15px;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 1px;
    z-index: 5;
    &.green {
      background-color: #2DC973; }
    &.red {
      background-color: #F75757; }
    &.pink {
      background-color: #FF3399; }
    &.blue {
      background-color: #2E31DE; }
    &.blue1 {
      background-color: $tmt_color_red; } }
  .part {
    max-width: 1020px;
    margin: 0 auto;
    &>h2 {
      line-height: 34px;
      font-size: 24px;
      color: #232323;
      font-weight: bold; }
    .load-more {
      width: 120px;
      height: 36px;
      margin: 0 auto;
      margin-top: 40px;
      background-color: #fff;
      box-shadow: 0 0 4px 0 #FFF1F3;
      text-align: center;
      line-height: 36px;
      font-size: 13px;
      color: $tmt_color_red;
      cursor: pointer;
      transform: scale(1);
      transition: all .5s linear;
      &:hover {
        transform: scale(1.1); } } }
  .ad-part {
    position: relative;
    height: 124px;
    overflow: hidden;
    margin-top: 20px;
    img {
      max-width: 100%;
      min-width: 100%;
      height: 100%; }
    .close {
      position: absolute;
      top: 15px;
      right: 30px;
      font-size: 18px;
      color: #fff;
      cursor: pointer; } }
  .swiper-part {
    margin-top: 20px;
    height: 350px;
    display: flex;
    justify-content: space-between;
    .swiper-part-left {
      width: 700px;
      height: 100%;
      background: #0A246A;
      position: relative;
      .swiper-container {
        width: 700px;
        height: 350px;
        cursor: pointer;
        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          align-items: center;
          overflow: hidden;
          img {
            min-width: 100%;
            max-width: 100%;
            height: 100%; } }
        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 20px;
            height: 2px;
            background: #FFF;
            opacity: .5;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px; }
          .swiper-pagination-bullet-active {
            background: $tmt_color_red;
            opacity: 1; } } } }
    .swiper-part-right {
      box-sizing: border-box;
      width: 320px;
      height: 100%;
      position: relative;
      background: #232323;
      box-shadow: 0 0 1px 0 rgba(0,0,0,.1);
      overflow: hidden;
      ul {
        height: 100%;
        li {
          cursor: pointer;
          padding: 10px;
          padding-bottom: 0;
          position: relative;
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          &:before {
            content: "";
            width: 1px;
            background: rgba(237,240,247,.1);
            position: absolute;
            left: 63px;
            top: 0;
            bottom: 0; }
          .time {
            float: left;
            width: 30px;
            text-align: right; }
          .circular {
            float: left;
            margin-left: 5px;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background: rgba(192,204,218,1);
            margin-top: 2px;
            background: url("/public/css/img/event/circular.png") no-repeat;
            background-size: 16px 16px; }
          &:hover {
            background: rgba(1,164,160,.1);
            .circular {
              background: url("/public/css/img/event/circular-hover.png") no-repeat;
              background-size: 16px 16px; } }
          .content {
            padding-bottom: 20px;
            margin-left: 60px;
            border-bottom: 1px solid rgba(237,240,247,.1);
            h4 {
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 16px;
              color: #FFFFFF;
              line-height: 22px;
              margin-bottom: 6px;
              position: relative;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              i.sign {
                display: inline-block;
                width: 49px;
                height: 17px;
                background: url("/public/css/img/event/sign.png") no-repeat;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: 3px; } }
            p {
              font-size: 12px;
              color: #B3B3B3;
              line-height: 18px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden; } } } } } }

  .logo-part {
    box-sizing: border-box;
    height: 80px;
    max-width: 100%;
    background-color: #fff;
    margin-top: 40px;
    padding: 10px 0;
    .logo_inner {
      height: 100%;
      width: 1020px;
      margin: 0 auto;
      box-sizing: border-box;
      .logo_cont {
        position: relative;
        overflow: hidden;
        padding-right: 35px;
        height: 100%;
        .nav_arrow {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding-left: 20px;
          z-index: 3;
          background-color: #FFF;
          span {
            display: inline-block;
            border: 6px solid transparent;
            cursor: pointer; }
          .arrow_prev {
            border-right-color: rgba(197, 0, 43, 0.5);
            margin-right: 5px;
            &.active {
              border-right-color: $tmt_color_red; } }
          .arrow_next {
            border-left-color: rgba(197, 0, 43, 0.5);
            &.active {
              border-left-color: $tmt_color_red; } } }
        .list_box {
          height: 100%;
          text-align: center;
          display: flex;
          flex-wrap: nowrap;
          position: absolute;
          padding-left: 10px;
          .action_all {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 40px;
            a {
              text-align: center;
              border-radius: 50%;
              background-color: #333;
              display: inline-block;
              width: 30px;
              height: 30px;
              line-height: 30px;
              color: #fff;
              font-size: 12px; } }
          p {
            width: 80px;
            margin-right: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            &:hover {
              img {
                transform: scale(1.1); } }
            &:last-child {
              margin-right: 0; }
            img {
              width: 100%;
              transform: scale(1);
              transition: all .5s linear; } } } } } }
  .fixed-top {
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 99;
    border-bottom: 1px solid #E5E5E5; }
  .event-part {
    margin-top: 40px;
    &:before, &:after {
      content: "";
      display: table; }
    .nav {
      height: 62px;
      .nav_inner {
        width: 1020px;
        margin: 0 auto;
        border-top: 1px solid #979FA8;
        border-bottom: 1px solid #979FA8;
        .nav_cont {
          overflow: hidden;
          box-sizing: border-box;
          padding-right: 35px;
          position: relative;
          height: 62px;
          .nav_arrow {
            position: absolute;
            right: 0;
            height: 62px;
            line-height: 62px;
            padding-left: 20px;
            z-index: 3;
            background-color: #f7f9ff;
            span {
              display: inline-block;
              border: 6px solid transparent;
              cursor: pointer; }
            .arrow_prev {
              border-right-color: rgba(197, 0, 43, 0.5);
              margin-right: 5px;
              &.active {
                border-right-color: $tmt_color_red; } }
            .arrow_next {
              border-left-color: rgba(197, 0, 43, 0.5);
              &.active {
                border-left-color: $tmt_color_red; } } }
          nav {
            font-size: 0;
            white-space: nowrap;
            overflow: hidden;
            position: absolute;
            z-index: 2;
            height: 100%;
            .nav_title {
              height: 100%;
              display: inline-block; }
            a {
              display: inline-block;
              color: #3A405B;
              line-height: 59px;
              font-weight: 500;
              @include fs(16);
              margin-right: 30px;
              border-bottom: 4px solid transparent;
              padding: 0 10px;
              &.active {
                border-bottom: 4px solid $tmt_color_red;
                color: $tmt_color_red; } } } } } } }

  .event-list {
    margin-top: 40px;
    .list {
      width: 1020px;
      margin: 0 auto;
      text-align: left;
      font-size: 0;
      display: flex;
      flex-wrap: wrap;
      .item {
        cursor: pointer;
        width: 320px;
        height: 410px;
        min-height: 410px;
        margin-right: 30px;
        margin-bottom: 30px;
        text-align: left;
        overflow: hidden;
        &:nth-child(3n) {
          margin-right: 0; }
        img {
          font-size: 0;
          transform: scale(1);
          transition: all .5s linear; }
        .cover {
          position: relative;
          overflow: hidden;
          background: #fff;
          img {
            width: 100%;
            min-height: 160px; } }
        &:hover {
          .cover {
            img {
              transform: scale(1.1); } } }
        .detail {
          background-color: #fff;
          padding: 20px 20px 15px 20px;
          min-height: 250px;
          max-height: 250px;
          span {
            display: block; }
          .title {
            font-family: PingFangSC-Medium;
            line-height: 24px;
            font-size: 16px;
            color: #3A405B;
            font-weight: 500;
            min-height: 48px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden; }
          .date {
            margin-top: 20px;
            font-size: 16px;
            color: #828891;
            line-height: 22px; }
          .space {
            margin-top: 6px;
            font-size: 14px;
            color: #828891;
            line-height: 22px; }
          .tags {
            margin-top: 20px;
            padding-top: 5px;
            border-top: 1px solid rgba(161, 167, 189, 0.30);
            li {
              display: inline-block;
              background-color: #FFF1F3;
              margin-top: 10px;
              margin-right: 10px;
              padding: 5px 16px;
              line-height: 16px;
              font-size: 12px;
              color: $tmt_color_red; } } } } } }

  .saying-part {
    position: relative;
    margin-top: 100px;
    height: 480px;
    overflow: hidden;
    .bg {
      width: 100%;
      background: #FFC001;
      height: 200px;
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0; }
    .part {
      position: relative;
      height: 480px;
      .saying-title {

        position: absolute;
        top: 47px;
        left: 40px;
        display: inline-block;
        padding-left: 12px;
        border-left: 4px solid #FFC001;
        font-weight: 600;
        z-index: 2;
        letter-spacing: 2px;
        font-size: 24px;
        color: rgba(255,192,1,1);
        line-height: 24px; }
      .swiper-container {
        height: 100%;
        .swiper-slide {
          width: 100%;
          height: 100%;
          img {
            min-width: 100%;
            max-width: 100%;
            height: 100%; } } }
      .arrow {
        position: absolute;
        left: -50px;
        bottom: 80px;
        z-index: 3;
        span {
          background-color: #232323;
          display: inline-block;
          margin-right: 2px;
          padding: 6px 12px;
          color: #333;
          transform: skew(-20deg);
          cursor: pointer;
          &:last-child {
            margin-right: 0; }
          i {
            display: inline-block;
            transform: skew(20deg);
            color: #fff;
            font-weight: bold; }
          &:hover {
            i {
              color: #FFC001; } } } } } }
  .video-part {
    margin-top: 50px;
    .three-video {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      height: 378px;
      a {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        .video-img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 42px;
          height: 42px;
          background: url("/public/css/img/event/video-img.png") no-repeat;
          background-size: cover;
          z-index: 9; } }
      img {
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        transform: scale(1);
        transition: all .5s linear;
        cursor: pointer;
        &:hover {
          transform: scale(1.1); } }
      .mark_info {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        background: rgba(35,35,35,.8);
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255,255,255,1);
        line-height: 22px; }
      .video-left {
        position: relative;
        width: 680px;
        height: 100%;
        margin-right: 20px;
        overflow: hidden; }
      .video-right {
        overflow: hidden;
        height: 100%;
        width: 320px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &>div {
          position: relative;
          overflow: hidden; }
        img {
          height: 180px;
          width: 100%; } } }
    .slide-video {
      margin-top: 20px;
      position: relative;
      padding: 0 34px;
      height: 162px;
      .swiper-container {
        height: 100%; }
      .swiper-slide {
        position: relative;
        width: 288px;
        height: 100%;
        margin-right: 10px;
        overflow: hidden;
        cursor: pointer;
        a {
          position: relative;
          display: inline-block;
          width: 100%;
          height: 100%;
          .video-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 42px;
            height: 42px;
            background: url("/public/css/img/event/video-img.png") no-repeat;
            background-size: cover;
            z-index: 9; } }
        .mark_info {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: auto;
          background: rgba(35,35,35,.8);
          padding: 10px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(255,255,255,1);
          line-height: 22px; }
        img {
          min-width: 100%;
          max-width: 100%;
          height: 100%;
          transform: scale(1);
          transition: all .5s linear; }
        &:hover {
          img {
            transform: scale(1.1); } } }
      .swiper-btn {
        width: 24px;
        height: 162px;
        background: rgba(35,35,35,.8);
        position: absolute;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center; }
      .left {
        left: 0;
        top: 0;
        border-radius: 2px 0px 0px 2px;
        &:hover {
          i {}
          color: $tmt_color_red; } }
      .right {
        right: 0;
        top: 0;
        border-radius: 0px 2px 2px 0px;
        &:hover {
          i {
            color: $tmt_color_red; } } } } }
  .news-part {
    margin-top: 50px;
    line-height: 34px;
    font-size: 24px;
    color: #232323;
    .news-list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        box-sizing: border-box;
        cursor: pointer;
        width: 490px;
        height: 130px;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0 0 2px 0 rgba(20, 28, 44, 0.20);
        &:nth-child(2n) {
          margin-right: 0; }
        .imgs-box {
          width: 120px;
          float: left;
          overflow: hidden;
          img {
            transform: scale(1);
            transition: all .5s linear; } }
        .detail {
          margin-left: 140px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            line-height: 24px;
            vertical-align: top;
            font-size: 16px;
            color: #3A405B;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden; }
          .info {
            line-height: 22px;
            font-size: 14px;
            color: $tmt_color_red;
            .date {
              color: #343A00; } } }

        &:hover {
          img {
            transform: scale(1.1); } } } } }
  .subscribe-part {
    position: relative;
    margin-top: 80px;
    padding: 30px 0;
    background-color: rgba(0, 32, 59, 0.98);
    text-align: center;

    .subscribe-part-tip {
      margin-right: 40px;
      font-size: 16px;
      color: #fff; }
    .email {
      width: 260px;
      height: 22px;
      margin-right: 40px;
      padding: 11px 20px;
      border-radius: 2px;
      font-size: 14px;
      color: #3A405B;
      border: none; }
    .subscribe-btn {
      width: 120px;
      height: 40px;
      border-radius: 2px;
      background-color: $tmt_color_red;
      border: 1px solid rgba(58, 64, 91, 0.20);
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      vertical-align: middle; } }
  //活动二级落地页
  .action-detail {
    .bg-part {
      margin-top: 40px;
      width: 100%;
      background: #fff;
      height: 157px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &>div {
        position: relative;
        &.bg-logo-part {
          width: 312px;
          height: 100%;
          margin-right: 20px;
          background: $tmt_color_red;
          .mark_bg {
            width: 312px;
            height: 100%;
            background: rgba(27,42,44,1);
            position: absolute;
            top: -5px;
            left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 200px;
              height: 100px; } } }
        &.bg-info-part {
          flex-grow: 1;
          background: $tmt_color_red;
          height: 152px;
          .img_mark {
            width: 100%;
            height: 100%;
            position: absolute;
            top: -5px;
            left: 5px;
            img {
              width: 100%;
              height: 100%; } }
          .mark {
            width: 100%;
            height: 157px;
            position: absolute;
            top: -10px;
            left: 10px;
            background: rgba(0,0,0,.6);
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;
            .info_part {
              width: 800px;
              font-size: 16px;
              line-height: 32px;
              color: rgba(201,223,224,1);
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              a {
                font-size: 16px;
                color: rgba(201,223,224,1); } } } } } }
    .logo-part {
      .list_box {
        p.on {
          &:before {
            content: "";
            height: 4px;
            background: $tmt_color_red;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0; } } } } } }

.event-container .event-ended {
  margin-bottom: 100px;
  .mod-tit {
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 28px; }
  .event-list li {
    display: inline-block;
    *display: inline;
    width: 348px;
    margin-bottom: 20px; }
  .title {
    color: #D93641;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 18px;
    margin-bottom: 4px; }
  .time {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 18px;
    color: #B3B3B3; } }

.qrcode_sidebar {
  position: fixed;
  top: 50%;
  right: 100px;
  transform: translateY(-50%);
  width: 170px;
  background-color: #FFFFFF;
  padding: 20px 15px;
  border: 1px solid rgba(25, 164, 159, 0.30);
  box-shadow: 0 0 6px 0 rgba(25, 164, 159, 0.30);
  .close-btn {
    position: absolute;
    top: -18px;
    right: -18px;
    border: 1px solid rgba(25, 164, 159, 0.50);
    box-shadow: 0 0 4px 0 rgba(25, 164, 159, 0.30);
    display: inline-block;
    width: 36px;
    height: 36px;
    background-color: #FFFFFF;
    text-align: center;
    border-radius: 50%;
    i {
      line-height: 38px;
      font-size: 18px;
      color: $tmt_color_red; } }
  img {
    display: block;
    margin-bottom: 10px; }
  p {
    line-height: 20px;
    font-size: 14px;
    color: #333333; }
  a {
    display: block;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(51, 51, 51, 0.20);
    font-size: 14px;
    color: $tmt_color_red; } }

.pop_up {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255, 0.98);
  overflow: scroll;
  z-index: 11; }

.qrcode_pop {
  .pop_cont {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 384px;
    padding: 0;
    text-align: center; }
  .tit {
    margin-top: 50px;
    color: #546369;
    margin-bottom: 30px; }
  .txt {
    color: #38454A;
    line-height: 22px;
    margin-bottom: 6px; }
  .name {
    width: 200px;
    margin: 0 auto;
    line-height: 46px;
    color: #38454A;
    font-size: 2.4rem;
    margin-bottom: 30px;
    background: #fff; }
  .qrcode {
    width: 160px;
    height: 160px;
    margin-bottom: 30px; }
  .remind {
    color: #38454A;
    font-size: 1.8rem;
    line-height: 28px;
    .use_na {
      color: $tmt_color_red; } }
  .pro {
    margin-top: 10px;
    a {
      display: inline-block;
      padding-top: 5px;
      border-top: 1px solid rgba(51, 51, 51, 0.20);
      font-size: 14px;
      color: $tmt_color_red; } }
  .btn {
    width: auto;
    height: auto;
    display: inline-block;
    padding: 0 15px;
    border: 1px solid transparent;
    border-radius: 3px;
    line-height: 33px;
    font-size: 1.2rem;
    color: #fff;
    background-color: $tmt_color_red;
    cursor: pointer;
    outline: none; }
  .kown_btn {
    margin-top: 50px;
    margin-bottom: 80px;
    .btn {
      font-size: 1.3rem;
      color: $tmt_color_red;
      background: #fff;
      border: 1px solid $tmt_color_red;
      border-radius: 3px;
      padding: 10px 30px;
      line-height: 16px;
      &:hover {
        color: #fff;
        background: $tmt_color_red; } } } }















