.btn {
    display: block;
    border: none;
    height: 45px;
    @include rounded(40px);
    line-height: 45px;
    width: auto;
    text-align: center;
    font-size: 1.6rem;
    // margin: 30px 0
    outline: none;
    cursor: pointer;
    padding: 0;
    overflow: visible;
    -webkit-appearance: none;
    @include transition(0.4s, ease);
    transition: 100ms background-color,100ms border-color,100ms color;
    box-sizing: border-box; }

.btn-bordered {
    display: block;
    border: 1px solid;
    padding: 0; }

.red {
    .line {
        vertical-align: middle;
        display: inline-block;
        *display: inline;
        height: 14px;
        margin: -3px 7px 0 3px;
        border-left: 1px solid $red; }
    &.btn {
        background-color: $red;
        color: white;
        -webkit-appearance: none; }
    &.btn-bordered {
        color: $red;
        border-color: $red;
        background-color: transparent;
        &:hover,&.hover {
            background-color: $red;
            border-color: $red;
            color: white;
            .line {
                border-color: white; } } }
    &.disable {
        color: #D7D7D7;
        border-color: #EEEEEE;
        background-color: white;
        &:hover {
            background-color: #FFF;
            color: #D7D7D7; } } }
.orange {
    &.btn {
        background-color: $tmt_color_red;
        color: #fff;
        color: white;
        -webkit-appearance: none;
        &:hover {
            background-color: $tmt_color_red_hover; } }
    &.btn-bordered {
        color: $tmt_color_red;
        border-color: $tmt_color_red;
        background-color: transparent;
        &:hover {
            color: $tmt_color_red_hover;
            border-color: $tmt_color_red_hover;
            background-color: #FFF1F3; }

        &.hover {
            background-color: $tmt_color_red;
            color: #fff;
            &:hover {
                border-color: $tmt_color_red_hover;
                background-color: $tmt_color_red_hover; } } }
    &.disable {
        opacity: .3;
        &:hover {
            color: $main;
            border-color: $main; } } }
.gray {
    &.btn {
        background-color: #b2b2b2;
        color: #909090;
        -webkit-appearance: none; }
    &.btn-bordered {
        color: #909090;
        border-color: #D8D8D8;
        background-color: white;
        &:hover {
            border-color: #b2b2b2;
            color: #666666; } }
    &.disable {
        opacity: .3;
        &:hover {
            color: #b2b2b2;
            border-color: #666666; } } }

.btn-normal {
    width: auto;
    display: inline-block;
    *display: inline;
    height: 38px;
    line-height: 36px;
    box-sizing: border-box;
    @include fs(14);
    padding: 0 20px;
    @include rounded(40px); }
.btn-small {
    width: auto;
    display: inline-block;
    *display: inline;
    height: 30px;
    line-height: 28px;
    padding: 0 18px;
    @include fs(14);
    @include rounded(40px); }
.btn-x-small {
    width: auto;
    display: inline-block;
    *display: inline;
    height: 26px;
    line-height: 26px;
    // height: 30px
    // line-height: 30px
    padding: 0 18px;
    @include fs(14);
    @include rounded(40px); }
.btn-large {
    width: auto;
    height: 41px;
    display: inline-block;
    *display: inline;
    @include fs(18);
    // border: 1px #D8D8D8 solid
    line-height: 39px;
    @include rounded(40px);
    padding: 0 20px; }


.tabs {
    width: 44%;
    min-width: 180px;
    line-height: 20px;
    position: relative;
    top: 15px;
    .tab {
        display: inline-block;
        *display: inline;
        text-align: center;
        margin-left: -1px;
        padding: 5px 0;
        @include fs(18);
        &.active {
            background-color: $tmt_color_red;
            color: white; }
        &:first-child {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px; }
        &:last-child {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px; } } }
.btn-app {
    border: 2px solid $main_blue;
    box-sizing: border-box;
    color: #fff;
    width: 180px;
    line-height: 45px;
    @include fs(18);
    display: inline-block;
    @include rounded(5px); }




.btn-tag {
    @include fs(14);
    color: #909090;
    line-height: 30px;
    padding: 0 19px;
    display: inline-block;
    border: 1px solid #f2f2f2;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    &:hover {
        color: $main;
        border-color: $main;
        background-color: rgba(34,187,149, 0.05); } }

.act-tag {
    display: inline-block;
    border: 1px solid #f2f2f2;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: gray;
    &.current {
        background-color: $main;
        border-color: $main;
        .unfollow {
            display: inline-block; }
        .follow {
            display: none; }
        .gap-line,.tag-a,.act {
            color: #fff; } }
    .tag-a {
        display: inline-block;
        padding: 4px 5px 4px 10px;
        color: gray;
        @include fs(14);
        line-height: 13px;
        line-height: 22px; }
    .gap-line {
        display: inline-block;
        color: gray;
        position: relative;
        top: -1px;
        @include fs(12); }
    .act {
        display: inline-block;
        color: gray;
        cursor: pointer;
        width: 15px;
        text-align: center;
        padding-right: 3px;
        @include fs(12); }
    .unfollow {
        display: none;
        @include fs(18);
        line-height: 12px; } }



.vc-btn {
    display: inline-block;
    padding: 0 20px;
    @include fs(20);
    line-height: 50px;
    border: none;
    background-color: #FF3D00;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    &.small {
        @include fs(18);
        line-height: 40px; }
    &.black {
        background-color: rgba(0,0,0,.6); }
    &.border {
        border: 1px  solid rgba(102,102,102,.3); }
    &.white {
        background-color: #FFF;
        color: #808080; }
    &.red {
        background-color: #F24964; }
    &.radius {
        @include rounded(4px); } }



.btn_red {
    display: inline-block;
    width: auto;
    background: #E73140;
    box-shadow: 0 4px 6px 0 rgba(235,81,126,0.6);
    border-radius: 3px;
    font-size: 1.3rem;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 36px;
    padding: 0 29px;
    height: auto; }






