.two_lines {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important; }
.index_cont {
    // 文章通用标签
    .post_tag {
        position: absolute;
        top: 0;
        left: 0;
        border-left: 4px solid $main_current;
        padding: 0 10px;
        background-color: #000;
        @include fs(14);
        line-height: 24px;
        color: #fff;
        z-index: 3; }
    .atlas_li {
        .post_tag {
            border-left: 4px solid #FF3399 !important; } }
    .pro_icon {
        display: inline-block;
        line-height: 20px;
        padding: 0 6px;
        background-color: #B880F6;
        color: #fff;
        @include fs(13);
        @include rounded(30px);
        margin-bottom: 10px;
        font-style: italic; }
    .main_cont {
        .article-list {
            .new {
                .pro_icon {
                    position: absolute;
                    left: 5px;
                    top: 5px; }
                .group {
                    .group_cell {
                        position: relative;
                        display: inline-block;
                        width: auto;
                        height: auto;
                        margin-right: 20px;
                        .bottom_banner {
                            position: inherit;
                            // bottom: 0
                            width: 100%;
                            padding: 10px 2px 0 3px; }
                        .title {
                            color: #333333;
                            @include fs(14);
                            line-height: 22px;
                            white-space: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical; }
                        .shadow {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            // height: 150px
                            top: 0;
                            background-color: rgba(0,0,0,.1); } } } } } }
    .post_data {
        a {
            color: #fff; }
        i {
            color: #2BF4F4;
            margin-right: 8px; }
        .like_num,.comment_num,.read_num {
            margin-right: 22px; }
        .like_num {
            cursor: pointer;
            .icon-Shape9 {
                display: none; }
            &.current {
                .icon-Shape9 {
                    display: inline-block; }
                .icon-Shape10 {
                    display: none; } } } }
    .top_article {
        background-color: #121212;
        margin-bottom: 30px;
        padding-top: 55px;
        .first {
            position: relative;
            margin-bottom: 30px; }
        .inner,.column,.articles {
            .options {
                @extend .fr;
                a {
                    display: inline-block;
                    *display: inline;
                    // line-height: 50px
                    // color: rgba(255, 255, 255, 0.5)
                    color: #fff;
                    &:hover,&.current {
                        color: $main;
                        text-decoration: none;
                        .icon {
                            // border-color: $main
                            // background-color: $main
                            color: $main; } }
                    &.current {
                        .icon-Shape9 {
                            display: inline-block;
                            *display: inline;
                            -webkit-animation: scalefade 300ms;
                            animation: scalefade 300ms; }
                        .icon-like-1 {
                            display: none; } }
                    .icon-Shape9 {
                        display: none; } }
                .icon {
                    vertical-align: middle;
                    text-align: center;
                    display: inline-block;
                    *display: inline;
                    // width: 50px
                    // height: 50px
                    // border: 1px solid #fff
                    margin-right: 10px;
                    // @include rounded(50%)
                    // @include fs(20)
                    @include fs(16);
 }                    // border-color: rgba(255, 255, 255, 0.5)
                .s-like {
                    margin-right: 6px; }
                .icon-comment {
                    position: relative;
                    top: 2px; }
                .num {
                    @include fs(13);
                    position: relative;
                    top: 2px; }
                .like {
                    margin-right: 30px; } } }
        .inner {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            &:hover .overlay {
                opacity: 0.7;
                filter: alpha(opacity=70);
                -ms-filter: alpha(opacity=70);
                zoom: 1; }
            .post {
                width: 530px;
                height: 339px;
                padding: 86px 70px 0;
                position: relative;
                a {
                    color: #fff; }
                // // 阴影
                // background-image: -webkit-gradient(linear,0 0,0 100%,from(rgba(0,0,0,.01) 40%),to(rgba(0,0,0,.7) 80%))
                // background-image: -webkit-linear-gradient(top,rgba(0,0,0,.01) 40%,rgba(0,0,0,.7) 80%)
                // background-image: -moz-linear-gradient(top,rgba(0,0,0,.01) 40%,rgba(0,0,0,.7) 80%)
                // background-image: linear-gradient(top,rgba(0,0,0,.01) 40%,rgba(0,0,0,.7) 80%)
 }                // background-repeat: repeat-x
            .overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                transition: all 0.2s ease;
                background-image: -webkit-linear-gradient(180deg, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.95) 100%);
                background-image: -moz-linear-gradient(180deg, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.95) 100%);
                background-image: linear-gradient(180deg, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.95) 100%); }
            .post-cont {
                position: absolute;
                bottom: 10px;
                width: 530px; }
            h2 {
                margin-bottom: 25px; }
            .info {
                @include fs(13);
                color: #fff;
                margin-bottom: 56px; }

            .title {
                @include fs(36);
                line-height: 1.2em; }
            .author {
                width: 310px;
                height: 100%;
                position: relative;
                background-color: #020000;
                background-color: rgba(0,0,0,.7);

                .a-inner {
                    padding: 63px 35px 57px;
                    text-align: center;
                    position: relative;
                    z-index: 2;
                    .btn {
                        &.hover {
                            background-color: $orange;
                            color: #fff;
                            &:hover {
                                background-color: $orange_hover; } } }
                    img {
                        @include rounded(50%);
                        border: 2px solid #fff; }
                    p {
                        @include fs(14);
                        color: #fff;
                        color: rgba(255,255,255,.5);
                        margin-bottom: 30px; }
                    .pic {
                        display: block;
                        margin-bottom: 20px; }
                    .name {
                        @include fs(18);
                        color: #fff;
                        display: block;
                        margin-bottom: 30px;
                        padding-bottom: 16px;
                        border-bottom: 1px solid rgba(255,255,255,.2); } } } }
        .first {
            width: 980px;
            margin: 0 auto;
            overflow: hidden;
            // box-shadow: 0px 0px 2px #000
            // display: inline-block
            img.bg {
                filter: blur(2px); } }
        .inner {
            &:hover .overlay {
                opacity: 1; }
            .post {
                width: 670px;
                padding: 0;
                height: 100%;
                display: table; }
            .post-cont {
                position: static;
                width: 670px;
                display: table-cell;
                vertical-align: middle; }
            .summary {
                color: #fff;
                opacity: .8;
                @include fs(16);
                padding-bottom: 30px;
                border-bottom: 1px solid rgba(255,255,255,.2);
                margin-bottom: 30px; }
            h2 {
                margin-bottom: 20px; }
            .tag {
                background-color: $orange;
                display: inline-block;
                line-height: 23px;
                padding: 0 5px;
                color: #fff;
                @include fs(16);
                margin-bottom: 20px; }
            .overlay {
                background-image: -webkit-linear-gradient(to right, #121212, rgba(18,18,18,.2), #121212);
                background-image: -o-linear-gradient(to right, #121212, rgba(18,18,18,.2), #121212);
                background-image: linear-gradient(to right, #121212, rgba(18,18,18,.2), #121212); }
            .author {
                background-color: transparent;
                display: table;

                .a-inner .name {
                    border: none;
                    padding-bottom: 0;
                    margin-bottom: 23px; }
                .a-inner {
                    padding: 0;
                    display: table-cell;
                    vertical-align: middle;
                    p {
                        margin-bottom: 0;
                        padding: 0 35px; } }
                .follow {
                    display: none; } }
            .info {
                opacity: .8;
                margin-bottom: 10px; }
            .options {
                opacity: .8;
                float: none;
                display: block; } } }
    .top_posts {
        margin-bottom: 30px;
        white-space: nowrap;
        overflow: hidden;
        .part {
            display: inline-block;
            width: 100vw;
            padding-top: 102px;
            padding-bottom: 40px;
            position: relative;
            overflow: hidden;
            .bg,.shadow {
                width: 110%;
                height: 100%;
                position: absolute;
                top: 0;
                left: -5%;
                filter: blur(4px); }
            .bg {
                background-size: cover;
                background-position: center; }
            .shadow {
                background-color: rgba(0,0,0,.6); } }
        .part-cont {
            width: 1024px;
            height: auto;
            margin: 0 auto;
            position: relative;
            z-index: 2; }
        .bottom_banner {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 30px 80px 20px;
            background-color: rgba(0,0,0,.7);
            color: #fff;
            .title {
                margin-bottom: 20px;
                @include fs(30);
                line-height: 46px;
                white-space: normal;
                a {
                    color: #fff; } }
            .info {
                color: #f2f2f2;
                @include fs(14);
                a {
                    color: #f2f2f2; }

                .gap_point {
                    margin: 0 10px; } } }
        .slick-dots {
            bottom: 8px;
            li {
                button {
                    font-size: 13px;
                    color: #2BF4F4;
                    width: 18px;
                    height: 18px;
                    opacity: .7;
                    border: 1px solid #2BF4F4;
                    padding: 0;
                    text-align: center;
                    &:before {
                        color: $main;
                        content: "";
                        height: 18px;
                        width: 18px; } }
                &.slick-active {
                    button {
                        background-color: #2BF4F4;
                        color: #171717;
                        opacity: 1; } } } } }



    .column {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        width: 1020px;
        margin: 0 auto;
        justify-content: space-between;
        margin-bottom: 40px;
        .part {
            width: 320px;
            height: 200px;
            position: relative;
            overflow: hidden; }
        .bottom_banner {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px 20px 22px;
            // background-color: #171717
            color: #fff;
            z-index: 2;
            .title {
                margin-bottom: 10px;
                @include fs(16);
                line-height: 24px;
                a {
                    color: #fff; } } }
        .shadow {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(0,0,0,.5); }

        .novel {
            img {
                transform: translateY(-25%); } }
        .novel_info {
            position: absolute;
            bottom: 0;
            padding: 0 20px 20px;
            z-index: 2;
            .title {
                color: $main_current;
                @include fs(18);
                line-height: 26px;
                margin-bottom: 10px;
                max-width: 280px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                a {
                    color: $main_current; } }
            .summary {
                color: #fff;
                @include fs(16);
                line-height: 24px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(44,255,255,0.5);
                margin-bottom: 10px; }
            .chapter {
                @include fs(14);
                .novel_state {
                    color: $main_current; }
                i {
                    color: rgba(44,255,255,.8);
                    margin-right: 5px;
                    vertical-align: middle; }

                a {
                    color: #fff; } } } }
    .index-ad {
        width: 1020px;
        margin: 0 auto; }
    .main_cont {
        width: 1020px;
        margin: 0 auto;
        .list-cont {
            width: 640px;
            margin-right: 60px;
            position: relative; }
        .nav {
            position: absolute;
            border-bottom: 1px solid #DBDBDB;
            width: 640px;
            &.fixed {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 4;
                width: 100%;
                background-color: #fff;
                .nav_inner {
                    width: 1020px;
                    margin: 0 auto; }
                .nav_cont {
                    width: 640px; } }
            .nav_cont {
                overflow: hidden;
                box-sizing: border-box;
                padding-right: 35px;
                position: relative;
                height: 54px; }
            .nav_arrow {
                position: absolute;
                right: 0;
                height: 54px;
                line-height: 54px;
                padding-left: 20px;
                z-index: 3;
                background-color: #FFF;
                span {
                    display: inline-block;
                    border: 6px solid transparent;
                    cursor: pointer; }
                .arrow_prev {
                    border-right-color: rgba(0,164,160,.5);
                    margin-right: 5px;
                    &.active {
                        border-right-color: $main_blue; } }
                .arrow_next {
                    border-left-color: rgba(0,164,160,.5);
                    &.active {
                        border-left-color: $main_blue; } } } }
        nav {
            font-size: 0;
            padding-top: 14px;
            white-space: nowrap;
            overflow: hidden;
            position: absolute;
            z-index: 2;
            .nav_title {
                display: inline-block; }
            a {
                position: relative;
                bottom: -1px;
                display: inline-block;
                padding-bottom: 14px;
                color: $black;
                line-height: 22px;
                font-weight: bold;
                @include fs(16);
                padding-right: 30px;
                margin-right: 12px;
                border-bottom: 4px solid transparent;
                &.current {
                    border-bottom: 4px solid $main_blue; } } }
        .article-list {
            margin-bottom: 40px;
            padding-top: 55px;
            .pic {
                float: left;
                margin-right: 30px;
                img {
                    width: 200px;
                    height: 150px; } }
            .cont {
                overflow: hidden;
                .title {
                    line-height: 34px;
                    @include fs(24);
                    color: $black;
                    display: block;
                    margin-bottom: 12px; } }
            .info {
                color: #B3B3B3;
                @include fs(13);
                margin-bottom: 14px;
                a {
                    color: $main; }
                .gap_point {
                    color: #C0C0C0;
                    margin: 0 10px; } }
            .options {
                @include fs(13);
                a {
                    color: #808080;
                    &.current,&:hover {
                        color: $main; } }
                i {
                    vertical-align: middle; }

                .icon {
                    margin-right: 6px; } }
            .summary {
                color: #808080;
                @include fs(16);
                line-height: 24px;
                margin-bottom: 11px; }
            .tag {
                font-size: 1.3rem;
                color: $main;
                i {
                    font-size: 1.8rem;
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -4px;
                    margin-right: 10px; }
                a {
                    vertical-align: top;
                    color: $main; } }
            > li {
                padding: 40px 0;
                border-bottom: 1px solid #F2F2F2;
                &:last-child {
                    border: none; } }
            .group {
                font-size: 0;
                margin-right: -6px;
                white-space: nowrap;
                position: relative;
                .group_cell {
                    position: relative;
                    display: inline-block;
                    width: 210px;
                    height: 180px;
                    margin-right: 6px;
                    // margin-right: 20px
                    vertical-align: top; }
                .tag_info {
                    position: absolute;
                    top: 0;
                    text-align: center;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 1.8rem;
                    color: #fff;
                    a {
                        line-height: 240px; } }
                .bottom_banner {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 10px 15px; }
                .title {
                    color: #ffffff;
                    @include fs(16);
                    line-height: 24px;
                    white-space: normal; } }
            .shadow {
                position: absolute;
                width: 100%;
                height: 100%;
                // height: 150px
                top: 0;
                background-color: rgba(0,0,0,.5); }
            .post_part {
                .pic {
                    position: relative; }
                .pro_icon {
                    position: absolute;
                    top: 5px;
                    left: 5px; } }
            .video_part {
                .pic {
                    position: relative;
                    display: inline-block;
                    &:after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        width: 42px;
                        height: 42px;
                        background: url(/public/css/img/video_p.png);
                        background-size: 100% 100%; } } }
            .authors_part {
                .group_cell {
                    height: auto;
                    padding-top: 30px;
                    border: 1px solid #E3E4E7;
                    text-align: center;
                    margin-right: 0.13%; }
                img {
                    border-radius: 50%;
                    margin-bottom: 12px;
                    display: inline-block !important; }
                .title {
                    color: #333333;
                    @include fs(18);
                    line-height: 24px;
                    white-space: normal; }
                .bottom_banner {
                    position: static;
                    padding: 0;
                    margin-bottom: 8px; }
                .fans_num {
                    color: $main;
                    margin-bottom: 29px;
                    @include fs(13);
                    line-height: 17px; } }
            .recommend_post {
                width: 640px;
                // height: 256px
                .cont {
                    position: relative; }
                .bottom_banner {
                    position: absolute;
                    bottom: 0;
                    padding: 0 32px 20px;
                    color: #fff;
                    width: 100%; }
                .title {
                    color: #fff;
                    display: inline-block;
                    white-space: nowrap;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &.bottom_border {
                        padding-bottom: 20px;
                        margin-bottom: 10px;
                        width: 100%;
                        border-bottom: 1px solid rgba(277,277,277,.42); } }
                .info {
                    color: #fff;
                    margin-bottom: 0;
                    .gap_point {
                        color: #C0C0C0; }
                    a {
                        color: #fff; } } }
            .group_tit {
                font-size: 1.6rem;
                font-size: 16px;
                line-height: 22px;
                padding-left: 10px;
                border-left: 4px solid $main_blue;
                margin-bottom: 17px;
                h4 {
                    display: inline-block; }
                .all {
                    font-size: 13px;
                    color: #B3B3B3;
                    line-height: 18px; } }
            .slick-arrow {
                z-index: 2;
                width: 32px;
                height: 46px; }
            .slick-next {
                right: -32px; }
            .slick-prev {
                left: -35px; }
            .slick-prev:before, .slick-next:before {
                color: #C5002B;
                // opacity: .4
                font-size: 16px;
                font-family: 'icomoon'; }
            .slick-prev:before {
                content: "\e604"; }
            .slick-next:before {
                content: "\e605";
                padding-top: 20px; } }
        .interest_list,.categorie_list {
            .loading {
                margin-top: 100px;
                text-align: center;
                @include fs(16);
                color: #D8D8D8; } }
        .categorie_list {
            padding-top: 20px; }
        .interest_list {
            padding-top: 75px;
            .loading {
                margin-top: 55px; }
            .reminder {
                color: #B3B3B3;
                margin-bottom: 30px;
                @include fs(14);
                a {
                    color: $orange;
                    &:hover {
                        color: $orange-hover; } } }
            .recommend-tags {
                padding-top: 20px;
                @include fs(0);
                li {
                    display: inline-block;
                    margin: 0 15px 20px 0;
                    a {
                        color: #909090; } } }
            .r-author-list {
                li {
                    border-bottom: 1px solid #f2f2f2;
                    .author-avatar {
                        width: 40px; }
                    .author-cont .btn {
                        right: 50px; } } }
            .latest-list {
                padding-top: 5px;
                li {
                    border-bottom: 1px solid #f2f2f2;
                    padding: 20px 0;
                    &:last-child {
                        border-bottom: none; } }
                .title {
                    @include fs(18);
                    color: $t_color;
                    margin-bottom: 8px; }
                .time {
                    color: $i_color;
                    @include fs(13);
                    i {
                        padding-right: 5px; } } } } }
    .load_part {
        position: relative;
        width: 100%;
        height: 128px; }
    .load_more {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        margin-bottom: 90px;
        padding: 10px 40px;
        box-shadow: 0px 0px 6px $main;
        text-align: center;
        cursor: pointer;
        span {
            display: inline-block;
            @include fs(14);
            color: $main; } }

    .sidebar {
        overflow: hidden;
        position: relative;
        padding-top: 14px;
        .top {
            border-top: 1px solid #DBDBDB; }
        .part {
            background-color: #FAFAFA;
            padding: 30px 20px;
            margin-bottom: 40px; }
        .w3-part {
            padding: 0; }
        .more {
            color: #B3B3B3;
            @include fs(13);
            padding-top: 15px;
            i {
                // vertical-align: middle
                margin-left: 5px;
                color: #C0C0C0;
                @include fs(12); } }
        .title {
            position: relative;
            top: -1px;
            display: inline-block;
            padding-top: 15px;
            margin-bottom: 25px;
            border-top: 1px solid $main;
            @include fs(16);
            line-height: 21px;
            i {
                margin-right: 10px;
                color: #272636; } }
        li {
            @include fs(14);
            a {
                color: #333; } }
        .see_world {
            li {
                display: block;
                line-height: 24px;
                padding-left: 20px;
                position: relative;
                margin-bottom: 20px;
                cursor: pointer;
                .point {
                    position: absolute;
                    top: 8px;
                    left: 0;
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    @include rounded(50%);
                    background-color: $main; }
                .cont {
                    display: block; }
                .text {
                    height: 28px;
                    overflow: hidden;
                    display: block; }
                time {
                    @include fs(13);
                    color: #808080; }
                .br {
                    padding-bottom: 15px;
                    border-bottom: 1px solid #EDEDED; }
                &:last-child {
                    margin-bottom: 0;
                    .br {
                        border-bottom: none;
                        padding-bottom: 0; } }
                &:first-child {
                    a {
                        border-top: none; } }
                &.show {
                    transition: height .5s ease;
                    -moz-transition: height .5s ease;
                    -webkit-transition: height .5s ease;
                    -o-transition: height .5s ease;
                    .text {
                        height: auto; }
                    .point {
                        @include rounded(0);
                        height: 100%;
                        border: 2px solid #fff;
                        width: 8px;
                        border-bottom: 34px solid #FAFAFA;
                        transition: height .5s ease;
                        -moz-transition: height .5s ease;
                        -webkit-transition: height .5s ease;
                        -o-transition: height .5s ease; } } } }

        .hot_tags {
            li {
                display: inline-block; }
            .tag {
                display: inline-block;
                padding: 4px 10px 3px 10px;
                border: 1px solid #f2f2f2;
                color: gray;
                font-size: 14px;
                font-size: 1.4rem;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                margin: 0 7px 10px 0; } }
        .hot_posts,.reports {
            ol {
                list-style-type: decimal;
                list-style-position: ouside;
                margin-left: 20px; }
            li {
                margin-bottom: 20px;
                padding-left: 5px;
                &:last-child {
                    margin-bottom: 0; }
                &:first-child {
                    a {
                        border-top: none;
                        padding-top: 0; } } } }
        .recommend_product,.partners,.authors {
            li {
                padding: 20px 0;
                border-bottom: 1px solid #EDEDED;
                &:first-child {
                    padding-top: 0; }
                &:last-child {
                    border: none;
                    padding-bottom: 0; } }
            .pic {
                margin-right: 15px; }
            .cont {
                overflow: hidden;
                a {
                    display: inline-block;
                    @include fs(16);
                    color: $main;
                    line-height: 22px;
                    margin-bottom: 4px; }
                p {
                    @include fs(13);
                    color: #808080;
                    line-height: 18px; } } }
        .partners {
            .cont {
                a {
                    color: #808080;
                    @include fs(13);
                    line-height: 18px; } } }
        .authors {
            img {
                @include rounded(50%); } } }
    .side_option {
        position: fixed;
        right: 4%;
        bottom: 100px;
        a {
            display: block;
            width: 50px;
            height: 50px;
            margin-bottom: 15px;
            @include rounded(50%);
            background-color: $main;
            color: #fff;
            text-align: center;
            line-height: 50px; } } }
// 收藏 v9.3.0
.comment {
	margin-left: 10px; }
.favourite {
	.icon-ribbon-1 {
		display: none;
		@include fs(14);
		margin-right: 3px;
		margin-left: 3px;
		&.current {
			.icon-ribbon-1 {
				display: inline-block; }
			.icon-ribbon {
				display: none; } } } }


























