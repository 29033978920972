@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?-csk716-1');
  src: url('../fonts/icomoon.eot?#iefix-csk716-1') format('embedded-opentype'), url('../fonts/icomoon.woff?-csk716-1') format('woff'), url('../fonts/icomoon.ttf?-csk716-1') format('truetype'), url('../fonts/icomoon.svg?-csk716-1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tread:before {
  content: '\e628';
}
.icon-tread2:before {
  content: '\e63';
}

.icon-like-1:before {
  content: '\e60b';
  // content: "\e629";
}
.icon-ribbon-1:before {
  content: '\e626';
}

.icon-iconfont-v:before {
  content: '\e624';
}

.icon-iconfont-present:before {
  content: '\e625';
}

.icon-wealth:before {
  content: '\e61e';
}

.icon-h2:before {
  content: '\e61d';
}

.icon-read:before {
  content: '\e619';
}

.icon-message:before {
  content: '\e618';
}

.icon-QQ:before {
  content: '\e614';
}

.icon-tengxun:before {
  content: '\e615';
}

.icon-weibo:before {
  content: '\e616';
}

.icon-weixin:before {
  content: '\e617';
}

.icon-Shape102:before {
  content: '\e627';
}

.icon-Shape10:before {
  content: '\e607';
}

.icon-tag2:before {
  content: '\e608';
}

.icon-Shape2:before {
  content: '\e609';
}

.icon-Shape9-1:before {
  content: '\e60a';
}

.icon-Shape9:before {
  content: '\e60b';
}

.icon-Shape:before {
  content: '\e60c';
}

.icon-time:before {
  content: '\e600';
}

.icon-svg:before {
  content: '\e603';
}

.icon-arrow-l:before {
  content: '\e604';
}

.icon-arrow-r:before {
  content: '\e605';
}

.icon-like:before {
  content: '\e606';
}

.icon-star2:before {
  content: '\e61f';
}

.icon-star-outline:before {
  content: '\e620';
}

.icon-warning-outline:before {
  content: '\e621';
}

.icon-pin-outline:before {
  content: '\e60d';
}

.icon-lightbulb:before {
  content: '\e61a';
}

.icon-help2:before {
  content: '\e60e';
}

.icon-info-outline:before {
  content: '\e601';
}

.icon-phone-iphone:before {
  content: '\e61b';
}

.icon-star3:before {
  content: '\e622';
}

.icon-star-outline2:before {
  content: '\e623';
}

.icon-eye:before {
  content: '\e000';
}

.icon-paper-clip:before {
  content: '\e001';
}

.icon-mail:before {
  content: '\e002';
}

.icon-toggle:before {
  content: '\e003';
}

.icon-layout:before {
  content: '\e004';
}

.icon-link:before {
  content: '\e005';
}

.icon-bell:before {
  content: '\e006';
}

.icon-lock:before {
  content: '\e007';
}

.icon-unlock:before {
  content: '\e008';
}

.icon-ribbon:before {
  content: '\e009';
}

.icon-image:before {
  content: '\e010';
}

.icon-signal:before {
  content: '\e011';
}

.icon-target:before {
  content: '\e012';
}

.icon-clipboard:before {
  content: '\e013';
}

.icon-clock:before {
  content: '\e014';
}

.icon-watch:before {
  content: '\e015';
}

.icon-air-play:before {
  content: '\e016';
}

.icon-camera:before {
  content: '\e017';
}

.icon-video:before {
  content: '\e018';
}

.icon-disc:before {
  content: '\e019';
}

.icon-printer:before {
  content: '\e020';
}

.icon-monitor:before {
  content: '\e021';
}

.icon-server:before {
  content: '\e022';
}

.icon-cog:before {
  content: '\e023';
}

.icon-heart:before {
  content: '\e024';
}

.icon-paragraph:before {
  content: '\e025';
}

.icon-align-justify:before {
  content: '\e026';
}

.icon-align-left:before {
  content: '\e027';
}

.icon-align-center:before {
  content: '\e028';
}

.icon-align-right:before {
  content: '\e029';
}

.icon-book:before {
  content: '\e030';
}

.icon-layers:before {
  content: '\e031';
}

.icon-stack:before {
  content: '\e032';
}

.icon-stack-2:before {
  content: '\e033';
}

.icon-paper:before {
  content: '\e034';
}

.icon-paper-stack:before {
  content: '\e035';
}

.icon-search:before {
  content: '\e036';
}

.icon-zoom-in:before {
  content: '\e037';
}

.icon-zoom-out:before {
  content: '\e038';
}

.icon-reply:before {
  content: '\e039';
}

.icon-circle-plus:before {
  content: '\e040';
}

.icon-circle-minus:before {
  content: '\e041';
}

.icon-circle-check:before {
  content: '\e042';
}

.icon-circle-cross:before {
  content: '\e043';
}

.icon-square-plus:before {
  content: '\e044';
}

.icon-square-minus:before {
  content: '\e045';
}

.icon-square-check:before {
  content: '\e046';
}

.icon-square-cross:before {
  content: '\e047';
}

.icon-microphone:before {
  content: '\e048';
}

.icon-record:before {
  content: '\e049';
}

.icon-skip-back:before {
  content: '\e050';
}

.icon-rewind:before {
  content: '\e051';
}

.icon-play:before {
  content: '\e052';
}

.icon-pause:before {
  content: '\e053';
}

.icon-stop:before {
  content: '\e054';
}

.icon-fast-forward:before {
  content: '\e055';
}

.icon-skip-forward:before {
  content: '\e056';
}

.icon-shuffle:before {
  content: '\e057';
}

.icon-repeat:before {
  content: '\e058';
}

.icon-folder:before {
  content: '\e059';
}

.icon-umbrella:before {
  content: '\e060';
}

.icon-moon:before {
  content: '\e061';
}

.icon-thermometer:before {
  content: '\e062';
}

.icon-drop:before {
  content: '\e063';
}

.icon-sun:before {
  content: '\e064';
}

.icon-cloud:before {
  content: '\e065';
}

.icon-cloud-upload:before {
  content: '\e066';
}

.icon-cloud-download:before {
  content: '\e067';
}

.icon-upload:before {
  content: '\e068';
}

.icon-download:before {
  content: '\e069';
}

.icon-location:before {
  content: '\e070';
}

.icon-location-2:before {
  content: '\e071';
}

.icon-map:before {
  content: '\e072';
}

.icon-battery:before {
  content: '\e073';
}

.icon-head:before {
  content: '\e074';
}

.icon-briefcase:before {
  content: '\e075';
}

.icon-speech-bubble:before {
  content: '\e076';
}

.icon-anchor:before {
  content: '\e077';
}

.icon-globe:before {
  content: '\e078';
}

.icon-box:before {
  content: '\e079';
}

.icon-reload:before {
  content: '\e080';
}

.icon-share:before {
  content: '\e081';
}

.icon-marquee:before {
  content: '\e082';
}

.icon-marquee-plus:before {
  content: '\e083';
}

.icon-marquee-minus:before {
  content: '\e084';
}

.icon-tag:before {
  content: '\e085';
}

.icon-power:before {
  content: '\e086';
}

.icon-command:before {
  content: '\e087';
}

.icon-alt:before {
  content: '\e088';
}

.icon-esc:before {
  content: '\e089';
}

.icon-bar-graph:before {
  content: '\e090';
}

.icon-bar-graph-2:before {
  content: '\e091';
}

.icon-pie-graph:before {
  content: '\e092';
}

.icon-arrow-left:before {
  content: '\e094';
}

.icon-arrow-right:before {
  content: '\e095';
}

.icon-arrow-up:before {
  content: '\e096';
}

.icon-arrow-down:before {
  content: '\e097';
}

.icon-volume:before {
  content: '\e098';
}

.icon-mute:before {
  content: '\e099';
}

.icon-content-right:before {
  content: '\e100';
}

.icon-content-left:before {
  content: '\e101';
}

.icon-grid:before {
  content: '\e102';
}

.icon-grid-2:before {
  content: '\e103';
}

.icon-columns:before {
  content: '\e104';
}

.icon-loader:before {
  content: '\e105';
}

.icon-bag:before {
  content: '\e106';
}

.icon-ban:before {
  content: '\e107';
}

.icon-flag:before {
  content: '\e108';
}

.icon-trash:before {
  content: '\e109';
}

.icon-expand:before {
  content: '\e110';
}

.icon-contract:before {
  content: '\e111';
}

.icon-maximize:before {
  content: '\e112';
}

.icon-minimize:before {
  content: '\e113';
}

.icon-plus:before {
  content: '\e114';
}

.icon-minus:before {
  content: '\e115';
}

.icon-check:before {
  content: '\e116';
}

.icon-cross:before {
  content: '\e117';
}

.icon-move:before {
  content: '\e118';
}

.icon-delete:before {
  content: '\e119';
}

.icon-menu:before {
  content: '\e120';
}

.icon-archive:before {
  content: '\e121';
}

.icon-inbox:before {
  content: '\e122';
}

.icon-outbox:before {
  content: '\e123';
}

.icon-file:before {
  content: '\e124';
}

.icon-file-add:before {
  content: '\e125';
}

.icon-file-subtract:before {
  content: '\e126';
}

.icon-help:before {
  content: '\e62a';
}

.icon-open:before {
  content: '\e128';
}

.icon-ellipsis:before {
  content: '\e129';
}

.icon-bell2:before {
  content: '\e60f';
}

.icon-store:before {
  content: '\e602';
}

.icon-qrcode:before {
  content: '\e610';
}

.icon-apple:before {
  content: '\e611';
}

.icon-android:before {
  content: '\e612';
}

.icon-paperplane:before {
  content: '\e613';
}

.icon-arrow-down2:before {
  content: '\e61c';
}

.icon-star:before {
  content: '\f005';
}

.icon-star-o:before {
  content: '\f006';
}
.icon-financing:before {
  content: '\e900';
}
.icon-pencil:before {
  content: '\e901';
}

.icon-m-back {
  content: '\e616';
}

.icon-tencent:before {
  content: '\e615';
}

.icon-qq:before {
  content: '\e614';
}
.icon-wechat:before {
  content: '\e617';
}

.icon-refresh:before {
  content: '\e058';
}
.icon-sina:before {
  content: '\e616';
}

.icon-comment:before {
  content: '\e076';
}
.icon-praise:before {
  content: '\e606';
}
// .icon-tread:before{
//     content: "\e607";
// }

.icon-fly:before {
  content: '\e613';
}

.icon-star-outline:before {
  content: '\e620';
}

.icon-wealth:before {
  content: '\e61e';
}

// app  下载页
.icon-phone:before {
  content: '\e61b';
}
//头部 话题下拉图标
.icon-arrow-d:before {
  content: '\e61c';
}
//头部 阅读图标
.icon-read:before {
  content: '\e619';
}

//app  下载页 互动  灯
.icon-light:before {
  content: '\e61a';
}

.icon-popular:before {
  content: '\e5fe';
}
.icon-award:before {
  content: '\e5ff';
}

.icon-iphone:before {
  content: '\e627';
}

.icon-ipad:before {
  content: '\e62b';
}

.icon-balance:before {
  content: '\e92a';
}

.icon-lock2:before {
  content: '\e929';
}

.icon-page:before {
  content: '\e928';
}
.icon-page2:before {
  content: 'e90c';
}
.icon-page3:before {
  content: 'e90d';
}
.icon-tree:before {
  content: '\e90e';
}
.icon-view:before {
  content: '\e90f';
}
.icon-lock3:before {
  content: '\e910';
}
.icon-medal:before {
  content: '\e912';
}
.icon-quit:before {
  content: '\e913';
}
.icon-right2:before {
  content: '\e914';
}
.icon-up:before {
  content: '\e915';
}
.icon-menu2:before {
  content: '\e916';
}
.icon-up2:before {
  content: '\e917';
}
.icon-copy:before {
  content: '\e918';
}
.icon-people:before {
  content: '\e919';
}
.icon-raise:before {
  content: '\e91a';
}
.icon-video2:before {
  content: '\e91b';
}
.icon-pack:before {
  content: '\e91c';
}
.icon-pannel:before {
  content: '\e91d';
}
.icon-home:before {
  content: '\e91e';
}
.icon-bag:before {
  content: '\e91f';
}
.icon-circle-close:before {
  content: '\e043';
}
.icon-circle-right:before {
  content: '\e042';
}
.icon-right3:before {
  content: '\e924';
}
.icon-recycle:before {
  content: '\e926';
}
.icon-set:before {
  content: '\e927';
}

.icon-right4:before {
  content: '\e925';
}
.icon-down:before {
  content: '\e911';
}

.icon-down2:before {
  content: '\e94e';
}
.icon-vip:before {
  content: '\e93f';
}

.icon-earth:before {
  content: '\e127';
}
.icon-star4:before {
  content: '\e623';
}
.icon-cup:before {
  content: '\e5ff';
}
.icon-note:before {
  content: '\e90d';
}
.icon-down3:before {
  content: '\e911';
}
.icon-voice:before {
  content: '\e098';
}
.icon-close:before {
  content: '\e922';
}

// 编辑器
.mce-i-help {
  font-family: 'icomoon' !important;
}
.mce-i-help:before {
  content: '\e62a' !important;
  margin-left: 3px;
}

.icon-pro:before {
  content: '\e940';
}
.icon-see:before {
  content: '\e941';
}
.icon-mobile:before {
  content: '\e942';
}
.icon-account:before {
  content: '\e943';
}
.icon-qrcode:before {
  content: '\e944';
}
.icon-write:before {
  content: '\e945';
}
.icon-author:before {
  content: '\e946';
}
.icon-sponsor:before {
  content: '\e947';
}
.icon-report:before {
  content: '\e948';
}
.icon-maker:before {
  content: '\e949';
}
.icon-hot_post:before {
  content: '\e94a';
}
.icon-hot_tag:before {
  content: '\e94b';
}
.icon-world:before {
  content: '\e94c';
}
.icon-message:before {
  content: '\e94d';
}
.icon-writer:before {
  content: '\e900';
}
.icon-bookmark:before {
  content: '\e901';
}
.icon-pro2:before {
  content: '\e902';
}
.icon-order:before {
  content: '\e903';
}
.icon-level:before {
  content: '\e904';
}
.icon-down3:before {
  content: '\e905';
}
.icon-comment2:before {
  content: '\e906';
}
.icon-share2:before {
  content: '\e907';
}

@font-face {
  font-family: 'iconfont'; // 投稿标题字体
  src: url('../fonts/iconfont.ttf?t=1634803953500') format('truetype');
}

.mce-i-subtitle,
.mce-i-h3,
.mce-i-h4 {
  font-family: 'iconfont' !important;
}

.mce-i-subtitle:before {
  content: '\e61a';
}
.mce-i-h3:before {
  content: '\e60b';
}

.mce-i-h4:before {
  content: '\e624';
}

@keyframes scalefade {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8) rotateX(-40deg);
    transform: scale(0.8) rotateX(-40deg);
  }
  50% {
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1.05) rotateX(0);
    transform: scale(1.05) rotateX(0);
  }
  100% {
    -webkit-transform: scale(1) rotateX(0);
    transform: scale(1) rotateX(0);
  }
}

@font-face {
  font-family: 'iconfont2'; /* Project id  */
  src: url('../fonts/iconfont2.ttf?t=1665558473020') format('truetype');
}

.icon-yulan:before {
  content: '\e621';
}

.icon-qingchu:before {
  content: '\e61e';
}
// 编辑器
.mce-i-removeformat {
  font-family: 'iconfont2' !important;
  font-size: 16px !important;
}
.mce-i-removeformat:before {
  content: '\e61e' !important;
}

.mce-i-preview {
  font-family: 'iconfont2' !important;
  font-size: 12px !important;
}
.mce-i-preview:before {
  content: '\e621' !important;
}
