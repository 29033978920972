.post-new {
    width: 980px;
    padding-top: 40px;
    min-height: 1000px;
    .toolbar {
        position: fixed;
        top: 256px;
        display: none;
        span {
            display: block;
            width: 30px;
            height: 30px;
            border: 1px solid #B3B3B3;
            @include rounded(50%);
            color: #B3B3B3;
            text-align: center;
            line-height: 30px;
            margin-bottom: 10px;
            cursor: pointer; } }
    .main {
        margin-left: 140px;
        .title {
            @include fs(36);
            @include plh(#B3B3B3);
            border: none;
            width: 100%;
            font-weight: normal;
            border-bottom: 1px solid #D8D8D8;
            margin-bottom: 50px;
            padding-bottom: 10px;
            vertical-align: middle; }
        .inner {
            @include fs(16);
            textarea {
                width: 700px;
                border: none; } }
        .wysiwyg-container {
            width: 700px;
            border: none; }
        .wysiwyg-editor {
            p {
                margin-bottom: 20px; }
            ol,ul {
                margin: 20px;
                list-style: initial; }
            ol {
                list-style-type: decimal; } } }
    .footer-bar {
        position: fixed;
        _positon: absolute;
        width: 100%;
        bottom: 0;
        right: 0;
        height: 51px;
        border-top: 1px #E5E5E5 solid;
        background-color: #FFF;

        .topic-select {
            padding: 0;
            // overflow: hidden
            left: 51%;
            display: none;
            top: 95px;
            color: #666;
            // max-height: 264px
            // overflow-y: auto
            background-color: #fff;
            .d-bd {
                margin-left: -20px;
                left: 50%;
                top: -20px; }
            li {
                word-break: break-all;
                white-space: initial;
                padding: 15px 30px;
                border-bottom: 1px solid #F2F2F2;
                cursor: pointer;
                line-height: 22px;
                margin-bottom: 0;
                &:hover,&.hover {
                    color: #fff;
                    background-color: $s_color; }
                &:last-child {
                    border: none; }
                &.last {
                    border-bottom: 0;
                    background-color: #F9F9F9;
                    text-align: center;
                    color: $i_color;
                    top: initial;
                    bottom: 52px;
                    position: fixed; } }
            // width: 220px
            //      width: 360px
            .d-bd {
                top: inherit;
                bottom: -20px;
                border-color: #D8D8D8 transparent transparent transparent;
                &:after {
                    top: -10px;
                    border-color: #fff transparent transparent transparent; } } }
        .gap-line {
            margin: 0 5px 0 8px;
            color: #B3B3B3;
            @include fs(15px); }
        .bar-cont {
            width: 980px;
            margin: 0 auto;
            .options {
                margin: 10px auto;
                text-align: center; }
            .btn-part {
                height: 51px;
                line-height: 51px;
                @include fs(14);
                color: #808080;
                input {
                    margin-right: 5px; }
                .pop-close {
                    cursor: pointer; }
                .icon-arrow-d {
                    @include fs(22);
                    position: relative;
                    top: 5px;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
                label {
                    margin-left: 140px; }
                .post_btn {
                    float: right;
                    color: $tmt_color_red;
                    text-decoration: underline; }
                .topic {
                    color: $orange;
                    &:hover {
                        color: $orange-hover; }
                    .txt {
                        width: 200px;
                        text-overflow: ellipsis; } } } } } }

#confrim-post {
    .universal-container {
        width: 700px;
        max-width: 700px; }
    .txt {
        margin-bottom: 0; }
    .info {
        color: #666;
        @include fs(14); }
    .btn-part {
        margin-top: 40px;
        .status {
            color: #B3B3B3;
            font-size: 13px;
            margin-right: 15px; } }
    .first {
        color: #B3B3B3;
        @include fs(14);
        input {
            vertical-align: middle;
            margin-top: -3px;
            margin-right: 5px; } } }
// 投稿编辑器
.post-new {
    #mceu_17-body {
        width: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
    #mceu_16-body {
        width: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
