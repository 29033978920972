.p_footer {
	background: #1B1B1B;
	min-width: 1190px;
	.about_us {
		padding: 50px 0px 40px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		max-width: 1120px;
		.tit_h4 {
			color: #F4F5FA;
			font-size: 18px;
			font-family: HYQiHei;
			margin-bottom: 22px; }
		.us_item {
			a {
				color: #F4F5FA;
				font-size: 16px;
				line-height: 32px;
				&:hover {
 } } }					// font-weight: 600
		.tmt_find,.tmt_contact {
			>div {
				display: flex;
				flex-direction: column; } }
		.tmt_platform {
			>div {
				display: flex;
				flex-wrap: wrap;
				max-width: 165px;
				width: 165px;
				a {
					margin-right: 12px;
					margin-bottom: 12px;
					width: 29px;
					height: 29px;
					min-width: 29px; } } }
		.tmt_brand {
			>div {
				display: flex;
				flex-wrap: wrap;
				max-width: 400px;
				width: 400px;
				justify-content: space-between;
				a {
					margin-bottom: 20px; } } }
		.load_app {
			.tit_h4 {
				margin-bottom: 10px; }
			>div {
				display: flex;
				flex-direction: column;
				img {
					width: 103px; } } } }
	.about_link {
		background: #000000;
		padding: 20px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		p {
			color: #F4F5FA;
			font-size: 16px;
			margin: 0 20px;
			a {
				color: #F4F5FA;
				font-size: 16px; }
			.beian {
				display: flex;
				justify-content: center;
				align-items: center; } } } }
