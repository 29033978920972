.word-detail {
    * {
        box-sizing: unset; } }
.word_content {
    width: 700px;
    margin: 0 auto;
    padding-top: 160px;
    h1.title {
        text-align: center;
        @include fs(24);
        line-height: 25px;
        margin-bottom: 50px; }
    .word_list {
        margin-bottom: 60px;
        .day_part {
            margin-bottom: 10px;
            ul {
                .a_img {
                    display: inline-block;
                    margin-bottom: 20px; } } }

        .date {
            color: $main_blue;
            // border-bottom: 1px solid #F2F2F2
            padding-bottom: 20px;
            time {
                display: inline-block;
                line-height: 24px;
                padding-left: 10px;
                border-left: 4px solid $main_blue;
                margin-left: -12px; } }
        li {
            border-top: 1px solid #F2F2F2;
            padding: 20px 0;
            .w_tit {
                margin-bottom: 19px;
                @include fs(18);
                a {
                    color: #333; } }
            p {
                @include fs(14);
                line-height: 26px;
                margin-bottom: 20px;
                color: #808080;
                a {
                    color: #808080; }
                .link {
                    color: $main_blue;
                    a {
                        @include fs(13);
                        color: $main_blue;
                        text-decoration: underline; } } } }
        .source {
            color: #808080;
            @include fs(13);
            margin-right: 15px; }
        .info {
            line-height: 26px; }
        .shares {
            .txt {
                @include fs(13);
                color: #808080;
                margin-right: 10px; }
            [class^="circle"] {
                margin-right: 10px;
                @include rounded(50%);
                display: inline-block;
                width: 26px;
                height: 26px;
                text-align: center;
                background-color: #D8D8D8;
                i {
                    line-height: 26px;
                    @include fs(12);
                    color: #fff;
                    display: inline-block;
                    width: 100%; } }
            .circle-weibo {
                &:hover {
                    background-color: #E74C3C; } }
            .circle-wechat {
                &:hover {
                    background-color: #27AE60; } }
            .qrcode {
                width: 88px;
                height: 88px;
                margin-bottom: 6px; }
            .w_txt {
                @include fs(14);
                line-height: 20px; }
            .wx-dropdown {
                // width: 88px
                padding: 16px 16px 10px;
                left: -51px;
                bottom: 40px; } }


        .options {
            color: #808080;
            @include fs(14);
            i {
                @include fs(16);
                margin-right: 10px; }
            span {
                cursor: pointer; }
            .js_favourite {
                i {
                    position: relative;
                    top: 1px;
                    @include fs(14); }
                .icon-ribbon-1 {
                    display: none; }
                &.current {
                    .icon-ribbon-1 {
                        display: inline-block;
                        *display: inline;
                        -webkit-animation: scalefade 300ms;
                        animation: scalefade 300ms; }
                    .icon-ribbon {
                        display: none; }
                    color: $main_blue; }
                .num {
                    display: inline-block;
                    min-width: 25px; } }
            .js_like {
                .icon-Shape9 {
                    display: none;
                    margin-top: -3px; }
                &.current {
                    .icon-Shape9 {
                        display: inline-block;
                        *display: inline;
                        -webkit-animation: scalefade 300ms;
                        animation: scalefade 300ms; }
                    .icon-like-1 {
                        display: none; }
                    color: $main_blue; }
                .num {
                    display: inline-block;
                    min-width: 25px; } }
            .js_commment {
                // margin-right: 18px
                i {
                    vertical-align: middle;
                    color: #999; }
                .num {
                    display: inline-block;
                    min-width: 25px; } }
            .dropdown-menu-part {
                a {
                    font-size: 13px !important;
                    font-size: 1.3rem !important;
                    color: #333;
                    &:hover {
                        color: $main_blue; } } } } }
    .pagination {
        li {
            padding: 0;
            border-top: 0; } } }
.word_detail {
    .dropdown-menu-part {
        .wx-dropdown {
            width: 88px;
            padding: 16px 16px 10px;
            left: -31px;
            bottom: 49px; } }
    article {
        margin-bottom: 40px;
        h1 {
            @include fs(24);
            color: #05121E;
            margin-bottom: 10px; }
        .glass {
            margin-bottom: 30px;
            position: relative;
            display: inline-block;
            cursor: pointer;
            .w_de_img {
                margin: 0 !important;
                width: auto; }
            i {
                position: absolute;
                bottom: 10px;
                right: 10px;
                color: #fff;
                @include fs(40); } } }
    .post-info {
        margin-bottom: 25px;
        .color-unclickable {
            color: #999998;
            @include fs(12); } }
    .inner {
        p {
            @include fs(16);
            line-height: 30px; } }
    .post-options {
        line-height: 35px;
        overflow: visible;
        .shares {
            [class^="circle"] {
                height: 32px;
                width: 32px;
                i {
                    line-height: 32px; } }
            .circle-wechat {
                color: #27AE60;
                i {
                    color: #27AE60; } }
            .circle-like,.circle-favourite {
                border-color: #C5002B;
                padding: 0 10px;
                border-radius: 47px;
                width: auto;
                cursor: pointer;
                i,.num {
                    color: #C5002B; }
                i {
                    margin-right: 4px;
                    @include fs (15);
                    position: relative;
                    top: 1px; }
                .num {
                    @include fs (13); } }
            .circle-like.current,.circle-favourite.current {
                background: #C5002B;
                i,.num {
                    color: #fff; } } } }
    .more-message {
        @include fs(14);
        color: #C5002B;
        letter-spacing: 0;
        line-height: 20px;
        display: block;
        text-align: center;
        margin: 20px auto 40px;
        text-decoration: underline; }
    .post-comment {
        margin-top: 40px; }
    .tags {
        color: $main_blue;
        @include fs(13);
        a {
            color: $main_blue; }
        i {
            margin-right: 10px;
            display: inline-block;
            background-image: url("/public/css/img/tag.svg");
            width: 15px;
            height: 15px;
            background-size: cover;
            vertical-align: middle; } }
    .link {
        @include fs(14);
        text-decoration: underline; } }
.no_second_nav {
    .save_img {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.9);
        overflow: scroll;
        z-index: 10;
        .close {
            position: absolute;
            color: #fff;
            right: 90px;
            top: 90px;
            font-size: 38px;
            font-size: 42px;
            cursor: pointer;
            z-index: 11; }
        .big_img {
            left: 50%;
            top: 50%;
            position: absolute;
            max-width: 100%; } } }

.word_like {
	display: block;
	h2 {
		margin: 60px auto 20px;
		font-size: 18px;
		color: #333333;
		line-height: 28px;
		text-align: center; }
	.word_like_item {
		border-top: 1px solid #e5e5e5;
		padding-top: 30px;
		padding-bottom: 30px;
		.item_tit {
			font-size: 20px;
			color: #333333;
			line-height: 32px;
			margin-bottom: 12px {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; } }
		.item_des {
			font-size: 13px;
			color: #808080;
			text-align: left;
			line-height: 19px; } } }


