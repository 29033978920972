.message-container {
    width: 700px;
    text-align: left;
    .top {
        margin: 152px 0 0;
        .title {
            @include fs(24);
            line-height: 25px;
            margin-bottom: 50px; } }
    .notification-cont {
        margin-bottom: 70px;
        .load-more {
            margin-top: 0;
            padding-top: 50px;
            border-top: 1px #f2f2f2 solid;
            text-align: center; } }
    .message-list {
        // margin-bottom: 50px
        border-top: 1px #E5E5E5 solid;
        padding-top: 5px;

        li {
            border-bottom: 1px #f2f2f2 solid;
            position: relative;
            padding: 20px 15px;
            &:last-child {
                border-bottom: none; } }
        .un-read {
            .disc {
                display: inline-block;
                *display: inline;
                width: 6px;
                height: 6px;
                background-color: $main;
                position: absolute;
                @include rounded(50%);
                left: 0;
                top: 27px; } }
        .reminder {
            font-size: 16px;
            font-size: 1.6rem;
            color: #b3b3b3;
            line-height: 21px;
            text-align: center;
            margin-top: 60px; }
        .cont {
            @include fs(14);
            margin-bottom: 8px;
            color: #333;
            a {
                color: $orange;
                &:hover {
                    color: $orange-hover; } } }
        .info {
            color: #B3B3B3;
            @include fs(13);
            line-height: 16px; }
        .comment {
            position: relative;
            margin-bottom: 11px;
            margin-top: 15px;
            padding: 15px 20px;
            color: #808080;
            background-color: #F6F6F6;
            @include fs(14);
            .triangle {
                position: absolute;
                display: inline-block;
                *display: inline;
                border: 15px solid transparent;
                border-bottom-color: #F6F6F6;
                top: -25px; } } } }


