.msg {
	display: none;
	border: 1px solid;
	border-radius: 4px;
	position: relative;
	padding: 8px 30px 8px 15px;
	@include fs(13);
	.btn-close {
		border: none;
		background: none;
		position: absolute;
		right: 2%;
		font-size: 1.2em;
		top: 0.35em; }
	&.danger {
		display: block;
		background-color: #FFF2F2;
		border-color: #FFDBDB;
		color: #D55050; }
	&.success {
		display: block;
		background-color: #F0F7E6;
		border-color: #DDE7D2;
		color: #76A238; } }


.tooltip {
	z-index: 999999;
	position: absolute;
	top: 0;
	left: 0;
	.inner,.text {
		@include fs(14);
		@include rounded(5px);
		padding: 8px 18px;
		color: #fff;
		line-height: 26px;
		background-color: #383838; }
	.swd {
		position: absolute;
		left: 50%;
		bottom: -18px;
		margin-left: -10px;
		border-width: 10px;
		border-color: #383838 transparent transparent transparent;
		border-style: solid; } }
.bottom-tip {
	position: fixed;
	top: 103px;
	.swd {
		bottom: initial;
		top: -18px;
		border-color: transparent transparent #383838 transparent; } }



.global-msg-mobile {
	position: absolute;
	padding: 5%;
	top: 63px;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	z-index: 10;
	text-align: center;
	background-color: #F6F1EA;
	a {
		color: $orange;
		margin-left: 5px; }
	span {
		float: right;
		@include fs(24);
		color: $orange;
		position: absolute;
		top: 6px;
		right: 10px;
		width: 30px; } }

.msg-mobile {
	position: fixed;
	background-color: rgba(0,0,0,0.8);
	color: white;
	z-index: 9999;
	width: 80px;
	height: 80px;
	left: 50%;
	top: 50%;
	border-radius: 10px;
	vertical-align: middle;
	text-align: center;
	transform: translate(-50%,-50%);
	opacity: 1;
	animation: show 2s ease 0 alternate;
	i {
		font-size: 26px;
		line-height: 45px;
		margin-top: 5px;
		display: block; }
	&.no-icon {
		p {
			line-height: 80px; } } }

@-webkit-keyframes show {
	10% {
		opacity: 1; }
	90% {
		opacity: 1; }
	100% {
		opacity: 0; } }
