
.set-inner {
    position: absolute;
    border: 1px solid #D8D8D8;
    padding: 15px 0 0;
    background-color: #fff;
    @include rounded(5px);
    a {
        color: $c_color;
        &:hover {
            color: $s_color; } }
    li {
        padding: 0 25px;
        line-height: 25px;
        margin-bottom: 15px;
        @include fs(14); }
    .d-bd {
        width: 0;
        position: absolute;
        display: inline-block;
        *display: inline;
        border-width: 10px;
        border-style: solid;
        border-color:  transparent transparent #D8D8D8 transparent;
        &:after {
            left: -8px;
            top: -6px;
            position: absolute;
            content: ' ';
            display: inline-block;
            *display: inline;
            border-width: 8px;
            border-style: solid;
            border-color:  transparent transparent #fff transparent; } } }
.common-nav {
    a {
        color: #666666;
        &:hover {
            color: $s_color; } }
    li {
        padding: 10px 25px;
        @include fs(14); } }


.android-nav {
    width: 150px;
    li {
        width: 170px;
        margin-left: -10px;
        padding: 10px 0px;
        text-align: center;
        &:hover {
            background-color: #21B890;
            a {
                color: #fff; }
            a:hover {
                color: #fff; } } } }

.qrcode-cont {
    text-align: center;
    padding: 10px;
    h4 {
        @include fs(18);
        line-height: 19px;
        margin-bottom: 9px; } }


.tag-nav {
    width: 400px;
    padding: 15px 15px 0;
    line-height: 15px;
    a {
        vertical-align: middle;
        display: inline-block;
        *display: inline;
        padding-right: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        color: $c_color;
        &:hover {
            color: $s_color; }
        &.last,&:last-child {
            border: none; } } }





// 头部

.p-header-hide {
    -webkit-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -moz-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -o-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190); }

.p-header div.second-nav-small {
    height: 49px;
    line-height: 50px;
    background-color: #F3F5F6;
    -webkit-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -moz-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -o-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    .logo {
        display: none; }
    .columns {
        line-height: 50px;
        .column-icon {
            display: inline-block;
            *display: inline; }
        .current-read .tit {
            margin-top: 12px; } }
    .sort-by {
        .tit {
            margin-top: 12px; } }
    .column-title {
        margin-right: 92px; } }
.p-header div.second-nav-large {
    height: 60px;
    line-height: 60px;
    background-color: #fff;
    -webkit-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -moz-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -o-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    .logo {
        display: inline-block;
        *display: inline; }
    .columns {
        line-height: 61px; } }
.p-header-show {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000); }
.p-header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100px;
    color: #909090;
    .first-nav {
        white-space: nowrap;
        background-color: #F3F5F6;
        color: #666666;
        height: 40px;
        line-height: 40px;
        padding: 0 50px;
        ul,li {
            display: inline-block;
            *display: inline; }
        li {
            margin-right: 20px; }
        li.last {
            margin-right: 60px; }
        a {
            color: #666666;
            @include fs(14);
            &.nav_title {
                @include fs(16); } }
        .left-c {
            width: 540px; }
        nav,.nav-search {
            display: inline-block;
            *display: inline; }
        .nav-search {
            vertical-align: top;
            input {
                background-color: #F8F8F8;
                border: 1px #DBDBDB solid;
                @include rounded(4px);
                height: 20px;
                padding: 2px 5px;
                width: 100px; }
            .search {
                position: relative;
                left: -30px;
                @include fs(15);
                vertical-align: middle;
                border: none;
                padding: 0;
                background-color: transparent;
                i {
                    // color: #b3b3b3
                    color: #D8D8D8;
                    &:hover {
                        color: #808080; } } } }
        .options {
            .btn {
                color: #fff; } }
        .current {
            // a
            //     color: $orange
            //     &:hover
            //         color: $orange-hover
            a {
                color: #FFE6A0;
                &:hover {
                    color: #FFE6A0; } } }
        .get_app {
            margin-left: 20px;
            margin-right: 0px;
            cursor: pointer;
            display: inline-block;
            position: relative;
            color: #b3b3b3;
            // color: #ffffff
            i {
                vertical-align: middle;
                color: $main_blue;
                // color: #ffffff
                margin-right: 10px;
                font-size: 20px; }
            .app_qrcode {
                position: absolute;
                top: 55px;
                right: 0px;
                background-color: transparent;
                width: 306;
                height: 209px;
                .qrcode_box {
                    width: 306px;
                    height: 209px;
                    background-image: url(img/header_wexin.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    margin-top: 25px;
                    padding: 18px 24px;
                    box-sizing: border-box;
                    text-align: center;
                    .code_img {
                        display: inline-block;
                        margin-top: 15px;
                        margin-bottom: 12px;
                        box-shadow: 0 0 4px 0 rgba(0,164,160,0.2); }
                    .code_box {
                        display: inline-block; }
                    .a_tit {
                        margin: 0;
                        padding: 0;
                        line-height: 18px;
                        @include fs(13);
                        color: #333333;
                        &.down {
                            @include fs(12);
                            // &::before, &::after
                            //     content: ""
                            //     display: inline-block
                            //     width: 4px
                            //     height: 4px
                            //     background-color: #02A4A0
                            //     margin: 0 5px
                            //     border-radius: 50%
 } } } } } }                            //     vertical-align: middle

    .second-nav {
        height: 60px;
        background-color: #fff;
        line-height: 60px;
        overflow: visible;
        border-bottom: 1px solid #E5E5E5;
        .shares {
            .txt {
                @include fs(14);
                color: #808080;
                margin-right: -5px; }
            [class^="circle"] {
                margin-left: 15px;
                border: 1px #000 solid;
                @include rounded(50%);
                display: inline-block;
                *display: inline;
                width: 40px;
                height: 40px;
                text-align: center;
                i {
                    line-height: 40px;
                    @include fs(18); } }
            .circle-weibo {
                line-height: 40px;
                border-color: #e74c3c;
                i {
                    color: #e74c3c; } }
            .circle-wechat {
                line-height: 40px;
                border-color: #44B549;
                i {
                    color: #8ee566; } }
            .circle-tencent {
                line-height: 40px;
                border-color: #39b2e2;
                i {
                    color: #39b2e2; } } }
        .dropdown-menu-part {
            .dropdown-menu {
                right: 0px;
                padding: 3px 0px;
                line-height: 32px;
                @include rounded(3px);
                a {
                    display: inline-block;
                    *display: inline;
                    width: 86px;
                    text-align: center;
                    padding-right: 0;
                    margin-right: 0;
                    color: #666666;
                    &:hover {
                        color: $main; } }
                .current {
                    color: $main; }
                &::before {
                    right: calc(50% - 24px);
                    right: -webkit-calc(50% - 24px);
                    right: -moz-calc(50% - 24px); } } }
        .sort-by {
            .tit {
                width: 190px;
                color: gray;
                text-align: center;
                @include fs(13);
                border: 1px solid #d9d9d9;
                line-height: 25px;
                margin-top: 18px;
                @include rounded(3px);
                cursor: pointer; }
            a {
                width: 175px!important;
                text-align: left!important;
                padding: 0 15px; }

            i {
                @include fs(18);
                vertical-align: middle;
                margin-left: 5px; }
            .dropdown-menu {
                &::before {
                    right: calc(50% - 78px);
                    right: -webkit-calc(50% - 78px);
                    right: -moz-calc(50% - 78px); } } }
        .tag-sort-by {
            .tit {
                width: 160px; }
            a {
                width: 146px!important;
                padding: 0 15px; }
            .dropdown-menu {
                &::before {
                    right: calc(50% - 61px);
                    right: -webkit-calc(50% - 61px);
                    right: -moz-calc(50% - 61px); } } }

        .more-tag,.more-product {
            .more-btn {
                .tit {
                    color: #666666;
                    @include fs(14);
                    cursor: pointer;
                    margin-left: 20px;
                    i {
                        @include fs(20);
                        vertical-align: middle; } } }
            .more-product-dropdown {
                padding: 30px 30px 20px;
                width: 390px;
                right: -358px;
                ul {
                    margin-left: -33px;
                    li {
                        display: inline-block;
                        *display: inline;
                        margin-left: 33px; }
                    a {
                        width: 70px;
                        text-align: left; } }
                &::before {
                    right: calc(50% + 142px); } }
            .more-tag-dropdown {
                width: 244px;
                padding: 26px 20px 25px 40px;
                right: -125px;
                .more-tag-nav {
                    display: inline-block;
                    *display: inline;
                    text-align: left;
                    margin-bottom: 10px;
                    vertical-align: top;
                    &:first-child {
                        margin-right: 45px; }
                    .tit {
                        color: #666666;
                        display: inline-block;
                        *display: inline;
                        text-align: center;
                        @include fs(14);
                        padding-bottom: 10px;
                        border-bottom: 2px $main solid;
                        margin-bottom: 7px;
                        line-height: 14px; }
                    ul {
                        a {
                            text-align: left; } } }
                .more-a {
                    line-height: normal;
                    a {
                        color: #B3B3B3;
                        @include fs(12);
                        line-height: normal;
                        &:hover {
                            color: #666; } }
                    .icon {
                        margin-left: 5px;
                        @include fs(15); } } } }
        .more-trail {
            .more-product-dropdown {
                padding: 20px 0px 20px;
                width: 110px;
                left: -12px;
                right: inherit;
                ul {
                    margin: 0;
                    li {
                        margin: 0;
                        display: block;
                        text-align: center; }
                    a {
                        width: auto;
                        padding: 0 25px; } } }
            .dropdown-menu::before {
                right: calc(50% - 36px); } } }
    .inner {
        width: 980px;
        margin: 0 auto;
        white-space: nowrap; }
    .menu {
        display: inline-block;
        *display: inline;
        margin-left: 11px;
        position: relative;
        .bar {
            vertical-align: middle;
            display: inline-block;
            *display: inline;
            cursor: pointer;
            color: #353A48;
            @include fs(20);
            i {
                @include fs(22);
                position: relative;
                right: -4px;
                top: 2px; } }
        .set-inner {
            width: 137px;
            left: -60px;
            top: 55px;
            .d-bd {
                top: -20px;
                right: 58px; } } }
    .line {
        height: 25px;
        display: inline-block;
        *display: inline;
        vertical-align: middle;
        margin: 0 15px;
        border-right: 1px solid #E5E5E5; }
    .columns {
        line-height: 61px;
        a {
            color: $black; }
        a,.column-title {
            @include fs(14); }
        .read-select {
            margin-right: 45px;
            margin-left: -6px; }
        .orange {
            color: $orange; }
        .unfollow {
            color: #fff; }
        .draft {
            .num {
                color: #666; } }
        .current-read {
            .tit {
                color: #808080;
                text-align: center;
                @include fs(13);
                border: 1px solid #D9D9D9;
                line-height: 25px;
                margin-top: 18px;
                @include rounded(3px);
                width: 86px; }
            i {
                @include fs(18);
                vertical-align: middle;
                margin-left: 5px; } }
        .column-title {
            color: #3A3E3F;
            margin: 0 40px 0 20px;
            @include fs(18);
            span {
                color: #b3b3b3; }
            .tag {
                color: $black; }
            a {
                color: $orange;
                &:hover {
                    color: $orange_hover; } } }
        .column-second-title {
            color: #333333;
            @include fs(14);
            margin-right: 35px; }
        .tag-title {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis; }
        .column-icon {
            display: none;
            margin-right: 3px;
            width: 22px;
            i {
                color: $main;
                @include fs(18);
                position: relative;
                top: 0px;
                vertical-align: middle; }
            .icon-tag2 {
                @include fs(18); }
            .icon-iconfont-present,.icon-bell2 {
                @include fs(18); }
            .icon-help2 {
                @include fs(22); }
            .icon-globe {
                @include fs(18); }
            .icon-read {
                @include fs(18); } }

        .tag-recommend {
            width: 555px;
            text-overflow: ellipsis;
            overflow: hidden; }
        .column-list {
            display: inline-block {
                *display: inline; }
            .tags {
                position: relative;
                i {
                    @include fs(20);
                    color: #b3b3b3;
                    vertical-align: middle;
                    position: relative;
                    top: -1px;
                    left: 2px; }
                .set-inner {
                    width: 400px;
                    padding: 25px;
                    line-height: 15px;
                    top: 50px;
                    left: 50%;
                    margin-left: -230px;
                    .d-bd {
                        top: -20px;
                        left: 50%;
                        margin-left: -5px; }
                    a {
                        vertical-align: middle;
                        @include fs(13);
                        display: inline-block;
                        *display: inline;
                        border-right: 1px solid #D7D7D7;
                        margin-right: 24px;
                        margin-bottom: 15px;
                        &:hover {
                            color: $s_color; }
                        &.last,&:last-child {
                            border: none; } } }
                .visible {
                    display: block; } }

            li {
                display: inline-block;
                *display: inline;
                margin-right: 18px;
                .num {
                    color: $s_color; }
                .nums {
                    background-color: $orange;
                    display: inline-block;
                    *display: inline;
                    *display: inline;
                    line-height: 17px;
                    @include fs(12);
                    @include rounded(50px);
                    transform: scale(0.9,0.9);
                    *font-size: 10;
                    color: #fff;
                    padding: 0 5px;
                    position: relative;
                    top: -1px; }
                a,.a_dropdown {
                    position: relative;
                    display: block;
                    color: #666666;
                    padding: 0 6px;
                    &:hover,&.current {
                        // border-bottom: 3px solid $s_color
                        // line-height: 58px
                        // padding-bottom: 0
                        .avia-menu-fx {
                            opacity: 1;
                            visibility: visible; } } }
                .a_dropdown {
                    .tit {
                        margin-left: 0; } }
                .avia-menu-fx {
                    position: absolute;
                    bottom: 0px;
                    height: 3px;
                    z-index: 2;
                    width: 100%;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    -moz-transition: all 0.3s ease-out;
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                    background-color: $main_blue; } } }

        .nav-list li {
            @include fs(13);
            a {
                color: #666666; }

            &:hover,&.current {
                a,.a_dropdown a .tit {
                    color: $orange; }
                .dropdown-menu a {
                    color: #666666; }
                .num {
                    color: $orange; } }
            span.num {
                margin-left: 3px;
                color: #666; }
            .dropdown-menu {
                li:hover,li.current {
                    a {
                        color: $orange; } } } }
        .info-list li {
            color: #808080;
            @include fs(13);
            span.num {
                color: #808080; } }
        .products-list {
            max-width: 595px; } }

    .draft {
        color: $s_color;
        vertical-align: middle;
        @include fs(14);
        .num {
            color: $i_color;
            margin: 0 5px;
            strong {
                font-weight: normal;
                margin: 0 3px; } } }
    .options {
        @include fs(14);
        @extend .fr;
        color: $i_color;
        // .search
        .topic {
            margin-right: 70px;
            display: inline-block;
            *display: inline;
            .txt {
                display: inline-block;
                *display: inline;
                *display: inline;
                vertical-align: middle;
                width: 102px;
                height: 30px;
                line-height: 28px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
            .ico {
                @include fs(20);
                color: #b3b3b3;
                vertical-align: middle;
                position: relative;
                top: -1px;
                left: 2px; } }
        .autosave {
            display: inline-block;
            *display: inline;
            // width: 110px
            margin-right: 20px; }
        .confrim-post_open {
            margin-right: 10px; }
        .post-edit {
            @include fs(14);
            margin: 0 15px;
            background: transparent;
            border: 1px solid #C5002B;
            color: #C5002B !important;
            border-radius: 0; }
        .unlogin {
            color: #b3b3b3;
            a {
                color: #b3b3b3;
                &:hover {
                    color: #808080;
                    text-decoration: underline; } }
            .line {
                margin: -3px 5px 0;
                height: 15px;
                vertical-align: middle; }
            .en {
                font-weight: normal;
                color: #424242; }
            .cn {
                font-weight: bold; } }
        .setting {
            display: inline-block;
            *display: inline;
            position: relative;

            .avatar {
                position: relative;
                top: -1px;
                img {
                    @include rounded(50%); }
                .new {
                    display: inline-block;
                    *display: inline;
                    position: absolute;
                    right: -1px;
                    top: -6px;
                    width: 8px;
                    height: 8px;
                    background-color: $orange;
                    border: 1px #fff solid;
                    @include rounded(50%); } } }
        .pro-sign {
            display: inline-block;
            *display: inline;
            border-radius: 50%;
            position: absolute;
            right: -2px;
            bottom: 9px; }
        .set-inner {
            // width: 150px
            // right: 0
            // top: 68px
            // margin-top: -8px
            // .d-bd
            //     top: -20px
            //     right: 8px
            // .nums
            //     @include rounded(5px)
            //     background-color: $s_color
            //     display: inline-block
            *display: inline;
            //     height: 16px
            //     line-height: 17px
            //     color: #fff
            //     padding: 0 2px
            //     @include fs(12)
            //     margin-left: 10px
            // li
            //     &.last
            //         margin-bottom: 0
            //         height: 40px
            //         line-height: 40px
            //         background-color: #F9f9f9
 }            //         color: #b3b3b3

        .topic-select {
            top: initial;
            // bottom: 52px
            position: fixed;
            // width: 220px
            width: 360px;
            .d-bd {
                top: inherit;
                bottom: -20px;
                border-color: #D8D8D8 transparent transparent transparent;
                &:after {
                    top: -10px;
                    border-color: #fff transparent transparent transparent; } } } }


    .pop-close {
        cursor: pointer; }
    .pop-select {
        padding: 0;
        // overflow: hidden
        left: 0px;
        display: none;
        top: 95px;
        color: #666;
        // max-height: 264px
        // overflow-y: auto
        background-color: #fff;
        .d-bd {
            margin-left: -20px;
            left: 50%;
            top: -20px; }
        li {
            word-break: break-all;
            white-space: initial;
            padding: 15px 30px;
            border-bottom: 1px solid #F2F2F2;
            cursor: pointer;
            line-height: 22px;
            margin-bottom: 0;
            &:hover,&.hover {
                color: #fff;
                background-color: $s_color; }
            &:last-child {
                border: none; }
            &.last {
                border-bottom: 0;
                background-color: #F9F9F9;
                text-align: center;
                color: $i_color; } } }
    .draft-select {
        width: 360px; } }
.about-management {
    .list-page {
        width: 1100px; }
    .cont {
        ul.person {
            margin-right: -20px;
            font-size: 0;
            margin-bottom: 40px;
            .on {
                background-image: none;
                .name {
                    color: #ffffff; }
                .tit {
                    color: #ffffff; } }
            li {
                display: inline-block;
                width: 260px;
                height: 330px;
                border: 1px solid rgba(198,200,206,0.50);
                box-sizing: border-box;
                text-align: center;
                padding: 40px 32px 0;
                background: #C5002B url('images/ffff.png') repeat-x 0 120px;
                margin-right: 20px;
                vertical-align: top;
                cursor: pointer;
                &:hover {
                    background-image: none;
                    img {
                        transform: scale(1.1);
                        transition: all 0.5s linear; }
                    .name {
                        color: #ffffff; }
                    .tit {
                        color: #ffffff; } }

                // flex: none
                img {
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    margin-bottom: 30px; }
                .name {
                    font-size: 20px;
                    // color: #FFFFFF
                    color: #333333;
                    line-height: 23px;
                    margin-bottom: 18px; }
                .tit {
                    text-align: center;
                    font-size: 16px;
                    // color: #ffffff
                    color: #808080;
                    line-height: 24px;
                    margin-bottom: 0; } } }
        ul.introduction {
            margin-bottom: 40px;
            li {
                display: none;
                padding: 30px 40px;
                border: 1px solid #E3E4E7;
                box-shadow: 0 0 6px 0 rgba(0,164,160,0.4);
                .name {
                    font-size: 28px;
                    color: #C5002B;
                    line-height: 34px;
                    margin-bottom: 15px; }
                .text {
                    margin-bottom: 15px; } } } } }


.wrapper {
    margin-top: 115px;
    &.no_second_nav {
        margin-top: 54px; } }

.p-footer {
    @include fs(13);
    color: #909090;
    a {
        margin: 0 10px;
        color: #808080;
        &:hover {
            // text-decoration: underline
            color: #666666; } }
    padding: 50px 0;
    text-align: center;
    border-top: 1px solid #e5e5e5;
    .social {
        a {
            &:hover {
                color: $s_color;
                text-decoration: none; }
            display: inline-block;
            *display: inline;
            width: 20px;
            height: 20px;
            color: #D7D7D7;
 }            // background-color: #D7D7D7
        i {
            @include fs(18); } }

    .about {
        width: 1020px;
        margin: 0 auto;
        margin-bottom: 15px;
        a {
            margin-bottom: 15px;
            display: inline-block; }
        .line {
            height: 14px;
            line-height: 20px;
            vertical-align: middle;
            margin-top: -2px;
            display: inline-block;
            *display: inline;
            border-left: 1px solid #D7D7D7; } }
    .info {
        margin-bottom: 26px;
        color: #323232;
        line-height: 20px; }
    .contant_box {
        width: 945px;
        margin: 0 auto;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between; }
    .discover_tmt,.collaboration_box {
        h5 {
            font-size: 14px;
            color: #666666;
            margin-bottom: 20px;
            text-align: left; }
        .text_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            a {
                display: inline-block;
                margin-bottom: 15px;
                margin-left: 0;
                font-size: 13px;
                color: #808080; } } }
    .us_box {
        h5 {
            font-size: 14px;
            color: #666666;
            margin-bottom: 20px;
            text-align: left; }
        .text_box {
            display: flex;
            a,img {
                width: 23px;
                height: 23px;
                display: inline-block;
                margin-left: 0; } } }
    .we_img_box {
        display: flex;
        margin-top: 60px;
        .img_box {
            position: relative; }
        .img_box,img {
            width: 100px;
            height: 100px;
            img {
                box-shadow: 0 0 4px 0 rgba(0,164,160,0.20);
                margin-bottom: 22px; }
            span {
                font-size: 13px;
                color: #808080; } }
        .taikong {
            margin-left: 38px; }
        .hover_text {
            background: rgba(56,56,56,0.95);
            box-shadow: 0 -2px 2px 0 rgba(0,0,0,0.10);
            border-radius: 5px;
            padding: 12px 15px;
            position: absolute;
            z-index: 10;
            top: -78px;
            left: 50%;
            margin-left: -115px;
            p {
                font-size: 13px;
                color: #ffffff;
                line-height: 17px;
                white-space: nowrap; }
            .three {
                border-top: 10px solid rgba(56,56,56,0.95);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                width: 0;
                position: absolute;
                bottom: -10px;
                right: 50%;
                margin-right: -10px; } }
        .hover_tmt {
            cursor: pointer; } }
    .flex_bottom_menu {
        position: fixed;
        right: 1%;
        bottom: 10%;
        z-index: 9;
        ul {
            li {
                width: 50px;
                height: 50px;
                background: rgba(0,164,160,.8);
                border-radius: 50%;
                margin-bottom: 10px;
                &>a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto; }
                &.English {
                    img {
                        width: 20px;
                        height: 13px; } }
                &.xiegao {
                    img {
                        width: 15px;
                        height: 16px; } }
                &.erweima {
                    position: relative;
                    img.app {
                        width: 16px;
                        height: 16px; }
                    .get_app {
                        color: #b3b3b3;
                        .app_qrcode {
                            position: absolute;
                            top: -50%;
                            right: 60px;
                            background-color: transparent;
                            width: 306px;
                            height: 209px;
                            .qrcode_box {
                                width: 306px;
                                height: 209px;
                                background-image: url(img/header_wexin.png);
                                background-size: 100%;
                                background-repeat: no-repeat;
                                padding: 18px 24px;
                                box-sizing: border-box;
                                text-align: center;
                                .code_img {
                                    display: inline-block;
                                    margin-top: 15px;
                                    margin-bottom: 12px;
                                    box-shadow: 0 0 4px 0 rgba(0,164,160,0.2); }
                                .code_box {
                                    display: inline-block; }
                                .a_tit {
                                    margin: 0;
                                    padding: 0;
                                    line-height: 18px;
                                    @include fs(13);
                                    color: #333333;
                                    &.down {
                                        @include fs(12); } } } } } } } } } }

.p-header-login {
    margin: 100px 0 50px;
    border-top: 1px solid #f2f2f2;
    text-align: center;
    .logo {
        top: -16px;
        position: relative;
        display: inline-block;
        *display: inline;
        width: 132px;
        height: 30px;
        background: #fff url(img/tmt_logo@2x.png) center center no-repeat;
        -webkit-background-size: 92px 30px;
        background-size: 92px 30px; } }
.m-header {
    height: 64px;
    line-height: 64px;
    // margin-bottom: 4%
    background-color: #FFF;
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        display: block;
        // border-bottom: 1px solid #E5E5E5
        width: 92%;
        margin: 0 auto; }
    .back {
        padding-left: 4%;
        @include fs(16);
        color: #333; }
    h2.tc {
        margin-top: -64px;
        color: #666666;
        @include fs(21); }
    .return {
        padding-left: 4%;
        color: #000;
        @include fs(16);
        position: absolute;
        top: 23px;
        left: 5px;
        i {
            color: #666; }
        strong {
            position: relative;
            top: -2px;
            left: 5px;
            font-weight: normal;
            color: #666; } } }
.m-footer {
    padding: 8% 5% 5%;
    a {
        color: #b3b3b3; }
    .forget {
        text-align: right; } }


.c-page {
    width: 700px;
    margin: 0 auto;
    padding: 40px 0;
    color: #808080; }
.dropdown-menu-part {
    position: relative;
    display: inline-block;
    *display: inline;
    white-space: normal; }
.dropdown-menu,.dropup-menu {
    padding: 15px 20px;
    position: absolute;
    top: 54px;
    background: #fff;
    border: 1px solid rgba(26,53,71,.12);
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(26,53,71,.1);
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    zoom: 1;
    pointer-events: none;
    // -webkit-transform: scale(.8) translateY(-30%)
    transition: .25s cubic-bezier(.3, 0, 0, 1.3);
    &::before {
        content: "";
        position: absolute;
        top: -7px;
        right: calc(50% - 9px);
        right: -webkit-calc(50% - 9px);
        right: -moz-calc(50% - 9px);
        width: 13px;
        height: 7px;
        z-index: 4;
        background: url(/public/css/img/dropdown-menu-arrow.svg); }
    &.user-dropdown {
        &::before {
            left: 10px; } }
    &.visible {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: alpha(opacity=100);
        zoom: 1;
        pointer-events: auto;
        -webkit-transform: none;
        transform: none;
        z-index: 3; } }
.dropdown-menu {
    -webkit-transform: scale(.8) translateY(-30%); }
.dropup-menu {
    -webkit-transform: scale(.8) translateY(30%); }
.user-dropdown {
    width: 154px;
    padding: 10px 0 0;
    right: 35px;
    li {
        padding: 0 0 0 25px;
        line-height: 42px;
        color: #666666;
        a {
            color: #666666; }
        &.last {
            padding: 0 25px;
            height: 40px;
            line-height: 42px;
            background-color: #F9f9f9;
            color: #b3b3b3;
            width: 104px;
            margin-right: 0; }
        i {
            margin-right: 10px;
            vertical-align: middle;
            position: relative;
            top: -1px; }
        .icon-wealth {
            // font-size: 13px
            @include fs(12);
            margin-right: 8px; }
        .nums {
            background-color: $orange;
            line-height: 16px;
            @include fs(12);


            @include rounded(50px);
            color: #fff;
            margin-left: 10px;
            padding: 0 5px; } } }
.main-dropdown {
    padding: 10px 0 10px 0;
    width: 137px;
    left: -45px;
    li {
        line-height: 42px;
        padding: 0 0 0 25px;
        color: #666666; }
    i {
        margin-right: 10px;
        @include fs(16);
        display: inline-block;
        *display: inline;
        vertical-align: middle;
        margin-top: -3px; }
    .icon-iconfont-present {
        @include fs(17); }
    .icon-read {
        font-size: 14px; } }
.weixin-dropdown {
    width: 500px;
    left: -250px;
    bottom: 30px;
    top: initial;
    &::before {
        top: initial;
        bottom: -7px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); } }
.qq-dropdown,.wx-dropdown {
    width: 115px;
    left: -59px;
    bottom: 30px;
    top: initial;
    &::before {
        top: initial;
        bottom: -7px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); } }
.share-dropdown {
    width: 150px;
    right: -13px;
    bottom: 45px;
    top: initial;
    a {
        line-height: 20px;
        font-size: 14px !important;
        font-size: 1.4rem !important; }
    &::before {
        top: initial;
        bottom: -7px;
        right: 14px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); } }



//导航改版
//@author hopekayo@gmail.com
//@DateTime 2016-04-21T15:52:45+0800
.new-header {
    height: auto;
    .user-nav {
        li {
            a {
                color: #666666; } } }
    .first-nav {
        height: 55px;
        line-height: 54px;
        border-bottom: 1px solid #E5E5E5;
        background-color: #ffffff;
        padding:  0 50px;
        li {
            vertical-align: top;
            position: relative; }
        a {
            color: #424242;
            font-weight: bold; }
        .current {
            a {
                color: #C5002B;
                &:hover {
                    color: #C5002B; } } }
        .down-nav {
            margin: 0 3px;
            i {
                vertical-align: middle;
                @include fs(12);
                display: inline-block;
                *display: inline;
                transform: scale(.6); }
            i.hover {
                animation: turnl 0.3s linear 0s 1 normal running forwards; }
            i.hover-out {
                animation: turnr 0.5s linear 0s 1 normal running forwards; } }
        .read-select,.tmtbase-select {
            .read-dropdown,.tmtbase-dropdown {
                padding: 15px 0; }
            .read-nav,.tmtbase-nav {
                line-height: 32px; }
            li {
                text-align: center;
                vertical-align: middle;
                width: 95px;
                margin: 0;
                padding: 0 4px;
                letter-spacing: 3px;
                &:last-child {
                    letter-spacing: 1px; }
                a {
                    font-weight: normal;
                    color: #666; }
                a:hover,a.current {
                    color: $orange; } } }
        .tmtbase-select {
            .tmtbase-dropdown {
                left: 49px; }
            .brand-dropdown {
                left: 29px; }
            .study-dropdown {
                left: -9px;
                .tmtbase-nav {
                    ul {
                        >li {
                            width: 170px; } } } } } }
    .logo-part {
        margin-right: 30px;
        display: inline-block;
        *display: inline; }
    .second-nav {
        .new {
            position: absolute;
            top: -15px;
            right: 3px; }

        .columns {
            // padding-left: 50px
            .all {
                margin-right: 20px;
                a {
                    text-align: center;
                    @include rounded(50%);
                    background-color: #333;
                    display: inline-block;
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    color: #fff;
                    @include fs(12); } }
            .tag-recommend {
                width: 580px; } } }
    div.second-nav-large {
        line-height: 60px;
        height: 60px;
        .columns {
            line-height: 60px; } }
    .columns .read-select {
        margin-right: 45px;
        margin-left: 0px; }
    &.p-header-hide {
        -webkit-transform: translateY(-54px);
        -ms-transform: translateY(-54px);
        transform: translateY(-54px); } }
.container {
    .event-container .top-events {
        margin-top: 55px;
        padding-top: 0; } }




//导航改版-2
//@DateTime 2016-08-23T14:07:45+0800
.v1_header {
    .first-nav {
        padding: 0 50px;
        background-color: #171717;
        height: 62px;
        line-height: 62px;
        border-bottom: none;
        a {
            color: #b3b3b3;
            font-weight: bold; }

        .logo-part {
            margin-right: 75px; }
        nav li {
            margin-right: 50px;
            vertical-align: top;
            a {
                border-bottom: 3px solid #171717;
                display: inline-block;
                line-height: 56px; }
            &.current,&:hover {
                a {
                    border-bottom: 3px solid $main_current;
                    color: $main_current; } }
            .read-nav {
                a {
                    border-bottom: 0;
                    color: #666; } } }
        .read-select li {
            a {
                border-bottom: none;
                line-height: normal; }
            &.current,&:hover {
                a {
                    border-bottom: none;
                    color: #2cbe99; } } } }

    .options {
        font-size: 14px;
        .post-edit {
            @include rounded(0);
            border-color: $main_current !important;
            color: $main_current !important;
            line-height: 30px;
            padding: 0 16px;
            background-color: transparent; }
        .line {
            border-color: #b3b3b3;
            height: 14px; } }


    .down-nav {
        margin: 0 2px;
        i {
            vertical-align: middle;
            @include fs(12);
            display: inline-block;
            *display: inline;
            transform: scale(.6); }
        i.hover {
            animation: turnl 0.3s linear 0s 1 normal running forwards; }
        i.hover-out {
            animation: turnr 0.5s linear 0s 1 normal running forwards; } }
    .read-select,.tmtbase-select {
        .read-dropdown,.tmtbase-dropdown {
            padding: 15px 0; }
        .read-nav,.tmtbase-nav {
            line-height: 32px; }
        li {
            text-align: center;
            vertical-align: middle;
            width: 75px;
            margin: 0;
            padding: 0 4px;
            letter-spacing: 3px;
            &:last-child {
                letter-spacing: 1px; }
            a {
                font-weight: normal;
                color: #666; }
            a:hover,a.current {
                color: $orange; } } }
    .tmtbase-select {
        .tmtbase-dropdown {
            left: 33px; } }
    .logo-part {
        margin-right: 30px;
        display: inline-block;
        *display: inline; }
    .second-nav {
        .new {
            position: absolute;
            top: -15px;
            right: 3px; }

        .columns {
            // padding-left: 50px
            .all {
                margin-right: 20px;
                a {
                    text-align: center;
                    @include rounded(50%);
                    background-color: #333;
                    display: inline-block;
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    color: #fff;
                    @include fs(12); } }
            .tag-recommend {
                width: 580px; } } }
    div.second-nav-large {
        line-height: 60px;
        height: 60px;
        .columns {
            line-height: 60px; } }
    .columns .read-select {
        margin-right: 45px;
        margin-left: 0px; }
    &.p-header-hide {
        -webkit-transform: translateY(-62px);
        -ms-transform: translateY(-62px);
        transform: translateY(-62px); } }
// 2019.1.6pc导航修改
.tmtbase-dropdown_new {
    padding: 20px 30px !important;
    // pointer-events: none !important
    &:before {
        right: calc(90% - 9px);
        right: -webkit-calc(90% - 9px);
        right: -moz-calc(90% - 9px); }
    .flex_box_tmtbase-nav {
        display: flex;
        flex-direction: column; }
    .tmtbase-nav_new_tit {
        font-size: 14px;
        color: #666666;
        padding-bottom: 10px;
        margin-bottom: 15px;
        position: relative;
        font-weight: bold;
        &:before {
            content: "";
            display: flex;
            width: 65%;
            height: 2px;
            background: #2AC7B4;
            position: absolute;
            bottom: 0; } }
    .tmtbase-nav_new {
        display: flex;
        li {
            width: 100px !important;
            letter-spacing: 0 !important;
            text-align: left !important;
            padding: 0 !important;
            a {
                font-size: 13px !important; } } } }
// 新年pc改版
.pc-index {
    .top_posts {
        position: relative;
        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            left: 36px;
            top: 50%;
            width: 110px;
            background: url('/public/img/index/2020_1.png');
            background-size: 100% 100%;
            height: 298px;
            transform: translateY(-50%); } }
    .part.slick-slide {
        position: relative;
        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 25px;
            background-size: 100% 100%;
            right: 35px;
            width: 147px;
            background: url(/public/img/index/2020_2.png);
            background-size: 100% 100%;
            height: 213px;
            z-index: 1; } } }
@media screen and (max-width: 1100px) {
    .pc-index {
        .top_posts {
            position: relative;
            &:after {
                content: none; } }
        .part.slick-slide {
            position: relative;
            &:before {
                content: none; } } } }
@media screen and (max-width: 1024px) {
    .p-header {
        .first-nav {
            li {
                margin-right: 15px; } } } }
